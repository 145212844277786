export class BasicModel {
  get mandatoryFields()  { return [] };

  get progress() {
    let fields = Object.getOwnPropertyNames(this).map(n => this[n]);
    return (fields).filter(v => !!v).length / fields.length * 100 || 0;
  }

  get incomplete() {
    let emptyFields = this.mandatoryFields.filter(field => !this[field]);
    return emptyFields.length > 0;
  };
}
