import React, {useCallback, useEffect, useState} from 'react';
import BackofficeService from './services/BackofficeService';
import {AggregateSummary} from './components/AggregateSummary';
import {ReadModelSummary} from './components/ReadModelSummary';
import {StatisticsSummary} from './components/StatisticsSummary';
import PurchasesAggregateModal from './components/PurchasesAggregateModal';
import AssetAggregateModal from './components/AssetAggregateModal';
import useModal from '../../hooks/UseModal';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {withStyles} from '@material-ui/core/styles';
import {Face, Image, Receipt, Refresh, ShoppingCart} from '@material-ui/icons';
import './Backoffice.scss';
import VendorAggregateModal from './components/VendorAggregateModal';
import CreditAdviceAggregateModal from './components/CreditAdviceAggregateModal';

const ModalTypes = {
  PURCHASES: 'PURCHASES',
  ASSET: 'ASSET',
  VENDOR: 'VENDOR',
  CREDIT_ADVICE: 'CREDIT_ADVICE'
};

const refreshButtonStyles = () => ({
  root: {
    backgroundColor: '#333',
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#FF0954',
      boxShadow: 'none'
    },
  }
});

const RefreshButton = withStyles(refreshButtonStyles)(Button);

export const Backoffice = () => {
    const initialState = {
      aggregateSummaries: [],
      readModelSummaries: [],
      statistics: {}
    }

    const [modalToggled, toggleModal] = useModal()
    const [state, setState] = useState(initialState);
    const [modalType, setModalType] = useState('');
    const [refreshCount, setRefreshCount] = useState(0)

    useEffect(() => {
      BackofficeService.getAggregateSummaries()
        .then(summaries => {
          setState(s => {
            return {...s, aggregateSummaries: summaries};
          });
        })
        .catch((error) => {
          console.log(error);
        });
      BackofficeService.getReadModelSummaries()
        .then(summaries => {
          setState(s => {
            return {...s, readModelSummaries: summaries};
          });
        })
      BackofficeService.getEnrichmentSummary(30)
        .then(stats => {
          setState(s => {
            return {...s, statistics: stats};
          });
        })
        .catch((error) => {
          console.log(error);
        })
    }, [refreshCount]);

    const aggregateTables = state.aggregateSummaries.map(t => <AggregateSummary key={t.name} table={t}/>);
    const readModels = state.readModelSummaries.map(t => <ReadModelSummary key={t.name} readModel={t}/>);
    const statistics = <StatisticsSummary statistics={state.statistics}/>;

    const openAggregateModal = (type) => {
      setModalType(type);
      toggleModal();
    }

    const doRefresh = useCallback(() => {
      setRefreshCount(refreshCount + 1)
    }, [refreshCount])

    return (
      <div className='backOffice__wrapper'>
        {
          modalType === ModalTypes.PURCHASES &&
          <PurchasesAggregateModal modalToggled={modalToggled} toggleModal={toggleModal}/>
        }
        {
          modalType === ModalTypes.ASSET &&
          <AssetAggregateModal modalToggled={modalToggled} toggleModal={toggleModal}/>
        }
        {
          modalType === ModalTypes.VENDOR &&
          <VendorAggregateModal modalToggled={modalToggled} toggleModal={toggleModal}/>
        }
        {
          modalType === ModalTypes.CREDIT_ADVICE &&
          <CreditAdviceAggregateModal modalToggled={modalToggled} toggleModal={toggleModal}/>
        }

        <div className='backOffice__titleWrapper'>
          <h1>Backoffice</h1>
          <RefreshButton onClick={() => doRefresh()}> <Refresh/> Refresh</RefreshButton>
        </div>
        <h3 className='backOffice__subtitle'>Enrichments</h3>
        <Box display='flex' flexWrap='wrap'>{statistics}</Box>

        <h3 className='backOffice__subtitle'>Aggregates</h3>
        <div className='backOffice__actions'>
          <span className='backOffice__actions__label'>Aggregate Inspection:</span>
          <Button onClick={() => openAggregateModal(ModalTypes.PURCHASES)}> <ShoppingCart/> Purchases</Button>
          <Button onClick={() => openAggregateModal(ModalTypes.ASSET)}> <Image/>Assets</Button>
          <Button onClick={() => openAggregateModal(ModalTypes.VENDOR)}> <Face/>Vendor</Button>
          <Button onClick={() => openAggregateModal(ModalTypes.CREDIT_ADVICE)}> <Receipt/>Credit Advice</Button>
        </div>

        <Box display="flex" flexWrap='wrap'>{aggregateTables} </Box>

        <h3 className='backOffice__subtitle'>Read Models</h3>
        <Box display='flex' flexWrap='wrap'>{readModels}</Box>
      </div>
    );
  }
;
