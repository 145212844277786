import {httpClient} from '../infrastructure/HttpClient';

class AuthService {
  static url = '/usage-catalog/api/users/me';

  // TODO: NCC-2342 remove this after our double authentication is removed
  static AD_url = '/usage-catalog/api/users/ad';

  initializeSession(credentials) {
    const token = 'Basic ' + btoa(`${credentials.username}:${credentials.password}`);
    return this.resumeSession(token)
  }

  resumeSession(token) {
    httpClient.setAuthorizationHeader(token);
    return this.authenticate(token);
  }

  clearSession() {
    httpClient.unsetAuthorizationHeader();
  }

  authenticate(token) {
    return httpClient.get(AuthService.url)
      .then(() => token)
      .catch(e => Promise.reject(e));
  }
}

export const authService = new AuthService();
