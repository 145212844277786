import ReactPaginate from 'react-paginate';
import React from 'react';
import './TablePagination.scss';

const TablePagination = ({pagination, handlePageChange}) => {
  return (
    <div className='tablePagination' data-testid='tablePagination--wrapper'>
      <ReactPaginate
        previousLabel={'<'} nextLabel={'>'} breakLabel={'...'}
        breakClassName={'break-me'}
        initialPage={pagination.pageNumber}
        pageCount={pagination.totalPages}
        marginPagesDisplayed={2} pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  )
}

export default TablePagination
