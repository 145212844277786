import React from 'react';
import './SearchResults.scss';
import NumberFormat from 'react-number-format';

const SearchResultSummary = React.memo(({totalCount}) => {

  const literal = (totalCount && totalCount === 1) ? 'Ergebnis' : 'Ergebnisse'

  return (
    <NumberFormat
      value={totalCount}
      renderText={value => <div data-testid="searchresults--count" className='searchresults__count'>{value} {literal}</div>}
      thousandSeparator={'.'}
      decimalSeparator={','}
      displayType={'text'}
      defaultValue={'0'}
      allowEmptyFormatting={true}
      allowNegative={false}
    />
  )
});

export default SearchResultSummary
