import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import {Card} from '@material-ui/core';
import {toDateString} from '../../../services/DateUtils';
import './summary.scss';

export const AggregateSummary = (props) => {
  const toKb = (bytes) => {
    let kb = bytes / 1024
    return kb.toFixed(2);
  }
  const {name, status, created, size, itemCount} = props.table;

  return (
    <Card className='summary'>
      <CardContent>
        <h2 className='summary__title'>{name}</h2>
        <ul className='summary__details'>
          <li><em>Status:</em> {status}</li>
          <li><em>Created:</em> {toDateString(created)}</li>
          <li><em>Size (KB):</em> {toKb(size)}</li>
          <li><em>Count:</em> {itemCount}</li>
        </ul>

      </CardContent>
    </Card>

  )
}
