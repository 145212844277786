import React from 'react';
import {Link} from 'react-router-dom';
import './NotFound.scss'

const NotFound = () => {
  return (
    <div className='notfound__wrapper'>
      <h1>Diese Seite konnte nicht gefunden werden.</h1>
      <p>
        Gründe dafür könnten sein, dass du eine falsche oder veraltete URL aufgerufen haben - bitte überprüfe
        dies nochmal.
      </p>
      <p>
        Vielleicht findest du den gewünschten Inhalt über die Startseite von EFA.
      </p>

      <Link to='/'>Startseite</Link>
    </div>
  );
};

export default NotFound;
