import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import '../vendorDetailsSections.scss';
import {MAX_INPUT_LENGTH} from '../../../constants';
import {PersonalData, SALUTATION_OPTIONS} from '../models/PersonalData';

export const PersonalDataSection = (props) => {

  let initialState = {
    personalData: new PersonalData(),
    saveDisabled: true
  };

  const [state, setState] = useState(initialState);
  const [originalState, setOriginalState] = useState(initialState);

  useEffect(() => {
    setState(s => {
      let personalData = Object.assign(new PersonalData(), props.data);
      const newState = {...s, personalData: personalData, saveDisabled: personalData.incomplete};
      setOriginalState({...newState});
      return newState
    })
  }, [props]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    state.personalData[name] = value;
    let newPersonalData = Object.assign(new PersonalData(), state.personalData);
    setState({...state, personalData: newPersonalData, saveDisabled: newPersonalData.incomplete});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit(state.personalData);
  };

  const salutationItems = Object.entries(SALUTATION_OPTIONS).map(([key, value]) => <MenuItem key={key} value={key}>{value}</MenuItem>);

  const handleReset = (evt) => {
    evt.preventDefault();
    setState({...originalState, saveDisabled: originalState.personalData.incomplete})
  }

  return (
    <>
      <form onSubmit={handleSubmit} data-testid='vendorDetails--personalData'>
        <h3 className='vendorDetails__section__headline'>Persönliche Daten</h3>
        <div className='vendorDetails__section__container'>
          <TextField
            data-testid='personalDataSection--externalCreditorId'
            id='externalCreditorId'
            label='Externe Kreditor ID'
            name='externalCreditorId'
            required={true}
            variant='outlined'
            value={state.personalData.externalCreditorId}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />

          <TextField
            data-testid='personalDataSection--companyName'
            id='companyName'
            name='companyName'
            label='Firma'
            variant='outlined'
            required={true}
            value={state.personalData.companyName}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />

          <Select
            displayEmpty={true}
            data-testid='personalDataSection--salutation'
            id='salutation'
            name='salutation'
            value={state.personalData.salutation}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          >
            {salutationItems}
          </Select>

          <div className='vendorDetails__section__empty'/>

          <TextField
            className='personalDataSection__firstName'
            data-testid='personalDataSection--firstName'
            id='firstName'
            name='firstName'
            label='Vorname'
            variant='outlined'
            value={state.personalData.firstName}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />
          <TextField
            className='personalDataSection__lastName'
            data-testid='personalDataSection--lastName'
            id='lastName'
            name='lastName'
            label='Nachname'
            variant='outlined'
            value={state.personalData.lastName}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />
        </div>

        <div className='vendorDetails__section__container'>
          <Button
            size='small'
            variant='contained'
            onClick={handleReset}
          >
            Zurücksetzen
          </Button>
          <Button
            disabled={state.saveDisabled}
            data-testid='personalDataSection--save'
            size='small'
            variant='contained'
            color='primary'
            type='submit'
          >
            Übernehmen
          </Button>
        </div>

      </form>
    </>
  );
}
