import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import './summary.scss';

export const ReadModelSummary = (props) => {
  const {name, count} = props.readModel;

  return (
    <Card className='summary'>
      <CardContent>
        <h2 className='summary__title'>{name}</h2>
        <ul className='summary__details'>
          <li><em>Count:</em> {count}</li>
        </ul>

      </CardContent>
      <CardActions>
        <Button size='small' disabled={true}>More details</Button>
      </CardActions>
    </Card>
  )
}
