import React, {useEffect, useState} from 'react';
import AuthContext from './AuthContext';

import {authService} from './AuthService';
import {maintenanceService} from './MaintenanceService';
import {MAINTENANCE_POLLING_INTERVALL} from '../constants';

const useUserWithLocalStorage = (intialState) => {
  const userKey = 'username';
  const authTokenKey = 'authToken';

  const initialUser = {...intialState};
  initialUser.name = localStorage.getItem(userKey);
  initialUser.authToken = localStorage.getItem(authTokenKey);

  const [user, setUser] = useState(initialUser);

  useEffect(() => {
    if (user && user.isValid()) {
      localStorage.setItem(userKey, user.name);
      localStorage.setItem(authTokenKey, user.authToken);
    } else {
      localStorage.removeItem(userKey);
      localStorage.removeItem(authTokenKey);
    }
  }, [user]);

  return [user, setUser];
};

const initialUserState = {
  name: null,
  authToken: null,
  isValid: function() { return !!(this.name && this.authToken);  }
};

const AuthProvider = ({ children }) => {

  const [isLoading, setLoading] = useState(false);
  const [isError] = useState(false);
  const [user, setUser] = useUserWithLocalStorage(initialUserState);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    if (user.isValid()) {
      setLoading(true);

      authService.resumeSession(user.authToken)
        .then(() => {
          setUserAuthenticated(true);
        })
        .catch(() => {
          authService.clearSession();
          setUser(initialUserState);
          setUserAuthenticated(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user, setUser]);

  /*
  // Maintenance Polling
  // checks on init and every 3 min after. tightly connected to backend maintenance wait times
   */
  useEffect(() => {
    maintenanceService.getMaintenanceMode()
      .then((maintenanceMode) => setMaintenanceMode(maintenanceMode))

    const interval = setInterval(() => {
      maintenanceService.getMaintenanceMode()
        .then((response) => setMaintenanceMode(response))
    }, MAINTENANCE_POLLING_INTERVALL);
    return () => clearInterval(interval);
  }, [user]);

  const startSession = (credentials) => {
    setLoading(true);

    return authService.initializeSession(credentials)
      .then((token) => {
        setUser( {...user, name: credentials.username, authToken: token});
        setUserAuthenticated(true);
      })
      .catch(() => {
        clearSession();
        return Promise.reject();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearSession = () => {
    authService.clearSession();
    setUser(initialUserState);
    setUserAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userAuthenticated,
        isLoading,
        isError,
        maintenanceMode,
        startSession,
        clearSession,
      }}
    >
      { children }
    </AuthContext.Provider>
  );
};

export default AuthProvider;
