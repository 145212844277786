import {createContext, useContext} from 'react';

const initialState = {
  user: null,
};

const AuthContext = createContext(initialState);

const useAuthContext = () => useContext(AuthContext);

export default AuthContext;

export { useAuthContext };
