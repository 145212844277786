export class Usage {

  department = {
    key: '',
    name: ''
  };
  documentPath = null;
  placementTime = null;
  dateCreated = null;

  static fromJson(payload) {
    let usage = new Usage();
    usage.documentPath = payload.documentPath || '';
    usage.department = payload.department || {};
    usage.placementTime = payload.placementTime;

    usage.dateCreated = payload.dateCreated;
    return usage;
  }
}
