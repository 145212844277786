import {BasicModel} from './BasicModel';

export const SALUTATION_OPTIONS = {
  "FRAU": "Frau",
  "HERR": "Herr",
  "DIVERS": "Divers",
};

export class Salutation {
  static mapSalutation(key) {
    switch (key.toUpperCase()) {
      case "DIVERS":
        return "DIVERS"
      case "FRAU":
      case "MRS":
        return "FRAU"
      case "HERR":
      case "MR":
        return "HERR"
      default:
        return ''
    }
  }
}

export class PersonalData extends BasicModel {
  externalCreditorId = '';
  companyName = '';
  salutation = '';
  firstName = '';
  lastName = '';

  static fromJson(payload = {}) {
    let pd = new PersonalData();
    pd.salutation = Salutation.mapSalutation(payload.salutation || '');
    pd.companyName = payload.companyName || '';
    pd.externalCreditorId = payload.externalCreditorId || '';
    pd.firstName = payload.firstName || '';
    pd.lastName = payload.lastName || '';
    return pd;
  }

  get mandatoryFields() {
    return ['externalCreditorId', 'companyName']
  };
}
