import React, {useEffect, useState} from 'react'
import {AnimatePresence, motion} from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/CachedRounded';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import './AssetDetails.scss'
import {PurchaseStatusIndicator} from './PurchaseStatusIndicator';
import useActionDrawer from '../../../hooks/UseActionDrawer';
import AssetDrawer from './AssetPurchaseActions/AssetDrawer';
import {PaymentStatus} from '../models/Purchase';
import {Tooltip} from '@material-ui/core';
import AssetMetadataRefresh from './AssetPurchaseActions/AssetMetadataRefresh';
import AssetPurchaseEditor from './AssetPurchaseActions/AssetPurchaseEditor';
import AssetRestrictionNote from './AssetPurchaseActions/AssetRestrictionNote';
import AssetRepurchase from './AssetPurchaseActions/AssetRepurchase';
import {RestrictionNoteIcon} from '../../../assets/Icons/AssetInformationIcons';
import {AssetInformation} from './AssetInformation';
import {CollageInformation} from './CollageInformation';
import UseAssetImage from '../../../hooks/UseAssetImage';

const animationVariants = {
  initial: {
    x: 100,
    opacity: 0
  },
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    x: 480,
    opacity: 0,
    transition: {
      duration: 0.25,
      delay: 0.3
    }
  }
}

const AssetDetails = React.memo(({detailsToggled, toggleDetails, purchase}) => {
  const [actionDrawerToggled, toggleActionDrawer] = useActionDrawer()
  const [assetAction, setAssetAction] = useState(null)

  const modificationEnabled = purchase && purchase.paymentStatus === PaymentStatus.IMMINENT;
  const restrictionNoteEnabled = purchase && !purchase.restrictionNoteSet;

  useEffect(() => {
    if (assetAction || actionDrawerToggled) {
      toggleActionDrawer()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetAction]);

  const handleCloseDetailsDrawer = () => {
    handleActionDrawer()
    toggleDetails()
  }

  const handleActionDrawer = () => {
    setAssetAction(undefined)
  }

  const handleRefreshMetadata = () => {
    setAssetAction(
      <AssetMetadataRefresh
        asset={purchase}
        toggleActionDrawer={handleActionDrawer}
      />
    )
  }

  const handleAssetPurchaseEditor = () => {
    setAssetAction(
      <AssetPurchaseEditor
        asset={purchase}
        toggleActionDrawer={handleActionDrawer}
      />
    )
  }

  const handleRestrictionNote = () => {
    setAssetAction(
      <AssetRestrictionNote
        asset={purchase}
        toggleActionDrawer={handleActionDrawer}
      />
    )
  }

  const handleRepurchase = () => {
    setAssetAction(
      <AssetRepurchase
        asset={purchase}
        toggleActionDrawer={handleActionDrawer}/>
    )
  }

  const RefreshButton = ({enabled}) => {
    let tooltipTitle = enabled ? 'Aktualisieren' : 'Verwendung darf nicht bearbeitet werden, da sie sich schon im Abrechnungsprozess befindet'
    return (
      <>
        <Tooltip
          title={tooltipTitle}
          enterDelay={300} leaveDelay={200} placement='top'
        >
          {
            enabled ?
              <div className='assetDetails__actions__button'
                   data-testid='assetDetails--refreshButton'
                   onClick={handleRefreshMetadata}
              >
                <RefreshIcon/>
              </div>
              :
              <div
                className='assetDetails__actions__button assetDetails__actions__button--disabled'
                data-testid='assetDetails--refreshButton'
              >
                <RefreshIcon/>
              </div>
          }
        </Tooltip>
      </>
    );
  }

  const EditButton = ({enabled}) => {
    const tooltipTitle = enabled ? 'Editieren' : 'Verwendung darf nicht bearbeitet werden, da sie sich schon im Abrechnungsprozess befindet';
    return (
      <>
        <Tooltip
          title={tooltipTitle}
          enterDelay={300} leaveDelay={200} placement='top'
        >
          {
            enabled ?
              <div
                className='assetDetails__actions__button'
                data-testid='assetDetails--editButton'
                onClick={handleAssetPurchaseEditor}
              >
                <EditIcon/>
              </div> :
              <div
                className='assetDetails__actions__button assetDetails__actions__button--disabled'
                data-testid='assetDetails--editButton'
              >
                <EditIcon/>
              </div>
          }
        </Tooltip>
      </>
    )
  }

  const RestrictionNoteButton = ({enabled}) => {

    let tooltipTitle = enabled ? 'Sperrvermerk' : 'Dieses Asset wurde bereits mit einem Sperrvermerk versehen';
    return (
      <>
        <Tooltip
          title={tooltipTitle}
          enterDelay={300} leaveDelay={200} placement='top'
        >
          {
            enabled ?
              <div
                className='assetDetails__actions__button'
                data-testid='assetDetails--restrictionNoteButton'
                onClick={handleRestrictionNote}
              >
                <div className='assetDetails__actions__restrictionNoteIcon'>
                  <RestrictionNoteIcon/>
                </div>
              </div> :
              <div
                className='assetDetails__actions__button assetDetails__actions__button--disabled'
                data-testid='assetDetails--restrictionNoteButton'>
                <div className='assetDetails__actions__restrictionNoteIcon--disabled'>
                  <RestrictionNoteIcon/>
                </div>
              </div>
          }
        </Tooltip>
      </>
    )
  }

  const RepurchaseButton = () => {
    return (
      <Tooltip
        title='Standzeitverlängerung'
        enterDelay={300} leaveDelay={200} placement='top'
      >
      <div
        className='assetDetails__actions__button'
        data-testid='assetDetails--repurchaseButton'
        onClick={handleRepurchase}
      >
        <UpdateIcon/>
      </div>
      </Tooltip>
    )
  }

  return (
    <>
      <AnimatePresence>
        {detailsToggled && (
          <>
            <div
              style={{
                position: 'fixed',
                top: '0',
                right: '0',
                zIndex: '20'
              }}
            >
              <motion.div
                variants={animationVariants}
                initial='initial'
                animate='enter'
                exit='exit'
              >
                <div className='assetDetails__closeButton' data-testid='assetDetails--closeButton'
                     onClick={handleCloseDetailsDrawer}>
                  <CloseIcon/>
                </div>
                <div className='assetDetails__actions'>
                  {!actionDrawerToggled &&
                  <>
                    <EditButton enabled={modificationEnabled}/>
                    <RefreshButton enabled={modificationEnabled}/>
                    <RepurchaseButton/>
                    <RestrictionNoteButton enabled={restrictionNoteEnabled}/>
                  </>
                  }
                </div>

                <div className='assetDetails__drawer' data-testid='assetDetails--drawer'>
                  <div className='assetDetails__thumbnailContainer'>
                    <UseAssetImage
                      thumbnailUrl={purchase?.metadata?.thumbnailUrl ?? ""}
                      damAssetUrl={purchase?.metadata?.damAssetUrl ?? ""}
                      assetId={purchase?.assetId?.damAssetId ?? ""}
                      className={'assetDetails__thumbnail'}
                      testIdAvailable={'assetDetails--thumbnail'}
                    />
                    <div className='assetDetails__overlay'>
                      <div className='assetDetails__enrichmentStatus'>
                        <PurchaseStatusIndicator purchase={purchase} isDetailed/>
                      </div>
                    </div>
                  </div>

                  {!purchase.isCollage && <AssetInformation purchase={purchase}/>}
                  {purchase.isCollage && <CollageInformation purchase={purchase}/>}

                </div>
              </motion.div>
            </div>

            <motion.div
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 0.1, delay: 0.1}}
              className='assetDetails__background'
              onClick={handleCloseDetailsDrawer}
            />
          </>
        )}
      </AnimatePresence>
      {detailsToggled &&
      <AssetDrawer actionDrawerToggled={actionDrawerToggled}>
        {assetAction}
      </AssetDrawer>
      }
    </>
  )
});

export default AssetDetails
