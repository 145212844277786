import React, {useState} from 'react';
import './Login.scss';
import {FormControl, FormGroup, Input, InputLabel} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {notificationService} from '../../components/Notifications/Notifications';
import {useAuthContext} from '../../services/AuthContext';

const Login = () => {
  const {startSession} = useAuthContext();

  const initialFormState = {username: '', password: ''};
  const [userform, setUserform] = useState(initialFormState);

  const handleInputChange = event => {
    const {name, value} = event.target;
    setUserform({...userform, [name]: value});
  };

  const handleSubmit = event => {
    event.preventDefault();

    startSession(userform)
      .catch(() => {
        notificationService.error('Authentifizierung fehlgeschlagen.')
      })
    setUserform(initialFormState);
  };

  return (
    <>
      <div className='login__wrapper' data-testid='login--wrapper'>
        <form
          onSubmit={handleSubmit}
          className='login__form'
          data-testid='login--form'
        >
          <FormGroup id='login-form'>
            <h1>Please Login</h1>
            <FormGroup id='login-wrapper'>
              <FormControl required={true} className='login__form__normalInput'>
                <InputLabel className='login__form__formLabel' htmlFor='username-input'>
                  Username
                </InputLabel>
                <Input
                  type='username'
                  name='username'
                  id='username-input'
                  value={userform.username}
                  onChange={handleInputChange}
                  data-testid='login--username'
                  className='login__form__username'
                />
              </FormControl>
            </FormGroup>
            <FormGroup id='password-wrapper'>
              <FormControl required={true} className='login__form__normalInput'>
                <InputLabel className='login__form__formLabel' htmlFor='password-input'>
                  Password
                </InputLabel>
                <Input
                  type='password'
                  name='password'
                  id='password-input'
                  value={userform.password}
                  onChange={handleInputChange}
                  data-testid='login--password'
                  className='login__form__password'
                />
              </FormControl>
            </FormGroup>

            <Button
              type='submit'
              variant='contained'
              color='primary'
              data-testid='login--submit'
            >
              Login
            </Button>
          </FormGroup>
        </form>
      </div>
    </>
  );
};

export default Login;
