import { useHistory } from 'react-router-dom';
import './VendorLink.scss';

const renderContent = (vendorName, vendorKey, baseUrl, params) => {

    if (vendorName && vendorKey) {
        return <a data-testid="vendorLink--link" href={baseUrl + "?" + params.toString()} title={'Anbieter Details'}>
            <span data-testid='vendorLink--vendorName-link'>{vendorName}</span>
        </a>
    }

    if (vendorKey) {
        return <span data-testid='vendorLink--vendorKey-span'>{`Anbietername Nicht zugewiesen. vendorKey= ${vendorKey}`}</span>
    }

    return <span data-testid='vendorLink--vendor-notAssigned'>{"Nicht zugeordnet"}</span>
}


export const VendorLink = ({ vendorKey, vendorName }) => {
    const history = useHistory();
    const baseUrl = history.location.pathname;
    const params = new URLSearchParams(history.location.search);

    if (vendorKey) {
        params.delete("selectedVendors")
        params.append("selectedVendors", vendorKey)
    }

    return (
        <div className="vendorLink" data-testid="vendorLink">
            {
                renderContent(vendorName, vendorKey, baseUrl, params)
            }
        </div>
    )
}