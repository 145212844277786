import {BasicModel} from './BasicModel';

export class AddressAndBank extends BasicModel {

  streetAndNumber = '';
  city = '';
  country = '';
  zipCode = '';
  phoneNumber = '';
  email = '';

  accountHolder = '';
  bankName = '';
  iban = '';
  bic = '';

  get mandatoryFields() {
    return ['email'];
  }

  static fromJson(payload = {}) {
    let addressAndBank = new AddressAndBank();

    addressAndBank.accountHolder = payload.accountHolder || '';
    addressAndBank.bankName = payload.bankName || '';
    addressAndBank.iban = payload.iban || '';
    addressAndBank.bic = payload.bic || '';
    addressAndBank.phoneNumber = payload.phoneNumber || '';
    addressAndBank.email = payload.email || '';

    addressAndBank.streetAndNumber = payload.streetAndNumber || '';
    addressAndBank.city = payload.city || '';
    addressAndBank.country = payload.country || '';
    addressAndBank.zipCode = payload.zipCode || '';

    return addressAndBank;
  }
}
