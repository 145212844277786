import React, {useEffect, useState} from 'react';
import {notificationService} from '../../../../components/Notifications/Notifications';
import {vendorService} from '../../../vendor/services/VendorService';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {assetService} from '../../services/AssetService';
import { AssetPurchaseEditorActionButtons } from './AssetPurchaseEditorActionButtons';


const AssetPurchaseEditorVendor = React.memo(({asset, toggleActionDrawer}) => {

  const [selectedVendor, setVendor] = useState()
  const [vendors, setVendors] = useState([])
  const [isLoading, setLoading] = useState(true)
  
  useEffect(() => {
    vendorService
      .getVendors()
      .then(vendors => setVendors(vendors))
      .catch(() => {
        notificationService.error('Konnte Liste an Anbietern nicht laden');
        toggleActionDrawer();
      })
      .finally(() => {
        setLoading(false)
      })
  }, [toggleActionDrawer]);

  const submitSelectVendor = () => {
    setLoading(true)
    assetService.overrideVendor(asset.assetId, selectedVendor)
      .then(() => {
        toggleActionDrawer()
        notificationService.success('Anpassung des Anbieters war erfolgreich, bitte gebe EFA etwas Zeit, die Änderungen zu übernehmen. Aktualisiere die Seite ggf. erneut.')
      })
      .catch((err) => {
        const backendErrorMessage = err ? err.message : '';
        notificationService.error(`Anpassung des Anbieters ist fehlgeschlagen, versuche es bitte erneut. ${backendErrorMessage}`)
      }).finally(() =>
        setLoading(false)
      )
  }

  return (
    <div className='assetActions__section'>
      {isLoading ?
        <div className='assetActions__spinner' data-testid='assetActions__spinner'>
          <div className='assetActions__spinner--circularProgress'><CircularProgress/></div>
          <div className='assetActions__spinner--message'>
            <h3>Anbieter anpassen</h3>
          </div>
        </div>
        :
        <>
          <h1 data-testid='assetActions--mainTitle__vendorOverride'>Anbieter anpassen</h1>

          <p className='assetActions__paragraph'>
            Du bist dabei, den Anbieter zu überschreiben. Die Anpassung wird anschließend zu Fotoware synchronisiert.
          </p>

          <p className='assetActions__paragraph' data-testid='assetActions__paragraph--assetAndVendor'>
            Aktuelles Asset: <span>{asset.assetId.damAssetId}</span>
            <br/>
            Aktueller Anbieter: <span data-testid='assetActions__editorVendor--vendorname'>{asset.vendor.vendorName || '-'}</span>
          </p>

          <div className='assetActions__picker'>
            <Autocomplete
              id='tags-standard'
              onChange={(_, vendor) => setVendor(vendor?.id)}
              options={vendors}
              loading={isLoading}
              disabled={isLoading}
              data-testid='assetActions__vendors'
              noOptionsText={'Keine Anbieter'}
              getOptionLabel={(vendor) => vendor.companyName}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={`Anbieter (${vendors.length})`}
                  data-testid='assetActions__vendorPicker'
                />
              }
            />
          </div>
      <AssetPurchaseEditorActionButtons isSubmitEnabled={selectedVendor} toggleActionDrawer={toggleActionDrawer} submit={submitSelectVendor} testId={"vendor"}  />


        </>
      }
    </div>
  )
});

export default AssetPurchaseEditorVendor;
