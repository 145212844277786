import {createTheme} from '@material-ui/core/styles';

export const RaflTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: 'rgba(255,9,84,0.7)',
      main: '#FF0954',
      dark: 'rgba(255,9,84, 0.7)',
    },
    secondary: {
      light: 'rgba(255,9,84,0.7)',
      main: '#000000',
      dark: 'rgba(0, 0, 0, 0.7)',
    },
  },
});
