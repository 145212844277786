import { useState, useEffect } from "react";

export const useDebounceValidation = (value, delay) => {

  const [isValueValid, setIsValueValid] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  useEffect(
    () => {
      setIsValueValid(true)
      setIsInProgress(true)
      const handler = setTimeout(() => {
        // eslint-disable-next-line no-useless-escape
        setIsValueValid(/^(\d+(?:[\.\,]\d{2})?)$/.test(value));
        setIsInProgress(false)
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return [isValueValid, isInProgress];
}
