
export const optimisticSubmitButtonStyles = () => ({
  root: {
    backgroundColor: '#6FCF97',
    fontSize: '0.72rem',
    '&:hover': {
      backgroundColor: '#52C582',
    },
  }
});

export const pessimisticSubmitButtonStyles = () => ({
  root: {
    backgroundColor: '#FF0954',
    fontSize: '0.72rem',
    '&:hover': {
      backgroundColor: 'rgba(248,8,81,0.9)',
    },
  }
});

export const cancelButtonStyles = () => ({
  root: {
    backgroundColor: '#4f4f4f',
    fontSize: '0.72rem',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#000000',
      color: '#ffffff'
    },
  }
});
