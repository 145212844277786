import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import './CreditAdviceDetails.scss';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';

import { toCalenderDateString, toDateString } from '../../services/DateUtils';
import TableContainer from '@material-ui/core/TableContainer';
import Spinner from '../../components/Spinner/Spinner';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {
  Add, Creating,
  Done,
  Open,
  Preview,
  ReadyToSend,
  SavePersonOfContact,
  ToBePaid
} from '../../assets/Icons/CreditAdviceIcons';

import Checkbox from '@material-ui/core/Checkbox';
import { notificationService } from '../../components/Notifications/Notifications';
import { MAX_INPUT_LENGTH } from '../../constants';
import { creditAdviceService } from './services/CreditAdviceService';
import { vendorService } from '../vendor/services/VendorService';
import { CreditAdvice, CreditAdviceStatus, CreditAdviceStatusLabels } from './models/CreditAdvice';

import { Vendor } from '../vendor/models/Vendor';
import LineItemCandidateList from './components/LineItemCandidateList';
import LoadingCard from './components/LoadingCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreditLineItem from './components/CreditLineItem';
import SpinnerInline from '../../components/Spinner/SpinnerInline';
import { purchaseService } from '../purchases/services/PurchaseService';
import { deriveApiUrl } from '../../infrastructure/DeriveApiUrl';
import useModal from '../../hooks/UseModal';
import BillableConfirmModal from './components/BillableConfirmModal';
import { AccountingTypes, PricingModel } from '../vendor/models/PricingModel';
import { Pagination } from '../../components/Pagination/model/Pagination';
import { PriceCurrencyLiteral } from '../../services/MoneyUtils';
import CreditAdviceStatusSummary from './components/CreditAdviceStatusSummary';

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: '#FF0954',
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const bulkDeleteButtonStyles = () => ({
  root: {
    backgroundColor: '#efefef',
    color: '#000',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#d4d4d4',
      boxShadow: 'none'
    },
  }
});

const BulkDeleteButton = withStyles(bulkDeleteButtonStyles)(Button);

const statusIcon = (status) => {
  switch (status) {
    case CreditAdviceStatus.CREATING:
      return <Creating />;
    case CreditAdviceStatus.PRELIMINARY:
      return <Open />;
    case CreditAdviceStatus.SHIPPABLE:
      return <ReadyToSend />;
    case CreditAdviceStatus.BILLABLE:
      return <ToBePaid />;
    case CreditAdviceStatus.CLEARED:
      return <Done />;
    default: {
      console.error('Unknown state');
      return <span>no status</span>;
    }
  }
};

const initialState = {
  creditAdvice: new CreditAdvice(),
  vendor: new Vendor(),
  selectedItems: [],
  addCreditLineItemsToggled: false,
  performingBulkAddLineItems: false,
  performingBulkDeleteLineItems: false,
  addBulkLineItemsTotal: null
}

export function toAccountingTypeLiteral(accountingType) {
  return PricingModel.AccountingLabels[accountingType] || '';
}

const CreditAdviceDetails = React.memo((props) => {
  const history = useHistory();
  const { vendorKey, serialNumber } = props.match.params;

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [personOfContact, setPersonOfContact] = useState('');
  const [pagination, setPagination] = useState({
    loadingPagination: true,
    pagination: new Pagination()
  });
  const [modalToggled, toggleModal] = useModal()

  useEffect(() => {
    creditAdviceService.getCreditAdvice(vendorKey, serialNumber)
      .then((creditAdvice) => {
        setState(state => ({
          ...state,
          creditAdvice: creditAdvice,
          selectedItems: []
        }));
        return creditAdvice;
      })
      .then((creditAdvice) => {
        setPersonOfContact(creditAdvice.personOfContact);
        return creditAdvice;
      })
      .then((creditAdvice) => {
        vendorService.getVendorById(creditAdvice.id.vendorKey)
          .then((vendor) => {
            setState(state => ({
              ...state,
              vendor: vendor
            }));
          })
          .catch(() => {
            history.push('/not-found');
          });
        return creditAdvice;
      })
      .then((creditAdvice) => {
        const searchParams = new URLSearchParams();
        searchParams.set('publishDateStart', toCalenderDateString(creditAdvice.accountingTimespan.start));
        searchParams.set('publishDateEnd', toCalenderDateString(creditAdvice.accountingTimespan.end));
        searchParams.set('selectedVendors', creditAdvice.id.vendorKey);

        purchaseService.searchAccountablePurchases(searchParams)
          .then((payload) => {
            setPagination(pagination => ({
              ...pagination,
              pagination: payload.pagination
            }));
          })
          .finally(() => {
            setPagination(pagination => ({
              ...pagination,
              loadingPagination: false
            }));
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [history, serialNumber, vendorKey, loading]);

  const isLocked = () => {
    return (state.creditAdvice.isLocked());
  }

  const handleSavePersonOfContact = (e) => {
    e.preventDefault()
    creditAdviceService.modifyPersonOfContact(vendorKey, serialNumber, personOfContact)
      .then(() => notificationService.success('Ansprechspartner aktualisiert.'))
      .catch(() => notificationService.error('Konnte Ansprechspartner nicht aktualisieren.'));
  }

  const handleChangePersonOfContact = (event) => {
    let { value } = event.target;
    setPersonOfContact(value);
  }

  const handleAddCreditLineItems = () => {
    setState({ ...state, addCreditLineItemsToggled: !state.addCreditLineItemsToggled });
  };

  const handleAddCreditLineItem = async (purchase, potentialCreditlineItems) => {
    await creditAdviceService.addCreditLineItem(state.creditAdvice.id.vendorKey, state.creditAdvice.id.serialNumber, purchase)
      .then(() => {
        notificationService.success('Verwendung wurde als Posten hinzugefügt.');
      })
      .catch((e) => {
        notificationService.error('Verwendung konnte nicht hinzugefügt werden. ' + e.message);
      })
      .finally(() => {
        setState({
          ...state,
          addCreditLineItemsToggled: potentialCreditlineItems.length > 1
        });
        setLoading(true);
      });
  };

  const handleAddAllCreditLineItems = async (purchases) => {
    await setState({
      ...state,
      addCreditLineItemsToggled: false,
      performingBulkAddLineItems: true,
      addBulkLineItemsTotal: purchases.length
    });

    let failureCount = await creditAdviceService.addCreditLineItems(state.creditAdvice, purchases);
    if (!failureCount) {
      notificationService.success(`${purchases.length} Posten wurden der Gutschrift erfolgreich hinzugefügt.`);
    } else {
      notificationService.error(`${failureCount} von ${purchases.length} Posten konnten der Gutschrift nicht hinzugefügt werden.`);
    }

    setState({
      ...state,
      addCreditLineItemsToggled: false,
      performingBulkAddLineItems: false
    });
    setLoading(true);
  };

  const handleDeleteCreditLineItem = async (purchase) => {
    await creditAdviceService.deleteCreditLineItem(state.creditAdvice.id.vendorKey, state.creditAdvice.id.serialNumber, purchase)
      .then(() => {
        notificationService.success(`Folgender Posten wurde entfernt: ${purchase.assetId.originDamId}`);
      })
      .catch(() =>
        notificationService.error(`Konnte folgende Posten nicht entfernen: ${purchase.assetId.originDamId}`)
      )
      .finally(() => setLoading(true)
      );
  };

  const handleEditCreditLineItemCaption = async (purchase, caption) => {
    const payload = {
      purchaseOrdinal: purchase.purchaseOrdinal,
      damAssetId: purchase.assetId.originDamId,
      dam: purchase.assetId.originDam,
      tenant: purchase.assetId.tenantId,
      caption
    }
    
    await creditAdviceService.modifyCaption(state.creditAdvice.id.vendorKey, state.creditAdvice.id.serialNumber, payload)
      .then(() => {
        notificationService.success(`Die Beschriftung des folgenden Artikels wurde aktualisiert ${purchase.assetId.originDamId}`);
      })
      .catch(() =>
        notificationService.error(`Folgender Artikel konnte nicht aktualisiert werden: ${purchase.assetId.originDamId}`)
      )
      .finally(() => setLoading(true)
      );
  };

  const handleDeleteSelected = async () => {

    await setState({ ...state, performingBulkDeleteLineItems: true });

    let failureCount = await creditAdviceService.deleteCreditLineItems(state.creditAdvice, state.selectedItems);

    if (!failureCount) {
      notificationService.success(`${state.selectedItems.length} Posten wurden von der Gutschrift erfolgreich entfernt.`);
    } else {
      notificationService.error(`${failureCount} von ${state.selectedItems.length} Posten konnten von der Gutschrift nicht entfernt werden.`);
    }

    setState({
      ...state,
      performingBulkDeleteLineItems: false,
    });
    setLoading(true);
  };

  const handleBillingStateChangeAndToggleModal = () => {
    let { vendorKey, serialNumber } = state.creditAdvice.id;

    creditAdviceService.modifyStatus(vendorKey, serialNumber, CreditAdviceStatus.BILLABLE)
      .then((updatedCreditAdvice) => {
        setState({ ...state, creditAdvice: updatedCreditAdvice });
      })
      .catch((e) => {
        notificationService.error(`Konnte Status nicht auf '${CreditAdviceStatus.BILLABLE}' setzen. ${e.message}`)
      })
      .finally(() => toggleModal())
  }

  const handleCreditAdviceStatusChange = (newStatus) => {
    let { vendorKey, serialNumber } = state.creditAdvice.id;

    if (newStatus === CreditAdviceStatus.BILLABLE) {
      toggleModal()
    } else {
      creditAdviceService.modifyStatus(vendorKey, serialNumber, newStatus)
        .then((updatedCreditAdvice) => {
          setState(state => ({ ...state, creditAdvice: updatedCreditAdvice }));
        }).catch((e) => {
          notificationService.error(`Konnte Status nicht auf '${newStatus}' setzen. ${e.message}`);
        });
    }
  }

  const isSelected = (creditLineItem) => {
    return !!state.selectedItems.find((e) => e.id === creditLineItem.id);
  };

  const handleSelectItem = (creditLineItem) => {
    let items = [...state.selectedItems];
    let index = items.findIndex((e) => e.id === creditLineItem.id);

    if (index === -1) {
      items.push(creditLineItem);
    } else {
      items.splice(index, 1);
    }

    setState(state => ({ ...state, selectedItems: items }));
  }

  const anySelected = () => (state.selectedItems.length > 0)

  const handleSelectAllToggle = () => {
    if (anySelected()) {
      setState(state => ({ ...state, selectedItems: [] }));
    } else {
      let creditLineItemsArray = [];
      state.creditAdvice.creditItems.map(creditLineItem => creditLineItemsArray.push(creditLineItem));
      setState(state => ({ ...state, selectedItems: creditLineItemsArray }));
    }
  }

  const stateTransitionButton = (targetState, label, className, id) => {
    return <Button
      data-testid={id}
      className={className}
      size='small'
      variant='contained'
      color='primary'
      type='submit'
      onClick={() => {
        handleCreditAdviceStatusChange(targetState);
      }}
    >
      {label}
    </Button>;
  }

  const nextStateButton = () => {
    switch (state.creditAdvice.status) {
      case CreditAdviceStatus.PRELIMINARY:
        return stateTransitionButton(
          CreditAdviceStatus.SHIPPABLE,
          'Versandfertig',
          'creditadvicedetail__nextStateButton',
          'creditadvicedetail--nextStateButton'
        );
      case CreditAdviceStatus.SHIPPABLE:
        return stateTransitionButton(
          CreditAdviceStatus.BILLABLE,
          'Honorarpflichtig',
          'creditadvicedetail__nextStateButton',
          'creditadvicedetail--nextStateButton'
        );
      default: {
        return null;
      }
    }
  }

  const previousStateButton = () => {
    switch (state.creditAdvice.status) {
      case CreditAdviceStatus.PRELIMINARY:
        return null;
      case CreditAdviceStatus.SHIPPABLE:
        return stateTransitionButton(
          CreditAdviceStatus.PRELIMINARY,
          'Vorläufig',
          'creditadvicedetail__prevStateButton',
          'creditadvicedetail--prevStateButton'
        );
      default: {
        return null;
      }
    }
  }

  const renderPreviewButton = () => {
    let backendBaseUrl = deriveApiUrl(window.location.href);
    const link = `${backendBaseUrl}/usage-catalog/api/credit-advices/${vendorKey}/${serialNumber}/preview`;

    const btn = <>
      <Link
        to={{ pathname: link }}
        target="_blank"
      >
        <div className='creditadvicedetailheader__previewButton'
          data-testid='creditadvicedetailheader--previewButton'>
          <Preview />
        </div>
      </Link>
      <span>Vorschau</span>
    </>;

    return isLocked() && state.creditAdvice.accountingType !== AccountingTypes.USAGE_REPORT ? btn : <></>;
  };

  return (
    <>
      {loading && (<Spinner />)}

      {!loading && (
        <>
          <BillableConfirmModal
            modalToggled={modalToggled}
            toggleModal={toggleModal}
            submitButtonText={CreditAdviceStatusLabels.BILLABLE}
            cancelButtonText="Abbrechen"
            content={state.vendor.addressAndBank.email}
            handleBillingStateChangeAndToggleModal={handleBillingStateChangeAndToggleModal}
          />
          <div className='creditadvicedetail__wrapper' data-testid='creditadvicedetail--container'>
            <TableContainer component={Paper}
              className='creditadvicedetail__table'
              data-testid='creditadvicedetail--table'>
              <div className='creditadvicedetailheader' data-testid='creditadvicedetail--header'>
                <div className='creditadvicedetailheader__title'>
                  <h5>Vorläufige Gutschrift</h5>
                  <small>Zeitraum <span
                    data-testid='creditadvicedetail--accountingTimeSpan__start'>{toDateString(state.creditAdvice.accountingTimespan.start)}</span> bis <span
                      data-testid='creditadvicedetail--accountingTimeSpan__end'>{toDateString(state.creditAdvice.accountingTimespan.end)}</span>
                  </small>
                </div>
                <div className='creditadvicedetailheader__preview'>
                  {renderPreviewButton()}
                </div>
                <div className='creditadvicedetailheader__vendor'>
                  <h6>Firma</h6>
                  <span data-testid='creditadvicedetail--companyName'>{state.vendor.personalData.companyName}</span>
                  <span
                    data-testid='creditadvicedetail--streetAndNumber'>{state.vendor.addressAndBank.streetAndNumber}</span>
                  <div>
                    <span data-testid='creditadvicedetail--zipCode'>{state.vendor.addressAndBank.zipCode}</span>
                    <span> </span>
                    <span data-testid='creditadvicedetail--city'>{state.vendor.addressAndBank.city}</span>
                  </div>
                  <div className='creditadvicedetailheader__vendorFinance'>
                    <div>Währung <span
                      data-testid='creditadvicedetail--currency'>{state.vendor.pricingModel.currency || '-'}</span>
                    </div>
                    <div>Steuersatz <span
                      data-testid='creditadvicedetail--taxRate'>{state.vendor.businessDetails.taxRate || '-'}</span>
                    </div>
                    <div>Abrechnungstyp <span
                      data-testid='creditadvicedetail--accountingType'>{toAccountingTypeLiteral(state.creditAdvice.accountingType)}</span>
                    </div>
                  </div>
                </div>
                <div className='creditadvicedetailheader__personOfContact'>
                  <div>Ansprechpartner</div>
                  <form
                    onSubmit={handleSavePersonOfContact}
                    className='creditadvicedetailheader__personOfContactForm'
                    data-testid='personOfContact--form'
                  >
                    <TextField
                      data-testid='creditadvicedetailheader--personOfContact'
                      id='personOfContact'
                      label='Ansprechpartner'
                      name='personOfContact'
                      variant='outlined'
                      type='text'
                      required={true}
                      disabled={isLocked()}
                      value={personOfContact}
                      onChange={handleChangePersonOfContact}
                      inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                    />
                    {!isLocked() && (
                      <button
                        data-testid='creditadvicedetailheader--personOfContactButton'
                        className='creditadvicedetailheader__personOfContactButton'
                        type='submit'
                      >
                        <SavePersonOfContact />
                      </button>
                    )}
                  </form>
                </div>
                <div className='creditadvicedetailheader__status'>
                  <div className='creditadvicedetailheader__statusIcon'>{statusIcon(state.creditAdvice.status)}</div>
                  <div className='creditadvicedetailheader__statusLabel'>{CreditAdviceStatusLabels[state.creditAdvice.status]}</div>
                  <div className='creditadvicedetailheader__statusSummary'>
                    <CreditAdviceStatusSummary creditAdvice={state.creditAdvice} />
                  </div>
                  <div className='creditadvicedetailheader__statusChangedBy'>
                    <div>
                      {state.creditAdvice.status === CreditAdviceStatus.PRELIMINARY || state.creditAdvice.status === CreditAdviceStatus.CREATING ? 'erstellt' : 'geprüft'} ({state.creditAdvice.lastModifiedBy})
                    </div>
                    <div>am {toDateString(state.creditAdvice.lastModified)}</div>
                  </div>
                </div>
              </div>
              <div className='creditadvicedetail__tablehead'>
                <h6>Posten</h6>
                {previousStateButton()}
                {nextStateButton()}
              </div>
              <Table aria-label='simple table'>
                <TableHead className='creditadvicedetail__legendHeader'>
                  <TableRow data-testid='creditadvicedetail--tableRow'>
                    <TableCell className='creditadvicedetail__actions'>
                      <CustomCheckbox
                        className='creditadvicedetail__checkbox'
                        data-testid='creditadvicedetail--selectAllCheckbox'
                        checked={anySelected()}
                        disabled={isLocked()}
                        onClick={isLocked() ? null : handleSelectAllToggle}
                      />
                      {anySelected() && (
                        <BulkDeleteButton
                          onClick={handleDeleteSelected}
                          data-testid='creditadvicedetail--bulkDelete'
                          className='creditadvicedetail__bulkDelete'
                          size='small'
                          variant='contained'
                          color='primary'
                          type='submit'
                        >
                          {!state.performingBulkDeleteLineItems ? 'Löschen' :
                            <div className={`creditadvicedetail__spinner`}>
                              <CircularProgress style={{ height: '15px', width: '15px', marginRight: '15px' }} /><span>
                                Läuft..
                              </span>
                            </div>}
                        </BulkDeleteButton>
                      )}

                    </TableCell>
                    <TableCell>ID / Fremd-Id</TableCell>
                    <TableCell>Überschrift</TableCell>
                    <TableCell>Ressort</TableCell>
                    <TableCell>Standzeit</TableCell>
                    <TableCell align='right' className='creditadvicedetail--alignRight'>Preis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody data-testid='creditadvicedetail--tableBody'>
                  {
                    state.creditAdvice.creditItems.map((creditLineItem, index) => (
                      <CreditLineItem
                        key={index}
                        creditLineItem={creditLineItem}
                        setState={setState}
                        state={state}
                        index={index}
                        isLocked={isLocked()}
                        isSelected={isSelected}
                        handleSelectItem={handleSelectItem}
                        handleDeleteCreditLineItem={handleDeleteCreditLineItem}
                        handleEditCreditLineItemCaption={handleEditCreditLineItemCaption}
                      />
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow data-testid='creditadvicedetail--tableRow'>
                    <TableCell className='creditadvicedetail__remaining' colSpan={5}>
                      <div>Derzeit verfügbare Posten: {pagination.loadingPagination ?
                        <SpinnerInline /> : <span data-testid='creditadvicedetail--totalElements'>{pagination.pagination.totalElements}</span>}
                      </div>
                    </TableCell>
                    <TableCell align='right' className='creditadvicedetail--alignRight creditadvicedetail__total'>
                      Total: <PriceCurrencyLiteral price={state.creditAdvice.sum} currency={state.vendor.pricingModel.currency} testId='creditadvicedetail--sum' />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <div>
              {!isLocked() ?
                <div
                  className={state.addCreditLineItemsToggled ? 'creditadvicedetail__addIcon--active' : 'creditadvicedetail__addIcon--idle'}
                  onClick={() => handleAddCreditLineItems()}
                >
                  <Add />
                </div>
                : null
              }
            </div>
            {
              state.addCreditLineItemsToggled && !isLocked() && (
                <LineItemCandidateList
                  creditAdvice={state.creditAdvice}
                  handleAddCreditLineItem={handleAddCreditLineItem}
                  handleAddAllCreditLineItems={handleAddAllCreditLineItems}
                />
              )
            }
            {
              state.performingBulkAddLineItems && (
                <LoadingCard />
              )
            }
          </div>
        </>
      )}

    </>
  );
})
  ;

export default CreditAdviceDetails;
