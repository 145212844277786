export class DepartmentResponse {
  defaultDepartment = null;
  otherDepartments = null;

  static fromJson(payload) {
    let departmentResponse = new DepartmentResponse();
    departmentResponse.defaultDepartment = Department.fromJson(payload.defaultDepartment || {})
    departmentResponse.otherDepartments = payload.otherDepartments.map(department => Department.fromJson(department))
    return departmentResponse;
  }

}

export class Department {
  urlPattern = '';
  name = '';
  costCenterId = '';
  costCenterName = '';
  foreignId = '';

  static fromJson(payload) {
    let department = new Department();

    department.costCenterId = payload.costCenterId || '';
    department.costCenterName = payload.costCenterName || '';
    department.urlPattern = payload.urlPattern || '';
    department.name = payload.name || '';
    department.foreignId = payload.foreignId || '';
    return department;
  }
}
