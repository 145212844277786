import React from 'react';
import NumberFormat from 'react-number-format';

export const MoneyUtilsTestId = 'price--formatted'

export const PriceLiteral = React.memo(({price, testId = MoneyUtilsTestId}) => {
  return (
    <NumberFormat
      value={price}
      renderText={value => <span data-testid={testId}> {value || '-'}</span>}
      thousandSeparator={'.'}
      decimalSeparator={','}
      displayType={'text'}
      decimalScale={2}
      fixedDecimalScale={true}
      defaultValue={'-'}
    />
  )
})

export const PriceCurrencyLiteral = React.memo(({price, currency, testId = MoneyUtilsTestId}) => {
  return (
    <NumberFormat
      value={price}
      renderText={value => <span data-testid={testId}> {value || '-'} {currency}</span>}
      thousandSeparator={'.'}
      decimalSeparator={','}
      displayType={'text'}
      decimalScale={2}
      fixedDecimalScale={true}
      defaultValue={'-'}
    />
  )
})
