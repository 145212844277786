const generateApiUrlForStage = stage => `https://${stage}-lb.efa.axelspringer.de`;

export const deriveApiUrl = url => {
  let apiInLocalstorage = localStorage.getItem('apiUrl');
  if (apiInLocalstorage) {
    return apiInLocalstorage;
  }

  const servedFromLocalhostRegex = /^http:\/\/localhost*/i;
  if (servedFromLocalhostRegex.test(url)) {
    return "http://localhost:8080"
  }

  // in all other cases, derive the stage-url and use it
  const servedInSubdomainRegex = /^https:\/\/([a-z\-0-9]+)\.efa\.axelspringer\.de/i;
  const matches = servedInSubdomainRegex.exec(url);
  const stage = (matches) ? matches[1] : 'prod';

  return generateApiUrlForStage(stage);
};
