import React, {useEffect, useState} from 'react';
import {purchaseService} from '../services/PurchaseService';
import {notificationService} from '../../../components/Notifications/Notifications';
import SpinnerInline from '../../../components/Spinner/SpinnerInline';
import UseAssetImage from '../../../hooks/UseAssetImage';

export const CollageChildPreview = ({assetIdChild, index}) => {

  const [assetPreview, setAssetPreview] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    purchaseService.fetchAssetPreview(assetIdChild)
      .then((assetPreview) => {
        setAssetPreview(assetPreview)
      })
      .catch((httpError) => {
        if (httpError.status !== 404) {
          notificationService.error('Konnte Collage-Kind nicht finden.')
        } else {
          // Not found - on Asset doesn't exist in EFA
          setAssetPreview(null)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [assetIdChild])

  return (
    <div
      className='assetDetails__collageChildren__child'
      data-testid="assetDetails--collage-child"
    >
      {loading && <SpinnerInline/>}

      {!loading && assetPreview != null &&
      <>
        <UseAssetImage
          thumbnailUrl={assetPreview.thumbnailUrl}
          damAssetUrl={assetPreview.damAssetUrl}
          assetId={assetPreview.assetId}
          className={'assetDetails__collageChildren__thumbnail'}
          testIdAvailable={'assetDetails--collageChildren--thumbnail'}
        />
        <div className='assetDetails__collageChildren__infoWrapper'>
          <h3>Asset ID</h3>
          <div>
            <a data-testid='assetDetails--collageChildren--damAssetId--link' href={`?shortOrLongId=${assetPreview.assetId.damAssetId}&tab=BYID`}>
              <span data-testid='assetDetails--collageChildren--damAssetId'>{assetPreview.assetId.damAssetId}</span>
            </a>
          </div>
        </div>
      </>
      }

      {!loading && assetPreview === null &&
      <>
        <UseAssetImage
          className={'assetDetails__collageChildren__thumbnail'}
          testIdAvailable={'assetDetails--collageChildren--thumbnail'}
        />
        <div className='assetDetails__collageChildren__infoWrapper'>
          <h3>Asset ID</h3>
          <div>
            <span data-testid='assetDetails--collageChildren--damAssetId'>Asset nicht bekannt in EFA: ${assetIdChild.damAssetId}</span>
          </div>
        </div>
      </>
      }
    </div>
  )
}
