import React from 'react';
import './DatePicker.scss';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/de';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';

const DatePickerComponent = props => {
  const { setSelectedDate, selectedDate, labelType, datePickerId, disabled, readOnly } = props;

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale='de'
    >
      <KeyboardDatePicker
        data-testid='datepicker--keyboard'
        disableToolbar
        disabled={disabled}
        readOnly={readOnly}
        variant='inline'
        color='secondary'
        margin='normal'
        id={datePickerId}
        label={labelType}
        format='DD/MM/yyyy'
        value={selectedDate}
        onChange={setSelectedDate}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerComponent;
