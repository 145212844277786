import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Spinner.scss';

const Spinner = React.memo(({modal: isModal}) => (
  <div className={`spinner ${isModal ? 'spinner__modal' : ''}`}>
    <CircularProgress/>
  </div>
))

export default Spinner;
