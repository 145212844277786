import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import Card from '@material-ui/core/Card';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import './LineItemCandidateList.scss';
import Button from '@material-ui/core/Button';
import {AddCreditLineItemIcon} from '../../../assets/Icons/CreditAdviceIcons';
import {purchaseService} from '../../purchases/services/PurchaseService';
import {toCalenderDateString, toDateString} from '../../../services/DateUtils';
import {notificationService} from '../../../components/Notifications/Notifications';
import Spinner from '../../../components/Spinner/Spinner';
import ReactPaginate from 'react-paginate';
import {prefixWithTenantDomain} from '../../../services/TenantService';
import {PriceCurrencyLiteral} from '../../../services/MoneyUtils';
import {Pagination} from '../../../components/Pagination/model/Pagination';

const LineItemCandidateList = React.memo((props) => {

  const {creditAdvice, handleAddCreditLineItem, handleAddAllCreditLineItems} = props;
  const [potentialCreditItems, setPotentialCreditItems] = useState({
    content: [],
    pagination: new Pagination()
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('publishDateStart', toCalenderDateString(creditAdvice.accountingTimespan.start));
    searchParams.set('publishDateEnd', toCalenderDateString(creditAdvice.accountingTimespan.end));
    searchParams.set('selectedVendors', creditAdvice.id.vendorKey);
    searchParams.set('page', potentialCreditItems.pagination.pageNumber);

    purchaseService.searchAccountablePurchases(searchParams)
      .then((payload) => {
        setPotentialCreditItems(potentialCreditItems => ({
          ...potentialCreditItems,
          content: payload.content,
          pagination: payload.pagination
        }));
      })
      .catch(() => {
        notificationService.error('Es ist ein Fehler aufgetreten, bitte versuche es noch einmal.');
      })
      .finally(() => {
        setLoading(false);
      });

  }, [creditAdvice, potentialCreditItems.pagination.pageNumber]);

  const triggerAddAllCreditLineItems = async () => {
    const totalPages = potentialCreditItems.pagination.totalPages;

    if (totalPages > 1) {
      let purchasesForAdvice = [];
      let promises = [];

      for (let page = 0; page <= totalPages; page++) {
        const searchParams = new URLSearchParams();
        searchParams.set('publishDateStart', toCalenderDateString(creditAdvice.accountingTimespan.start));
        searchParams.set('publishDateEnd', toCalenderDateString(creditAdvice.accountingTimespan.end));
        searchParams.set('selectedVendors', creditAdvice.id.vendorKey);
        searchParams.set('page', page);
        promises.push(purchaseService.searchAccountablePurchases(new URLSearchParams(searchParams))
          .then(purchases => purchasesForAdvice.push(...purchases.purchases)));

        searchParams.delete('page');
      }
      await Promise.all(promises)
        .then(() => handleAddAllCreditLineItems(purchasesForAdvice))
        .catch(() => notificationService.error('Konnte Gutschrift nicht erzeugen, versuche es erneut.'));
    } else {
      handleAddAllCreditLineItems(potentialCreditItems.content);
    }
  };

  const togglePaginationPage = (e) => {
    const newState = {
      ...potentialCreditItems,
      pagination: {
        ...potentialCreditItems.pagination,
        pageNumber: e.selected,
      }
    };
    setPotentialCreditItems(newState);
  };

  return (
    <AnimatePresence>

      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
      >
        <motion.div initial={{y: -100}} animate={{y: -10}} exit={{y: -100}} transition={{duration: 0.5}}>
          <Card className='addLineItem__card' data-testid='potentialCreditLineItem--card'>

            {loading && (
              <div style={{padding: '3em 0em'}}>
                <Spinner/>
              </div>
            )}

            {!loading && (
              <>
                <TableContainer component={Paper}>
                  <Table aria-label='simple table'>
                    <TableHead className='addLineItem__tablehead'>
                      <TableRow>
                        <TableCell/>
                        <TableCell>ID</TableCell>
                        <TableCell align='left'>Caption</TableCell>
                        <TableCell align='left'>Preis</TableCell>
                        <TableCell align='left'>Standzeit</TableCell>
                        <TableCell align='left'>Verwendungen</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        potentialCreditItems.content.map((purchase, index) => {
                            let firstUsage;
                            if (purchase.usages.length > 0) {
                              firstUsage = <div>
                                {purchase.usages[0].department.name} | {toDateString(purchase.usages[0].placementTime)} | <a
                                href={prefixWithTenantDomain(purchase.usages[0].documentPath)}>Artikel</a>
                              </div>;
                            } else {
                              firstUsage = <div/>;
                            }

                            return (
                              <TableRow key={index} hover={true} data-testid='potentialCreditLineItem--entry'>
                                <TableCell align='right'>
                                  <div onClick={() => handleAddCreditLineItem(purchase, potentialCreditItems)}
                                       data-testid='potentialCreditLineItem--addCandidateButton'>
                                    <AddCreditLineItemIcon/>
                                  </div>
                                </TableCell>
                                <TableCell>{purchase.assetId.damAssetId}</TableCell>
                                <TableCell>{purchase.metadata.caption}</TableCell>
                                <TableCell><PriceCurrencyLiteral price={purchase.price.value} currency={purchase.price.currency} /> </TableCell>
                                <TableCell>{toDateString(purchase.onlineTimeStart)} - {toDateString(purchase.onlineTimeEnd)}</TableCell>
                                <TableCell>
                                  {firstUsage}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}

                    </TableBody>
                  </Table>
                </TableContainer>
                <div className='addLineItem__actions'>
                  {potentialCreditItems.content.length > 0 ?
                    <Button
                      className='addLineItem__button'
                      data-testid='potentialCreditLineItem--addAllCandidatesButton'
                      size='small'
                      variant='contained'
                      color='primary'
                      type='submit'
                      onClick={() => triggerAddAllCreditLineItems(potentialCreditItems)}
                    >
                      Alle hinzufügen ({potentialCreditItems.pagination.totalElements})
                    </Button>
                    : <p data-testid='potentialCreditLineItem--addAllCandidatesButton__Disabled'>Derzeit keine Posten</p>}
                  {
                    potentialCreditItems.pagination.totalPages > 1 && (
                      <div className='potentialCreditLineItem__Pagination'
                           data-testid='potentialCreditLineItem--PaginationWrapper'>
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          initialPage={potentialCreditItems.pagination.pageNumber}
                          pageCount={potentialCreditItems.pagination.totalPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={(e) => togglePaginationPage(e)}
                          containerClassName={'potentialCreditLineItem__pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}/>
                      </div>
                    )
                  }
                </div>
              </>
            )}
          </Card>
        </motion.div>
      </motion.div>

    </AnimatePresence>
  );
});

export default LineItemCandidateList;
