import { useReducer } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { RaflTheme } from '../theme';
import Login from '../features/login/Login';
import PurchaseList from '../features/purchases/PurchaseList';
import DepartmentList from '../features/department/DepartmentList';
import VendorList from '../features/vendor/VendorList';
import VendorDetails from '../features/vendor/VendorDetails';
import { useAuthContext } from './AuthContext';
import Spinner from '../components/Spinner/Spinner';
import { ThemeProvider } from '@material-ui/core/styles';
import Layout from '../components/Layout/Layout';
import NotFound from '../features/notFound/NotFound';
import VendorCreation from '../features/vendor/VendorCreation';
import { NotificationsContainer } from '../components/Notifications/Notifications';
import { Backoffice } from '../features/backoffice/Backoffice';
import CreditAdviceList from '../features/creditAdvice/CreditAdviceList';
import CreditAdviceDetails from '../features/creditAdvice/CreditAdviceDetails';
import Maintenance from '../features/maintenance/Maintenance';

const AuthLoader = () => {

  const forceUpdate = useReducer(() => ({}), {})[1]

  const authContext = useAuthContext();

  return (
    <>
      {authContext.maintenanceMode && (<Maintenance />)}

      {!authContext.maintenanceMode && (
        <Router>
          <ThemeProvider theme={RaflTheme}>
            <Layout>
              {authContext.isLoading && (<Spinner />)}

              {authContext.userAuthenticated && (
                <>
                  <Switch>
                    <Route exact path='/'
                      render={(props) => <PurchaseList forceUpdate={forceUpdate} {...props} />} />
                    <Route exact path='/usages'
                      render={(props) => <PurchaseList forceUpdate={forceUpdate} {...props} />}
                    />
                    <Route exact path='/costcenters' component={DepartmentList} />
                    <Route exact path='/vendors' component={VendorList} />
                    <Route path='/vendors/new' component={VendorCreation} />
                    <Route path='/vendors/edit/:id' component={VendorDetails} />
                    <Route path='/admin' component={Backoffice} />
                    <Route exact path='/creditadvices' component={CreditAdviceList} />
                    <Route path='/creditadvices/edit/:vendorKey/:serialNumber' component={CreditAdviceDetails} />
                    <Route component={NotFound} />
                  </Switch>
                  <NotificationsContainer />
                </>
              )}

              {!authContext.userAuthenticated && !authContext.isLoading && (
                <>
                  <Route path={'*'} component={Login} />
                  <NotificationsContainer />
                </>
              )}

              {authContext.isError && (<div>Sorry, something went wrong.</div>)}

            </Layout>
          </ThemeProvider>
        </Router>
      )}
    </>
  );
};

export default AuthLoader;
