import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles} from '@material-ui/core/styles';
import './CreditAdviceBatchJobSearchResults.scss'
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import {useState, useMemo} from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '4px 8px 4px 4px',
  },
}));


export const CreditAdviceBatchJobSearchResults = ({results, checkIsSelected, handleSelectVendor}) => {
  const classes = useStyles()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('vendorName');

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedResults = useMemo(() => {
    return results.slice().sort((a, b) => {
      if (orderBy === 'vendorName') {
        return order === 'asc'
          ? a.vendorName.localeCompare(b.vendorName, 'de', { sensitivity: 'base', caseFirst: 'false' })
          : b.vendorName.localeCompare(a.vendorName, 'de', { sensitivity: 'base', caseFirst: 'false' });
      } else if (orderBy === 'count') {
        return order === 'asc' ? a.count - b.count : b.count - a.count;
      }
      return 0;
    });
  }, [results, order, orderBy]);

  return (
    <Table className=""
           data-testid="creditAdviceBatchJobSearchResults"
    >
      <TableHead>
        <TableRow>
          <TableCell>Auswählen</TableCell>
          <TableCell><TableSortLabel active={orderBy === 'vendorName'}
                                     direction={orderBy === 'vendorName' ? order : 'asc'}
                                     onClick={handleRequestSort('vendorName')}>Kreditor</TableSortLabel></TableCell>
          <TableCell><TableSortLabel active={orderBy === 'count'}
                                     direction={orderBy === 'count' ? order : 'asc'}
                                     onClick={handleRequestSort('count')}>Anzahl</TableSortLabel></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedResults.map(({vendor, vendorName, count}) => (
          <TableRow key={vendor.vendorKey}>
            <TableCell className="checkBox">
              <Checkbox
                inputProps={{'data-testid': 'creditAdviceBatchJobSearchResults--checkbox'}}
                value={vendor.vendorKey}
                checked={checkIsSelected(vendor.vendorKey)}
                size={'small'}
                classes={{root: classes.root}}
                onChange={() => handleSelectVendor(vendor.vendorKey)}
              />
            </TableCell>
            <TableCell key={vendor.vendorKey}
                       className="companyName"
                       data-testid="creditAdviceBatchJobSearchResults--item"
            >{vendorName}
            </TableCell>
            <TableCell className="count">{count}</TableCell>
          </TableRow>
        ))
        }
      </TableBody>
    </Table>
  )
}


