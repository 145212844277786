import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {cancelButtonStyles, optimisticSubmitButtonStyles} from './Buttons';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {toDateString} from '../../../../services/DateUtils';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import {notificationService} from '../../../../components/Notifications/Notifications';
import {purchaseService} from '../../services/PurchaseService';

const AssetRepurchase = React.memo(({asset, toggleActionDrawer}) => {

  const [isLoading, setLoading] = useState(false)
  const [selectedUsage, setSelectedUsage] = useState({})
  const [actionEnabled, setActionEnabled] = useState(false);

  const SubmitButton = withStyles(optimisticSubmitButtonStyles)(Button);
  const CancelButton = withStyles(cancelButtonStyles)(Button);

  const handleCancelAction = () => {
    toggleActionDrawer()
  }

  const handleSubmitAction = () => {
    setLoading(true);
    let usage = asset.usages[selectedUsage];
    purchaseService.repurchase(asset.assetId, usage)
      .then(() => {
        notificationService.success('Wiederkauf erfolgreich');
        toggleActionDrawer()
      })
      .catch((err) => {
        let backendErrorMessage = err.message || '';
        let errorMessage = backendErrorMessage ? backendErrorMessage : 'Wiederkauf fehlgeschlagen';
        notificationService.error(errorMessage);
      })
      .finally(() => setLoading(false));
  }

  const handleSelectUsage = (idx) => {
    setSelectedUsage(idx);
    setActionEnabled(true);
  }

  const usagesList = (
    <div className={asset.usages.length > 2 ? 'assetActions__repurchase__wrapper' : ''}>
      <List>
        {(asset.usages || []).map((usage, idx) => {
          const departmentString = usage.department.name;
          const standzeitStr = `Publiziert am ${toDateString(new Date(usage.placementTime))}`;
          return (
            <ListItem key={idx} onClick={() => handleSelectUsage(idx)} data-testid={'repurchase--usage'}>
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={idx === selectedUsage}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={idx} primary={standzeitStr} secondary={departmentString}/>
            </ListItem>
          );
        })}
      </List>
    </div>
  )

  return (
    <>
      {isLoading ?
        <div className='assetActions__spinner' data-testid='assetActions__spinner'>
          <div className='assetActions__spinner--circularProgress'><CircularProgress/></div>
          <div className='assetActions__spinner--message'>
            <h3>Wiederkauf / Verlängerung</h3>
          </div>
        </div>
        :
        <>
          <h1>Wiederkauf / Verlängerung</h1>

          <p className='assetActions__paragraph'>
            Du bist im Begriff einen Wiederkauf / Verlängerung für folgendes Asset durchzuführen.
          </p>

          <p className='assetActions__paragraph' data-testid='assetActions--paragraph__info'>
            Aktuelles Asset: <span>{asset.assetId.damAssetId}</span>
          </p>

          <div className='assetActions__buttonGrid'>
            <SubmitButton
              disabled={!actionEnabled}
              data-testid='repurchase--confirm'
              variant='contained'
              color='primary'
              type='submit'
              onClick={handleSubmitAction}
            >
              Wiederkauf durchführen
            </SubmitButton>

            <CancelButton
              data-testid='repurchase--cancel'
              variant='contained'
              onClick={handleCancelAction}
            >
              Abbrechen
            </CancelButton>
          </div>

          {usagesList}

          </>
      }
    </>
  )
});

export default AssetRepurchase;
