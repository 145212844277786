import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {Link} from 'react-router-dom';
import {Icon} from '@material-ui/core';
import {toDateString} from '../../services/DateUtils';
import Button from '@material-ui/core/Button';
import React, {useState} from 'react';

import {PricingModel} from './models/PricingModel';
import Input from '@material-ui/core/Input';
import './VendorListView.scss';
import {PriceCurrencyLiteral} from '../../services/MoneyUtils';

export const VendorListView = ({vendors = [], loading = false}) => {
  const [filter, setFilter] = useState('');

  const handleFilterChange = (e) => {
    let {value} = e.target;
    setFilter(value);
  };

  const displayedVendors = vendors.filter((vendor) => {
    let searchString = `${vendor.companyName || ''} ${vendor.externalCreditorId || ''} ${vendor.firstName || ''} ${vendor.lastName || ''}`;
    return filter.length <= 0 || searchString.toLowerCase().includes(filter.toLowerCase());
  });

  const vendorListTable = <TableContainer component={Paper}>
    <Table aria-label='simple table'>
      <TableHead className='table__head'>
        <TableRow>
          <TableCell className='vendorlist__actionColumn'/>
          <TableCell align='left' colSpan={2}>Firmenname / Kreditor</TableCell>
          <TableCell align='right'>Stehsatz</TableCell>
          <TableCell align='right'>Fakturierung</TableCell>
          <TableCell align='right'>Letzte Änderung</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {vendors.length > 0 ? displayedVendors.map(v => {
          const pricingModel = PricingModel.fromJson(v);

          return (
            <TableRow key={v.id.vendorKey} hover={true} data-testid='vendorlist--item'>
              <TableCell align='right'>
                <Link
                  to={{pathname: '/vendors/edit/' + v.id.vendorKey}}
                  data-testid={`vendorlist--itemLink--${v.companyName}`}
                >
                  <Icon color='primary'>edit</Icon>
                </Link>
              </TableCell>
              <TableCell>
                <div className='vendorlist__companyNameWrapper' data-testid='vendorlist-companyNameWrapper'>
                  <span>{v.companyName} / <em>{v.externalCreditorId}</em></span>
                  <span className='vendorlist__companyNameWrapper__firstAndLastName'>
                    {v.firstName || ''} {v.lastName || ''}
                  </span>
                </div>
              </TableCell>
              <TableCell align='right'>
                {!v.isComplete && (
                  <div className={`vendorlist__pill vendorlist__pill--yellow vendorlist__pill--vendorStatus`} data-testid='vendorlist-completionStatus'>
                    Unvollständige Daten
                  </div>
                )}
              </TableCell>
              <TableCell align='right' className='nowrap'>
                <PriceCurrencyLiteral price={pricingModel.defaultPrice} currency={pricingModel.currency} />
              </TableCell>
              <TableCell align='right' className='nowrap'>
                {pricingModel.billingTypeLiteral || '-'}
              </TableCell>
              <TableCell align='right'>
                {toDateString(v.lastModified)}
              </TableCell>
            </TableRow>
          );
        }) : (
          <TableRow key={1} hover={true} data-testid='vendorlist--item'>
            <TableCell align='center' colSpan={5} className='vendorlist__noVendorMessage'
                       data-testid='vendorlist-message--no-vendors'>Keine Anbieter vorhanden</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>;

  return (
    <>
      {!loading && (
        <div className='vendorlist__wrapper' data-testid='vendorlist--container'>
          <div className='vendorlist__search' data-testid='vendorlist--search'>
            <div className='vendorlist__createButton'>
              <Button
                component={Link}
                to={'/vendors/new'}
                variant="outlined"
                startIcon={<Icon color='primary'>add</Icon>}
                data-testid='vendorlist--create-button'
              >
                Anbieter anlegen
              </Button>
            </div>

            <div className='vendorlist__filter'>
              <Input
                placeholder="Filter"
                onChange={handleFilterChange}
                variant="outlined"
                inputProps={{'data-testid': 'vendorlist--filter-input'}}
              />
            </div>
          </div>
          {vendorListTable}
        </div>
      )}
    </>
  );
};
