import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './PurchaseList.scss';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../../components/Spinner/Spinner';
import PurchaseTable from './components/PurchaseTable';
import { purchaseService } from './services/PurchaseService';
import { batchJobService } from './services/BatchJobService';
import AssetDetails from './components/AssetDetails';
import SearchResultSummary from '../../components/SearchResultSummary/SearchResultSummary';
import { PurchasesFilter, TabStates } from './components/PurchasesFilter';
import { notificationService } from '../../components/Notifications/Notifications';
import { toCalenderDateString } from '../../services/DateUtils';
import useDetails from '../../hooks/UseDetails';
import TablePagination from '../../components/Pagination/TablePagination';
import { Pagination } from '../../components/Pagination/model/Pagination';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';


const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: '#FF0954',
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 3000,
    margin: '0 auto',
  },
}));


const PurchaseList = ({ searchQuery = window.location.search, forceUpdate }) => {
  const history = useHistory();
  const classes = useStyles();
  const [detailsToggled, toggleDetails] = useDetails()

  const [isLoading, setLoading] = useState(false);
  const [currentSearch, setCurrentSearch] = useState({ searchParams: new URLSearchParams(), tabState: null })
  const [searchResult, setSearchResults] = useState({ pagination: new Pagination() });
  const [currentPurchase, setCurrentPurchase] = useState()
  const [selectedPurchases, setSelectedPurchases] = useState([])
  const [pendingMetadataJobs, setPendingMetadataJobs] = useState([])
  const [pendingCreditAdviceJobs, setPendingCreditAdviceJobs] = useState([])
  const queryFromUrl = new URLSearchParams(searchQuery);
  const tab = queryFromUrl.get('tab');
  const shouldShowCheckboxes = tab === 'ALL' && !!queryFromUrl.get('selectedVendors');
  useEffect(() => {
    // defaults to today if date is not set
    if (!queryFromUrl.has('publishDateStart')) {
      queryFromUrl.set('publishDateStart', toCalenderDateString(new Date()))
    }
    if (!queryFromUrl.has('publishDateEnd')) {
      queryFromUrl.set('publishDateEnd', toCalenderDateString(new Date()))
    }


    executeSearch(queryFromUrl, tab);
    getPendingMetadataJobs()
    getPendingCreditAdviceJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getPendingMetadataJobs = () => {
    shouldShowCheckboxes && batchJobService.getPendingMetadataJobs()
      .then(resData => {
        if (JSON.stringify(pendingMetadataJobs) !== JSON.stringify(resData)) {
          setPendingMetadataJobs(resData)
        }
      })

  }
  const getPendingCreditAdviceJobs = () => {
    batchJobService.getPendingCreditAdviceJobs()
      .then(resData => {
        if (JSON.stringify(pendingCreditAdviceJobs) !== JSON.stringify(resData)) {
          setPendingCreditAdviceJobs(resData)
        }
      })
  }

  // wraps the search in order to be able to add the page info
  const executePagedSearch = (page) => {
    currentSearch.searchParams.set('page', page);
    executeSearch(currentSearch.searchParams, currentSearch.tabState);
  }

  const executeSearch = (searchParams, tabState) => {
    setLoading(true)

    let searchCall;
    switch (tabState) {
      case TabStates.BYID:
        searchCall = purchaseService.searchById(new URLSearchParams(searchParams));
        break;
      case TabStates.ACCOUNTABLE:
        searchCall = purchaseService.searchAccountablePurchases(new URLSearchParams(searchParams))
        break;
      case TabStates.EXPIRE:
        searchCall = purchaseService.searchNearlyExpiredPurchases(new URLSearchParams(searchParams))
        break;
      case TabStates.ALL:
      default:
        searchCall = purchaseService.search(new URLSearchParams(searchParams));
    }

    searchCall
      .then(res => {
        setSearchResults(res)
        // if (TabStates.ALL) getPendingMetadataJobs()
        // if (TabStates.ACCOUNTABLE) getPendingCreditAdviceJobs()
      }
      )
      .then(() => {
        let newUrlParams = new URLSearchParams(searchParams);
        if (tabState) newUrlParams.set('tab', tabState);
        history.push({ search: `?${(newUrlParams.toString())}` });
      })
      .then(() => setCurrentSearch({ searchParams: new URLSearchParams(searchParams), tabState: tabState }))
      .catch(() => notificationService.error('Die Suche ist leider fehlgeschlagen. Probiere es erneut.'))
      .finally(() => setLoading(false));
  }

  // this will also be called when the props change,
  // therefore only call handler when value has changed by user
  const handlePageChange = (e) => {
    if (e.selected !== searchResult.pagination.pageNumber) {
      executePagedSearch(e.selected)
    }
  }

  const handlePurchaseDetails = (purchase, vendor) => {
    setCurrentPurchase({ ...purchase, vendor: vendor })
    toggleDetails()
  }

  const handleSelectPurchase = (id) => {
    let ids = [...selectedPurchases];
    let index = ids.findIndex((e) => e === id);

    if (index === -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }

    setSelectedPurchases(ids);
  }

  const isSelected = (id) => {
    return !!selectedPurchases.find((e) => {
      return e === id
    });
  };



  const checkIsAnyPurchaseSelected = () => {
    return !!selectedPurchases.length
  }


  const filterPurchasesForPendingJobs = () => searchResult.content.filter(el => !pendingMetadataJobs.includes(el.assetId.damAssetId) && !pendingCreditAdviceJobs.includes(el.assetId.damAssetId) && !el.creditAdviceId.serialNumber)

  const handleSelectAllToggle = () => {
    let ids = [...selectedPurchases]
    if (searchResult?.content?.some(v => selectedPurchases.includes(v.assetId.damAssetId))) {
      const contentIds = searchResult.content.map(el => el.assetId.damAssetId)
      ids = ids.filter(function (el) {
        return !contentIds.includes(el)
      })

      setSelectedPurchases(ids)

      return
    }
    const noPurchasesIdsForPendingJobs = filterPurchasesForPendingJobs()
    for (const purchase of noPurchasesIdsForPendingJobs) {
      ids.push(purchase.assetId.damAssetId)
    }
    setSelectedPurchases(ids)
  }

  const handleDeselectAllCheckboxes = () => {
    setSelectedPurchases([])
  }



  const checkIsAllSelected = () => {

    return !!searchResult?.content?.length && searchResult.content.every(v => selectedPurchases.includes(v.assetId.damAssetId))
  }

  const getEntities = () => {
    return selectedPurchases.map(purchaseId => {
      const purchase = searchResult?.content.find(e => e.assetId.damAssetId === purchaseId)
     return { id: purchase.assetId.damAssetId, purchaseOrdinal: purchase.assetId.purchaseOrdinal }
    })
  }


  const isUpdateMetadataButtonEnabled = checkIsAnyPurchaseSelected() && shouldShowCheckboxes

  return (
    <div className={classes.root} data-testid='purchaselist--container'>

      <PurchasesFilter
        handleDeselectAllCheckboxes={handleDeselectAllCheckboxes}
        getPendingMetadataJobs={getPendingMetadataJobs}
        getPendingCreditAdviceJobs={getPendingCreditAdviceJobs}
        forceUpdate={forceUpdate}
        selectedPurchases={selectedPurchases}
        isUpdateMetadataButtonEnabled={isUpdateMetadataButtonEnabled}
        triggerSearch={executeSearch}
        getEntities={getEntities}
      />
      <SearchResultSummary totalCount={searchResult.pagination.totalElements} />
      <div className='purchaseListSelectionMenu'>
       
        {shouldShowCheckboxes &&
          <>
            <div className='selectAll'>
              <CustomCheckbox
                className='creditadvicedetail__checkbox'
                data-testid='purchaseList--selectAll-checkbox'
                disabled={false}
                checked={checkIsAllSelected()}
                onClick={handleSelectAllToggle}
              />
              <div>Alle Auswählen</div>
            </div>

            <button className='deselectAll' data-testid='purchaseList--deselectAll-checkbox' onClick={handleDeselectAllCheckboxes}>Auswahl aufheben</button>
          </>

        }
      </div>

      {isLoading ? <Spinner /> :
        <>
          <div className='table' data-testid='purchaselist--table'>
            <PurchaseTable
              purchases={searchResult.content}
              handleAssetDetails={handlePurchaseDetails}
              handleSelectPurchase={handleSelectPurchase}
              isSelected={isSelected}
              shouldShowCheckboxes={shouldShowCheckboxes}
              pendingMetadataJobs={pendingMetadataJobs}
              searchResult={searchResult}
              pendingCreditAdviceJobs={pendingCreditAdviceJobs}
            />
          </div>

          {searchResult.content && searchResult.content.length > 0 && (
            <TablePagination pagination={searchResult.pagination} handlePageChange={handlePageChange} />
          )}
        </>
      }

      <AssetDetails
        detailsToggled={detailsToggled}
        toggleDetails={toggleDetails}
        purchase={currentPurchase}
      />

    </div>
  );
};

export default PurchaseList;
