import { cancelButtonStyles, optimisticSubmitButtonStyles } from './Buttons';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const AssetPurchaseEditorActionButtons = ({ isSubmitEnabled, submit, toggleActionDrawer, testId }) => {

  const SubmitButton = withStyles(optimisticSubmitButtonStyles)(Button);
  const CancelButton = withStyles(cancelButtonStyles)(Button);
    return (
        <div className="assetActions__buttonGrid">
            <SubmitButton
                disabled={!isSubmitEnabled}
                data-testid={`assetActions--${testId}__submit`}
                variant="contained"
                color="primary"
                type="submit"
                onClick={submit}
            >
                Überschreiben
            </SubmitButton>
            <CancelButton
                data-testid={`assetActions--${testId}__cancel`}
                variant="contained"
                onClick={toggleActionDrawer}
            >
                Abbrechen
            </CancelButton>
        </div>

    )
}

