import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import {PricingModel} from '../models/PricingModel';

export const PricingModelSection = (props) => {
  const initialState = {
    pricingModel: new PricingModel(),
    saveDisabled: true
  };

  const [state, setState] = useState(initialState);
  const [originalState, setOriginalState] = useState(initialState);

  useEffect(() => {
    setState(s => {
      let pricingModel = Object.assign(new PricingModel(), props.data);
      let newState = {...s, pricingModel: pricingModel, saveDisabled: pricingModel.incomplete};
      setOriginalState(newState);
      return newState;
    });
  }, [props]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    state.pricingModel[name] = value;
    let newPricingModel = Object.assign(new PricingModel(), state.pricingModel)
    setState({...state, pricingModel: newPricingModel, saveDisabled: newPricingModel.incomplete});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit(state.pricingModel);
  };

  const handleReset = (evt) => {
    evt.preventDefault();
    setState({...originalState})
  }

  return (
    <>
      <form onSubmit={handleSubmit} data-testid='vendorDetails--pricingModel'>
        <h3 className='vendorDetails__section__headline'>Preismodell & Fakturierung</h3>
        <div className='vendorDetails__section__container'>
          <TextField
            data-testid='pricingModelSection--defaultPrice'
            id='defaultPrice'
            name='defaultPrice'
            label='Standard Preis'
            variant='outlined'
            type='number'
            required={true}
            value={state.pricingModel.defaultPrice}
            onChange={handleInputChange}
          />

          <FormControl variant='outlined' required={true}>
            <InputLabel id='currency-input-label'>
              Währung
            </InputLabel>
            <Select
              displayEmpty={true}
              data-testid='pricingModelSection--currency'
              labelId='currency-label'
              id='currency'
              name='currency'
              value={state.pricingModel.currency}
              onChange={handleInputChange}
              label='Währung'
            >
              {Object.entries(PricingModel.Currency).map(entry => {
                return (
                  <MenuItem key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant='outlined' required={true}>
            <InputLabel id='billingType-input-label'>
              Fakturierung
            </InputLabel>
            <Select
              displayEmpty={true}
              data-testid='pricingModelSection--facType'
              labelId='billingType-label'
              id='billingType'
              name='billingType'
              value={state.pricingModel.billingType}
              onChange={handleInputChange}
              label='Fakturierung'
            >
              {Object.entries(PricingModel.FacturationLabels).map(entry => {
                return (
                  <MenuItem key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant='outlined' required={true}>
            <InputLabel id='lifetime-input-label'>Standzeit</InputLabel>
            <Select
              displayEmpty={true}
              data-testid='pricingModelSection--lifetime'
              labelId='lifetime-label'
              id='vendorLifetime'
              name='lifetime'
              value={state.pricingModel.lifetime}
              onChange={handleInputChange}
              label='Standzeit'
            >
              {Object.entries(PricingModel.LifetimeLabels).map(entry => {
                return (
                  <MenuItem key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant='outlined' required={true}>
            <InputLabel id='accountingType-input-label'>Abrechnungstyp</InputLabel>
            <Select
              displayEmpty={true}
              data-testid='pricingModelSection--accountingType'
              labelId='accountingType-label'
              id='vendorAccountingType'
              name='accountingType'
              value={state.pricingModel.accountingType}
              onChange={handleInputChange}
              label='Abrechnungstyp'
            >
              {Object.entries(PricingModel.AccountingLabels).map(entry => {
                return (
                  <MenuItem key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div className='vendorDetails__section__container'>
          <Button
            size='small'
            variant='contained'
            onClick={handleReset}
          >
            Zurücksetzen
          </Button>

          <Button
            disabled={state.saveDisabled}
            data-testid='pricingModelSection--save'
            size='small'
            variant='contained'
            color='primary'
            type='submit'
          >
            Übernehmen
          </Button>
        </div>
      </form>
    </>);
}
