import {AssetId} from './AssetId';


export class AssetPreview {

  constructor(assetId, thumbnailUrl, damAssetUrl) {
    this.assetId = assetId;
    this.thumbnailUrl = thumbnailUrl;
    this.damAssetUrl = damAssetUrl
  }

  assetId = null;
  thumbnailUrl = null;
  damAssetUrl = null;

  static fromJson(payload = {} ){
    let res = new AssetPreview()
    res.assetId = AssetId.fromJson(payload.assetId)
    res.thumbnailUrl = payload.thumbnailUrl
    res.damAssetUrl = payload.damAssetUrl
    return res
  }

}
