import React from 'react'

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '../../../components/Modal/Modal';
import './BillableConfirmModal.scss';
import './BulkUpdateConfirmModal.scss';


const customCancelButtonStyles = theme => ({
    root: {
        maxWidth: '15em',
        backgroundColor: '#efefef',
        color: '#000',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#d4d4d4',
            boxShadow: 'none'
        },
    }
});

const customSubmitButtonStyles = theme => ({
    root: {
        maxWidth: '15em',
        backgroundColor: '#FF0954',
        color: '#fff',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: 'rgba(248,8,81,0.9)',
            boxShadow: 'none'
        },
    }
});

const CustomSubmitButton = withStyles(customSubmitButtonStyles)(Button);
const CustomCancelButton = withStyles(customCancelButtonStyles)(Button);

const BulkUpdateConfirmModal = React.memo(({modalToggled, toggleModal, submitButtonText, cancelButtonText, handleBatchStatusChange, logMessages}) => {

    const handleCancel = () => {
        toggleModal()
    };

    const handleSubmit = () => {
        handleBatchStatusChange()
    };

    const cancelButton = (buttonText) => (
        <CustomCancelButton
            data-testid='bulkUpdateConfirmModal--cancel'
            className='bulkUpdateConfirmModal__Buttons__cancel'
            size='small'
            variant='contained'
            onClick={handleCancel}
            type='submit'
        >
            {buttonText}
        </CustomCancelButton>)

    const submitButton = (buttonText) => (
        <CustomSubmitButton
            data-testid='bulkUpdateConfirmModal--continue'
            className='bulkUpdateConfirmModal__Buttons__continue'
            size='small'
            variant='contained'
            color='primary'
            type='submit'
            onClick={() => handleSubmit()}
        >
            {buttonText}
        </CustomSubmitButton>
    );

    return (
        <div className='bulkUpdateConfirmModal' data-testid='bulkUpdateConfirmModal'>
            <Modal modalToggled={modalToggled} toggleModal={toggleModal} hasClosingButton={false} modalWidth={'30em'}>

                <div>
                    <div className='bulkUpdateConfirmModal__content'>
                        <p>Du bist dabei, für alle markierten Einträge eine Abrechnung durchzuführen. Dieser Vorgang ist nicht umkehrbar.</p>
                        <p>Wie möchtest du fortfahren?</p>
                    </div>

                    <div className='bulkUpdateConfirmModal__Buttons'>
                        {cancelButton(cancelButtonText)}
                        {submitButton(submitButtonText)}
                    </div>
                </div>

                <div className='bulkUpdateConfirmModal__log'>
                    {logMessages.map((message, index) => (
                        <p key={index}>{message}</p>
                    ))}
                </div>

            </Modal>
        </div>
    );
})

export default BulkUpdateConfirmModal
