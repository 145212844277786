import React, {useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import './MainMenu.scss';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import {useAuthContext} from '../../services/AuthContext';
import handbook from '../../assets/efa_handbook.pdf';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {Assessment, MenuBook, PowerSettingsNew} from '@material-ui/icons';
import {BatchJobsLink} from '../../features/batchJob/components/BatchJobsLink/BatchJobsLink'

const MainMenu = () => {
  const {clearSession, user} = useAuthContext();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    clearSession();
    history.push('/')
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToBackoffice = () => {
    history.push('/admin');
  }

  const redirectToHandbook = () => {
    window.open(handbook);
  }

  return (
    <>
      <nav className='mainNav' data-testid='menu--nav'>
        <NavLink to='/usages'>Verwendungen</NavLink>
        <NavLink to='/creditadvices'>Gutschriften</NavLink>
        <NavLink to='/vendors'>Anbieter</NavLink>
        <NavLink to='/costcenters'>Kostenstellen</NavLink>
        <BatchJobsLink />
        <Toolbar className='logoutMenu'>
          <div>
            <IconButton
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
              data-testid='profile--button'
            >
              <AccountCircle/>
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem disabled={true}>{user.name}</MenuItem>
              <MenuItem onClick={() => redirectToHandbook()} data-testid='handbook--button'>
                <ListItemIcon>
                  <MenuBook/>
                </ListItemIcon>
                Handbuch
              </MenuItem>
              <MenuItem onClick={() => goToBackoffice()}>
                <ListItemIcon>
                  <Assessment/>
                </ListItemIcon>
                Backoffice
              </MenuItem>
              <MenuItem onClick={() => handleLogout()} data-testid='logout--button'>
                <ListItemIcon>
                  <PowerSettingsNew/>
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </nav>
    </>
  );
};

export default MainMenu;
