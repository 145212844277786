import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';

import '../vendorDetailsSections.scss';
import {MAX_INPUT_LENGTH} from '../../../constants';
import {BusinessData} from '../models/BusinessData';
import Button from '@material-ui/core/Button';

export const BusinessDataSection = (props) => {

  let initialState = {
    businessDetails: new BusinessData(),
    saveDisabled: true
  };

  const [state, setState] = useState(initialState);
  const [originalState, setOriginalState] = useState(initialState);

  useEffect(() => {
    setState(s => {
      let data = Object.assign(new BusinessData(), props.data);
      const newState = {...s, businessDetails: data, saveDisabled: data.incomplete};
      setOriginalState({...newState});
      return newState
    })
  }, [props]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    state.businessDetails[name] = value;
    let newData = Object.assign(new BusinessData(), state.businessDetails);
    setState({...state, businessDetails: newData, saveDisabled: newData.incomplete});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit(state.businessDetails);
  };

  const handleReset = (evt) => {
    evt.preventDefault();
    setState({...originalState, saveDisabled: originalState.businessDetails.incomplete})
  }

  return (
    <>
      <form onSubmit={handleSubmit} data-testid='vendorDetails--businessData'>
        <h3 className='vendorDetails__section__headline'>Unternehmensdaten</h3>
        <div className='vendorDetails__section__container'>
          <TextField
            data-testid='businessDataSection--taxRate'
            id='taxRate'
            name='taxRate'
            label='Steuersatz'
            type='number'
            min='0'
            required={false}
            variant='outlined'
            value={state.businessDetails.taxRate}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />

          <TextField
            data-testid='businessDataSection--taxNumber'
            id='taxNumber'
            name='taxNumber'
            label='Steuernummer'
            variant='outlined'
            required={false}
            value={state.businessDetails.taxNumber}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />

          <TextField
            data-testid='businessDataSection--tradeId'
            id='tradeId'
            name='tradeId'
            label='Umsatzsteuernummer'
            variant='outlined'
            required={false}
            value={state.businessDetails.tradeId}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />

        </div>

        <div className='vendorDetails__section__container'>
          <Button
            size='small'
            variant='contained'
            onClick={handleReset}
          >
            Zurücksetzen
          </Button>
          <Button
            disabled={state.saveDisabled}
            data-testid='businessDataSection--save'
            size='small'
            variant='contained'
            color='primary'
            type='submit'
          >
            Übernehmen
          </Button>
        </div>
      </form>
    </>
  );
}
