import moment from 'moment';

const EMPTY_PLACEHOLDER = '';
const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
const DATE_CALENDAR_FORMAT = 'YYYY-MM-DD';

export const toDateString = (input) => {
  let date = input || null

  return (date == null) ? EMPTY_PLACEHOLDER : moment(date).format(DATE_FORMAT);
}

export const toDateTimeString = (input) => {
  let date = input || null

  return (date == null) ? EMPTY_PLACEHOLDER : moment(date).format(DATE_TIME_FORMAT);
}

export const addDays = (days, startDate) => {
  return moment(startDate).add(days, 'days').toDate()
}

export const isFutureDate = (date) => {
  return moment(date).isAfter(moment())
}

export const toCalenderDateString = (date) => (date) ? moment(date).format(DATE_CALENDAR_FORMAT) : EMPTY_PLACEHOLDER
