import NotAvailableNoBorderRadius from '../assets/Thumbs/NotAvailableNoBorderRadius.svg';
import React, {useCallback, useEffect, useState} from 'react';
import {CollageIcon, RestrictionNoteIcon} from '../assets/Icons/AssetInformationIcons';
import NotAvailable from '../assets/Thumbs/NotAvailable.svg';
import {assetService} from '../features/purchases/services/AssetService';

export const AssetImageThumbnailContainer = 'assetImage--ThumbnailContainer'
export const AssetImageAvailableTestId = 'assetImage--thumbnailAvailable'
export const AssetImageNotAvailableTestId = 'assetImage--thumbnailNotAvailable'

const UseAssetImage = React.memo(({
                                    thumbnailUrl,
                                    damAssetUrl,
                                    assetId,
                                    className = '',
                                    testIdAvailable = AssetImageAvailableTestId,
                                    isPurchaseList = false,
                                    restrictionNoteSet = false,
                                    isCollage = false
                                  }) => {

  const removeFallbackThumbnail = useCallback((e) => {
    e.target.previousSibling.remove();
    e.target.style = null;
  }, []);

  const [assetThumbnailUrl, setAssetThumbnailUrl] = useState(thumbnailUrl)
  useEffect(() => {
    const updateThumbnailUrl = async () => {
      const resolvedUrl = await assetService.getAssetThumbnailUrl(damAssetUrl, assetId)
      setAssetThumbnailUrl(resolvedUrl)
    };
    updateThumbnailUrl().catch((error) => {
      console.error(error);
    })
  }, [damAssetUrl]);

  return (
    <div data-testid={AssetImageThumbnailContainer}>
      {isPurchaseList &&
      <>
        {assetThumbnailUrl ?
          <div>
            <div className='purchaseTable__assetImg__container'>
              {restrictionNoteSet && (
                <div className='purchaseTable__assetImg__iconBackground' data-testid='purchaseTable--restrictionNote'>
                  <div className='purchaseTable__assetImg__icon'>
                    <RestrictionNoteIcon/>
                  </div>
                </div>
              )}
              {isCollage && (
                <div className='purchaseTable__assetImg__iconBackground' data-testid='purchaseTable--collage'>
                  <div className='purchaseTable__assetImg__icon'>
                    <CollageIcon/>
                  </div>
                </div>
              )}
            </div>
            <img data-testid={AssetImageNotAvailableTestId} src={NotAvailable} alt=''/>
            <img
              data-testid={testIdAvailable}
              src={assetThumbnailUrl}
              alt=''
              style={{display: 'none'}}
              onLoad={(e) => removeFallbackThumbnail(e)}
            />
          </div>
          : (
            <div>
              {restrictionNoteSet && (
                <div className='purchaseTable__assetImg__iconBackground'>
                  <div className='purchaseTable__assetImg__icon'>
                    <RestrictionNoteIcon/>
                  </div>
                </div>
              )}
              {isCollage && (
                <div className='purchaseTable__assetImg__iconBackground' data-testid='purchaseTable--collage'>
                  <div className='purchaseTable__assetImg__icon'>
                    <CollageIcon/>
                  </div>
                </div>
              )}
              <img data-testid={AssetImageNotAvailableTestId} src={NotAvailable} alt=''/>
            </div>
          )
        }
      </>
      }

      {!isPurchaseList &&
      <div>
        {assetThumbnailUrl ? (
          <div className={className}>
            <img data-testid={AssetImageNotAvailableTestId}
                 src={NotAvailableNoBorderRadius} alt=''/>
            <img
              data-testid={testIdAvailable}
              src={assetThumbnailUrl}
              alt=''
              style={{display: 'none'}}
              onLoad={removeFallbackThumbnail}
            />
          </div>
        ) : (
          <div className={className}>
            <img data-testid={AssetImageNotAvailableTestId}
                 src={NotAvailableNoBorderRadius} alt=''/>
          </div>
        )}
      </div>
      }
    </div>
  )
})

export default UseAssetImage
