import React, {useEffect, useState} from 'react';

import {vendorService} from './services/VendorService';
import {notificationService} from '../../components/Notifications/Notifications';
import {VendorListView} from './VendorListView';

const VendorList = (props) => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    vendorService.getVendors()
      .then(setVendors)
      .catch(() => notificationService.error("Anbieter konnten nicht geladen werden"))
      .finally(() => setLoading(false));
  }, []);

  return <VendorListView {...props} loading={loading} vendors={vendors}/>
}

export default VendorList;

