import {BasicModel} from './BasicModel';

export class BusinessData extends BasicModel {
  taxRate = '';
  taxNumber = '';
  tradeId = '';

  static fromJson(payload = {}) {
    let m = new BusinessData();
    m.taxRate = payload.taxRate || '';
    m.taxNumber = payload.taxNumber || '';
    m.tradeId = payload.tradeId || '';
    return m;
  }

  get mandatoryFields() {
    return []
  };
}
