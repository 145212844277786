import {httpClient} from '../../../infrastructure/HttpClient';
import {handleHttpStatusCode} from '../../../infrastructure/HandleHttpStatusCode';



class BatchJobService {
     url= '/usage-catalog/api/batch-job/';
     

    get(){
        return httpClient.get(`${this.url}`)
        .then(handleHttpStatusCode)
        .then(response => {
            return response
        });
    }
    
    post(payload){
        return httpClient.post(`${this.url}`, payload)
        .then(handleHttpStatusCode)   
        .then(response => {
        });
    }

    getPendingMetadataJobs(){
        return httpClient.get(`${this.url + 'entities/pending/METADATA' }`)
        .then(handleHttpStatusCode) 
        .then(response => {
            return response
        });

    }

    getPendingCreditAdviceJobs(){
        return httpClient.get(`${this.url + 'entities/pending/CREDIT_ADVICE' }`)
        .then(handleHttpStatusCode) 
        .then(response => {
            return response
        });

    }
}

export const batchJobService = new BatchJobService();