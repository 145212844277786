import React from 'react';
import {AccountBalanceOutlined, EmailOutlined, ListAltOutlined, PictureAsPdfOutlined} from '@material-ui/icons';

import './CreditAdviceStatusSummary.scss';
import Tooltip from '@material-ui/core/Tooltip';
import {toDateTimeString} from '../../../services/DateUtils';

const CreditAdviceStatusSummary = ({creditAdvice}) => {

  const billingTracker = creditAdvice ? creditAdvice.billingTracker : {};

  const emailSent = billingTracker.accountingNotificationSentAt;
  const emailToolip = emailSent ? `Email wurde um ${toDateTimeString(emailSent)} Uhr versandt` : '';

  const sapDone = billingTracker.fileNameSapObjectRecords;
  const sapToolip = sapDone ? `SAP Dokument '${billingTracker.fileNameSapObjectRecords}' wurde um ${toDateTimeString(billingTracker.accountingRecordsArchivedAt)} Uhr übertragen` : '';

  const csvFileExists = billingTracker.fileNameCsvRecord;
  const csvToolip = csvFileExists ? `CSV/XLS Dokument '${billingTracker.fileNameCsvRecord}' wurde um ${toDateTimeString(billingTracker.accountingNotificationSentAt)} Uhr versandt` : '';

  const pdfFileExists = billingTracker.fileNamePdfRecord;
  const pdfToolip = pdfFileExists ? `PDF Dokument '${billingTracker.fileNamePdfRecord}' wurde um ${toDateTimeString(billingTracker.accountingNotificationSentAt)} Uhr versandt` : '';

  return (
    <div className='creditadvice__summary'>
      <Tooltip title={emailToolip} data-testid='statusSummary--email'>
        <EmailOutlined className={emailSent ? '' : 'creditadvice__summary--icon__disabled'}/>
      </Tooltip>

      <Tooltip title={csvToolip} data-testid='statusSummary--csv'>
        <ListAltOutlined className={csvFileExists ? '' : 'creditadvice__summary--icon__disabled'}/>
      </Tooltip>

      <Tooltip title={pdfToolip} data-testid='statusSummary--pdf'>
        <PictureAsPdfOutlined className={pdfFileExists ? '' : 'creditadvice__summary--icon__disabled'}/>
      </Tooltip>

      <Tooltip title={sapToolip} data-testid='statusSummary--sap'>
        <AccountBalanceOutlined className={sapDone ? '' : 'creditadvice__summary--icon__disabled'}/>
      </Tooltip>
    </div>
  )
}

export default CreditAdviceStatusSummary;

