import React, {useEffect, useState} from 'react';
import './CreditAdviceFilter.scss';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import DatePicker from '../../../components/DatePicker/DatePicker';
import {toCalenderDateString} from '../../../services/DateUtils';
import {vendorService} from '../../vendor/services/VendorService';
import {notificationService} from '../../../components/Notifications/Notifications';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment/moment';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '.8rem',
  },
  root: {
    fontSize: '.8rem',
  }
}));

const initialSearchState = {
  page: '0',
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month'),
  vendor: null,
}

const initializeSearchState = (searchQuery) => {
  const urlQuery = new URLSearchParams(searchQuery);
  const newSearchState = {};
  if (urlQuery.has('searchStartDate')) newSearchState.startDate = new Date(urlQuery.get('searchStartDate'));
  if (urlQuery.has('searchEndDate')) newSearchState.endDate = new Date(urlQuery.get('searchEndDate'));
  if (urlQuery.has('selectedVendor')) newSearchState.selectedVendor = urlQuery.get('selectedVendor');
  if (urlQuery.has('page')) newSearchState.selectedVendor = urlQuery.get('page');
  return {...initialSearchState, ...newSearchState};
}

export const CreditAdviceFilter = ({
                                     searchQuery = window.location.search,
                                     triggerSearch,
                                     isBulkUpdateDisabled,
                                     showBulkUpdateConfirmModal
                                   }) => {

  const classes = useStyles();
  const [vendors, setVendors] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState(initializeSearchState(searchQuery));

  useEffect(() => {
    vendorService.getVendors()
      .then(vendors => {
        setVendors(vendors);

        const vendor = vendors.find((vendor) => vendor.id.vendorKey === searchState.selectedVendor);
        setSearchState((searchState) => ({...searchState, vendor: vendor || null}));
      })
      .catch(() => notificationService.error('Konnte Anbieter nicht laden, bitte aktualisiere die Seite.'))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchAll = (evt) => {
    evt?.preventDefault();

    const searchParams = new URLSearchParams();
    if (searchState.startDate) searchParams.set('searchStartDate', toCalenderDateString(searchState.startDate));
    if (searchState.endDate) searchParams.set('searchEndDate', toCalenderDateString(searchState.endDate));
    if (searchState.selectedVendor) searchParams.set('selectedVendor', searchState.selectedVendor);
    if (searchState.page) searchParams.set('page', searchState.page);

    triggerSearch(searchParams)
  }

  const handleClearSearch = () => {

    setSearchState({...initialSearchState});
    const searchParams = new URLSearchParams();
    searchParams.set('searchStartDate', toCalenderDateString((moment().startOf('month'))));
    searchParams.set('searchEndDate', toCalenderDateString((moment().endOf('month'))));
    searchParams.delete('selectedVendor');
    searchParams.set('page', '0');
    triggerSearch(searchParams)
  }

  return (
    <div className="creditAdviceFilter" data-testid="creditAdvicelist--filter">
      <Card className="creditAdviceFilterCard">
        <form className="creditAdviceFilterWrapper" data-testid="creditAdviceFilterWrapper"
              onSubmit={handleSearchAll}>
          <div className="creditAdviceFilterWrapper__datePickers">
            <div className="creditAdviceFilterWrapper__datePickers__startDatePicker"
                 data-testid="creditAdviceFilter--startdate">
              <DatePicker
                setSelectedDate={(date) => setSearchState({...searchState, startDate: date})}
                selectedDate={searchState.startDate}
                datePickerId={'startDatePicker'}
                id="startDatePickerUsages"
                classes={{label: classes.label}}
              />
            </div>
            <div className="creditAdviceFilterWrapper__datePickers__endDatePicker"
                 data-testid="creditAdviceFilter--enddate">
              <DatePicker
                setSelectedDate={(date) => setSearchState({...searchState, endDate: date})}
                selectedDate={searchState.endDate}
                datePickerId={'endDatePicker'}
                id="endDatePickerUsages"
              />
            </div>
          </div>
          <div className="creditAdviceFilterWrapper__vendorPicker">
            <Autocomplete
              value={searchState.vendor}
              onChange={(_, vendor) => setSearchState({
                ...searchState,
                selectedVendor: vendor?.id?.vendorKey,
                vendor: vendor
              })}
              data-testid="creditAdvicelist--filter__vendors"
              ListboxProps={{'data-testid': 'vendorOptionsList'}}
              id="tags-standard"
              loading={isLoading}
              disabled={isLoading}
              options={vendors}
              loadingText={''}
              noOptionsText={'Keine Anbieter'}
              getOptionLabel={vendor => vendor.companyName}
              classes={{root: classes.root}}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label={`Anbieter (${vendors.length})`}
                />
              )}
            />
          </div>
          <div className="creditAdviceFilterButtons">
            <Button
              type="submit"
              data-testid="creditAdvicelist--filter__search"
              onClick={handleSearchAll}
              className="creditAdviceFilterCreditAdviceButtons--search"
            >Suchen
            </Button>

            <Button
              data-testid="creditAdvicelist--filter__reset"
              onClick={handleClearSearch}
              className="creditAdviceFilterCreditAdviceButtons--reset"
            >Zurücksetzen
            </Button>
          </div>
          <div className="creditAdviceFilterCreateBatchJobButton">
            <Button
              disabled={!isBulkUpdateDisabled()}
              data-testid="creditAdvicelist--filter__create"
              onClick={() => showBulkUpdateConfirmModal()}
              className="creditAdviceFilterCreateBatchJobButton--button"
            >Abrechnung versenden
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}
