import {TENANT_BILD} from '../constants';

export const prefixWithTenantDomain = (str, tenant = TENANT_BILD) => {
  switch (tenant) {
    case TENANT_BILD:
      return `https://www.bild.de${str}`;
    default:
      return str;
  }
}
