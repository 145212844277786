export class Metadata {

  foreignId = null;
  caption = null;
  title = null;
  credit = null;
  objectDescription = null;
  source = null;
  externalCreditorId = null;
  currency = null;
  onlinePrice = null;

  static fromJson(payload) {
    let metadata = new Metadata();

    metadata.externalCreditorId = payload.externalCreditorId.value || null;
    metadata.foreignId = payload.foreignId.value || null;
    metadata.title = payload.title.value || null;
    metadata.credit = payload.credit.value || null;
    metadata.caption = payload.caption.value || null;
    metadata.onlinePrice = payload.onlinePrice.value || null;
    metadata.currency = payload.currency.value || null;
    metadata.source = payload.source.value || null;
    metadata.objectDescription = payload.objectDescription.value || null;

    return metadata;
  }
}
