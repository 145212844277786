import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {assetService} from '../../services/AssetService';
import {notificationService} from '../../../../components/Notifications/Notifications';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Metadata} from '../../models/Metadata';
import Tooltip from '@material-ui/core/Tooltip';
import {cancelButtonStyles, optimisticSubmitButtonStyles} from './Buttons';
import {PriceLiteral} from '../../../../services/MoneyUtils';

const AssetMetadataRefresh = React.memo(({asset, toggleActionDrawer}) => {

    const initialMetadata = {metadata: new Metadata(), version: undefined};
    const [freshMetadata, setFreshMetadata] = useState(initialMetadata);
    const [isLoading, setLoading] = useState(true)

    const SubmitButton = withStyles(optimisticSubmitButtonStyles)(Button);
    const CancelButton = withStyles(cancelButtonStyles)(Button);
    useEffect(() => {
      setLoading(true)
      assetService.fetchMetadataWithDamAssetUrl(asset)
        .then(res => {
          setFreshMetadata(state => (
            {...state, metadata: res.metadata, version: res.version}
          ))
        })
        .catch((err) => {
          let backendErrorMessage = err ? err.message : '';
          notificationService.error(`Konnte Metadaten für Asset '${asset.assetId.damAssetId}' nicht laden. ${backendErrorMessage}`);
          toggleActionDrawer();
        })
        .finally(() => {
          setLoading(false)
        })
    }, [asset.assetId, toggleActionDrawer]);

    const handleRefreshMetadata = () => {
      setLoading(true)
      assetService
        .refreshMetadata(asset.assetId, freshMetadata.version, asset.metadata.damAssetUrl)
        .then(() => {
          toggleActionDrawer()
          notificationService.success('Aktualisierung der Metadaten war erfolgreich, bitte gebe EFA etwas Zeit, die Änderungen zu übernehmen. Aktualisiere die Seite ggf. erneut.')
        })
        .catch(response => {
          if (response.isConflict) {
            notificationService.error('Metadaten wurden in der Zwischenzeit geändert, bitte prüfe erneut die Daten.')
            setFreshMetadata({...freshMetadata, metadata: response.metadata})
          } else if (response.isNotAllowed) {
            notificationService.error('Das Asset befindet sich in einer Gutschrift, die Metadaten können daher nicht aktualisiert werden.')
          } else {
            let backendErrorMessage = response ? response.message : '';
            notificationService.error(`Aktualisierung der Metadaten ist fehlgeschlagen, versuche es noch einmal. ${backendErrorMessage}`)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return (
      <>
        {isLoading ?
          <div className='assetActions__spinner' data-testid='assetActions__spinner'>
            <div className='assetActions__spinner--circularProgress'><CircularProgress/></div>
            <div className='assetActions__spinner--message'>
              <h3>Metadaten aktualisieren</h3>
            </div>
          </div>
          :
          <>
            <h1 data-testid='assetActions--mainTitle__metadataRefresh'>Metadaten aktualisieren</h1>

            <p className='assetActions__paragraph'>
              Du bist dabei, die Metadaten in EFA mit denen aus Fotoware zu aktualisieren.
              <br/>
              Aktuelles Asset: <span>{asset.assetId.damAssetId}</span>
            </p>

            <div className='assetActions__buttonGrid'>
              <SubmitButton
                data-testid='metadataRefresh--submit'
                variant='contained'
                color='primary'
                type='submit'
                onClick={() => handleRefreshMetadata()}
              >
                Aktualisieren
              </SubmitButton>
              <CancelButton
                data-testid='metadataRefresh--cancel'
                variant='contained'
                onClick={() => toggleActionDrawer()}
              >
                Abbrechen
              </CancelButton>
            </div>

            <>
              <div className='assetActions__metadata__header' data-testid='metadataRefresh--header'>
                <h2>Alter Wert</h2>
                <h2>Neuer Wert</h2>
              </div>
              <div className='assetActions__metadata__wrapper'>

                <div className='assetActions__metadata' data-testid='metadataRefresh--externalCreditorId'>
                  <h1>Ext. Kreditor ID / Anbieter-ID</h1>
                  <div className='assetActions__metadata__valueGrid'>
                    <div data-testid='metadataRefresh--externalCreditorId--currentValue'>
                      <span>{asset.metadata.externalCreditorId || '-'}</span></div>
                    <div data-testid='metadataRefresh--externalCreditorId--fotowareValue'>
                      <span>{freshMetadata.metadata.externalCreditorId || '-'}</span></div>
                  </div>
                </div>

                <div className='assetActions__metadata' data-testid='metadataRefresh--foreignId'>
                  <h1 className={'nowrap'}>Fremd-ID</h1>
                  <div className='assetActions__metadata__valueGrid'>
                    <div data-testid='metadataRefresh--foreignId--currentValue'>
                      <span>{asset.metadata.foreignId || '-'}</span></div>
                    <div data-testid='metadataRefresh--foreignId--fotowareValue'>
                      <span>{freshMetadata.metadata.foreignId || '-'}</span></div>
                  </div>
                </div>

                <Tooltip
                  title='Preis und Währung müssen einen gültigen Wert haben, andern falls werden diese nicht übernommen'
                  enterDelay={300} leaveDelay={200} placement='top'>
                  <div className='assetActions__metadata' data-testid='metadataRefresh--onlinePrice'>
                    <h1>Online Preis</h1>
                    <div className='assetActions__metadata__valueGrid'>
                      <div data-testid='metadataRefresh--onlinePrice--currentValue'>
                        <PriceLiteral price={asset.price.value}/>
                      </div>
                      <div data-testid='metadataRefresh--onlinePrice--fotowareValue'>
                        <span>{freshMetadata.metadata.onlinePrice || '-'}</span></div>
                    </div>
                  </div>
                </Tooltip>

                <Tooltip
                  title="Preis und Währung müssen einen gültigen Wert haben, andern falls werden diese nicht übernommen"
                  enterDelay={300} leaveDelay={200} placement="top">
                  <div className='assetActions__metadata' data-testid='metadataRefresh--currency'>
                    <h1>Währung</h1>
                    <div className='assetActions__metadata__valueGrid'>
                      <div data-testid='metadataRefresh--currency--currentValue'>
                        <span>{asset.price.currency || '-'}</span></div>
                      <div data-testid='metadataRefresh--currency--fotowareValue'>
                        <span>{freshMetadata.metadata.currency || '-'}</span></div>
                    </div>
                  </div>
                </Tooltip>

                <div className='assetActions__metadata' data-testid='metadataRefresh--source'>
                  <h1>Quelle</h1>
                  <div className='assetActions__metadata__valueGrid'>
                    <div data-testid='metadataRefresh--source--currentValue'><span>{asset.metadata.source || '-'}</span>
                    </div>
                    <div data-testid='metadataRefresh--source--fotowareValue'>
                      <span>{freshMetadata.metadata.source || '-'}</span></div>
                  </div>
                </div>

                <div className='assetActions__metadata' data-testid='metadataRefresh--objectDescription'>
                  <h1>Objektbeschreibung</h1>
                  <div className='assetActions__metadata__valueGrid'>
                    <div data-testid='metadataRefresh--objectDescription--currentValue'>
                      <span>{asset.metadata.objectDescription || '-'}</span></div>
                    <div data-testid='metadataRefresh--objectDescription--fotowareValue'>
                      <span>{freshMetadata.metadata.objectDescription || '-'}</span></div>
                  </div>
                </div>

                <div className='assetActions__metadata' data-testid='metadataRefresh--caption'>
                  <h1>Bildunterschrift</h1>
                  <div className='assetActions__metadata__valueGrid'>
                    <div data-testid='metadataRefresh--caption--currentValue'><span>{asset.metadata.caption || '-'}</span>
                    </div>
                    <div data-testid='metadataRefresh--caption--fotowareValue'>
                      <span>{freshMetadata.metadata.caption || '-'}</span></div>
                  </div>
                </div>

                <div className='assetActions__metadata' data-testid='metadataRefresh--credit'>
                  <h1>Credit</h1>
                  <div className='assetActions__metadata__valueGrid'>
                    <div data-testid='metadataRefresh--credit--currentValue'><span>{asset.metadata.credit || '-'}</span>
                    </div>
                    <div data-testid='metadataRefresh--credit--fotowareValue'>
                      <span>{freshMetadata.metadata.credit || '-'}</span></div>
                  </div>
                </div>

                <div className='assetActions__metadata' data-testid='metadataRefresh--title'>
                  <h1>Titel</h1>
                  <div className='assetActions__metadata__valueGrid'>
                    <div data-testid='metadataRefresh--title--currentValue'><span>{asset.metadata.title || '-'}</span>
                    </div>
                    <div data-testid='metadataRefresh--title--fotowareValue'>
                      <span>{freshMetadata.metadata.title || '-'}</span></div>
                  </div>
                </div>

              </div>
            </>
          </>
        }
      </>
    )
  }
)

export default AssetMetadataRefresh;
