import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import '../vendorDetailsSections.scss';
import IBAN, * as Iban from 'iban';
import {MAX_INPUT_LENGTH} from '../../../constants';
import {AddressAndBank} from '../models/AddressAndBank';

export const AddressAndBankSection = (props) => {

  const validators = {
    iban: (value) => {
      return IBAN.isValid(value) ? '' : 'IBAN fehlerhaft'
    },
    email: (value) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase()) ? '' : 'E-Mail Adresse fehlerhaft';
    }
  }

  let initialState = {
    addressAndBank: new AddressAndBank(),
    saveDisabled: true
  };

  let initialErrorState = {};

  const [state, setState] = useState(initialState);
  const [originalState, setOriginalState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);

  useEffect(() => {
    let addressAndBank = Object.assign(new AddressAndBank(), props.data);

    setState(s => {
      const newState = {...s, addressAndBank: addressAndBank, saveDisabled: addressAndBank.incomplete};
      setOriginalState({...newState})
      return newState
    })
  }, [props]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    state.addressAndBank[name] = value.trim();
    let newAddressAndBank = Object.assign(new AddressAndBank(), state.addressAndBank);
    const fieldValidator = validators[name]
    if (fieldValidator) {
      setErrorState({...errorState, [name]: fieldValidator(value.trim())})
    }

    setState({...state, addressAndBank: newAddressAndBank, saveDisabled: newAddressAndBank.incomplete});
  };

  const handleIbanChange = (event) => {
    const {name, value} = event.target;

    let newAddressAndBank = {...state.addressAndBank}
    newAddressAndBank.iban = Iban.electronicFormat(value);

    const fieldValidator = validators[name]
    if (fieldValidator) {
      setErrorState({...errorState, [name]: fieldValidator(value)})
    }

    setState({...state, addressAndBank: newAddressAndBank, saveDisabled: newAddressAndBank.incomplete});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit(state.addressAndBank);
  };

  const handleReset = (evt) => {
    evt.preventDefault();
    setState({...originalState, saveDisabled: originalState.addressAndBank.incomplete})
  }

  return (
    <>
      <form onSubmit={handleSubmit} data-testid='vendorDetails--addressAndBank'>
        <h3 className='vendorDetails__section__headline'>Adresse</h3>
        <div className='vendorDetails__section__container'>
          <TextField
            data-testid='addressAndBankSection--streetAndNumber'
            id='streetAndNumber'
            label='Straße und Hausnummer'
            name='streetAndNumber'
            variant='outlined'
            value={state.addressAndBank.streetAndNumber}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}

          />
          <TextField
            data-testid='addressAndBankSection--zipCode'
            id='zipCode'
            label='PLZ'
            name='zipCode'
            variant='outlined'
            value={state.addressAndBank.zipCode}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />
          <TextField
            data-testid='addressAndBankSection--city'
            id='city'
            label='Ort'
            name='city'
            variant='outlined'
            value={state.addressAndBank.city}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />
          <TextField
            data-testid='addressAndBankSection--country'
            id='country'
            label='Land'
            name='country'
            variant='outlined'
            value={state.addressAndBank.country}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />
          <TextField
            data-testid='addressAndBankSection--phoneNumber'
            id='phoneNumber'
            label='Telefonnummer'
            name='phoneNumber'
            variant='outlined'
            value={state.addressAndBank.phoneNumber}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />
          <TextField
            required={true}
            data-testid='addressAndBankSection--email'
            id='email'
            label='E-Mail Adresse'
            name='email'
            variant='outlined'
            error={!!errorState.email}
            helperText={errorState.email}
            value={state.addressAndBank.email}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />

        </div>

        <h3 className='vendorDetails__section__headline'>Kontodaten</h3>
        <div className='vendorDetails__section__container'>
          <TextField
            data-testid='addressAndBankSection--accountHolder'
            id='accountHolder'
            label='Kontoinhaber'
            name='accountHolder'
            variant='outlined'
            value={state.addressAndBank.accountHolder}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}

          />
          <TextField
            data-testid='addressAndBankSection--bankName'
            id='bankName'
            label='Bank'
            name='bankName'
            variant='outlined'
            value={state.addressAndBank.bankName}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}

          />
          <TextField
            data-testid='addressAndBankSection--iban'
            id='iban'
            label='IBAN'
            name='iban'
            variant='outlined'
            error={!!errorState.iban}
            helperText={errorState.iban}
            value={IBAN.printFormat(state.addressAndBank.iban)}
            onChange={handleIbanChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}
          />
          <TextField
            data-testid='addressAndBankSection--bic'
            id='bic'
            label='BIC'
            name='bic'
            variant='outlined'
            value={state.addressAndBank.bic}
            onChange={handleInputChange}
            inputProps={{maxLength: MAX_INPUT_LENGTH}}

          />
        </div>

        <div className='vendorDetails__section__container'>
          <Button
            size='small'
            variant='contained'
            onClick={handleReset}
          >
            Zurücksetzen
          </Button>
          <Button
            disabled={state.saveDisabled}
            data-testid='addressAndBankSection--save'
            size='small'
            variant='contained'
            color='primary'
            type='submit'
          >
            Übernehmen
          </Button>
        </div>

      </form>
    </>
  );
}
