import React from 'react';
import moment from 'moment';
import {
  MajorWarning,
  MinorWarning,
  PaymentDone,
  PaymentMissing,
  PaymentPending
} from '../../../assets/Icons/PurchaseStatusIndicatorIcons';
import { toDateString, toDateTimeString } from '../../../services/DateUtils';
import Tooltip from '@material-ui/core/Tooltip';
import { PaymentStatus } from '../models/Purchase';
import { Link } from 'react-router-dom';

export const PurchaseStatusIndicator = React.memo(({ purchase, isDetailed }) => {

  const enrichmentLabels = {
    ONLINETIME_ENRICHMENT: 'Standzeitberechnung',
    VENDOR_ENRICHMENT: 'Anbieterzuordnung',
    FOTOWARE_ENRICHMENT: 'Fotoware Metadaten',
    PRICING_ENRICHMENT: 'Preisberechnung',
    DEPARTMENT_ENRICHMENT: 'Ressortzuordnung',
  }

  const enrichmentTooltips = {
    ONLINETIME_ENRICHMENT: 'Im Bild oder im Anbieter konnte keine Standzeit gefunden werden.',
    VENDOR_ENRICHMENT: 'Die Metadaten des Bildes lassen keine Zuordnung zu. Überprüfe ob der Anbieter in EFA hinterlegt ist.',
    FOTOWARE_ENRICHMENT: 'Die Metadaten zu diesem Bild konnten in Fotoware nicht ermittelt werden.',
    PRICING_ENRICHMENT: 'Im Bild und oder im Anbieter konnten keine Informationen zum Preis gefunden werden. Überprüfe ggf. die Formatierung im Preisfeld bei Fotoware!',
    DEPARTMENT_ENRICHMENT: 'Die Zuordnung vom abzurechnenden Ressort ist fehlgeschlagen. Überprüfe das Url-Mapping der Kostenstellen!',
  }

  let paymentMissing = purchase.paymentStatus === PaymentStatus.IMMINENT;
  let paymentPending =  purchase.paymentStatus === PaymentStatus.COVERED;
  let paymentDone = purchase.paymentStatus === PaymentStatus.ISSUED;

  let failedStates = purchase.enrichments.filter(x => !x.isDone)

  let minorFailure = false;
  let severeFailure = false;

  let treshold = moment().subtract(15, 'minute')

  for (let state of failedStates) {
    if (moment(state.lastUpdated).isBefore(treshold)) {
      severeFailure = true;
      minorFailure = false;
      break
    } else {
      minorFailure = true;
    }
  }

  const entries = failedStates.map((state, index) => {
    let tooltip = enrichmentTooltips[state.step] || '-'
    let date = toDateTimeString(state.lastUpdated)

    return (
      <Tooltip key={index} title={`${tooltip} (${date})`} enterDelay={300} leaveDelay={200} placement='top'>
        <span data-testid={`purchaseStatusIndicator--failedState-${state.step}`}>{enrichmentLabels[state.step]}</span>
      </Tooltip>
    );
  });

  const RestrictionNoteNotice = ({ purchase }) => (
    purchase.restrictionNoteSet ?
      <div className={`assetDetails__restrictionNote ${(minorFailure || severeFailure) ? '' : 'assetDetails__restrictionNote--topPadding'}`}>
        <h3>Sperrvermerk gesetzt:</h3>
        <span className='assetDetails__restrictionNote--flexRow' data-testid='assetDetails--restrictionNote'>
          am {toDateString(purchase.restrictionNoteLastModified) || ''}
        </span>
      </div> : <></>);

  const creditAdviceLink = (purchase) => {
    if (!purchase.creditAdviceId || !purchase.creditAdviceId.vendorKey || !purchase.creditAdviceId.serialNumber) {
      return null;
    }
    return `/creditadvices/edit/${purchase.creditAdviceId.vendorKey}/${purchase.creditAdviceId.serialNumber}`;
  }

  return (
    <>
      {isDetailed && (minorFailure || severeFailure) ? (
        <div data-testid='purchaseStatusIndicator--details'>
          <div className='assetDetails__enrichmentStatus--flexColumn'>
            <h1 className='assetDetails__enrichmentStatus--alertHeader'>Fehlgeschlagene Prozesse</h1>
            {entries}
          </div>
          <div className='assetDetails__enrichmentStatus--flexRow assetDetails__enrichmentStatus--lastUpdated'>
            <h3>Letzte Aktualisierung:</h3><span data-testid='purchaseStatusIndicator--lastUpdated'>{toDateTimeString(purchase.lastUpdated) || '-'}</span>
          </div>
        </div>
      ) : null}

      {isDetailed && <RestrictionNoteNotice purchase={purchase} />}

      {!isDetailed && (
        <>
          {creditAdviceLink(purchase) &&
            <Link to={creditAdviceLink(purchase)} data-testid="purchaseStatusIndicator--creditAdviceLink">
              {paymentMissing && <span data-testid='purchaseStatusIndicator--paymentMissing' title="Offene Rechnung"><PaymentMissing /></span>}
              {paymentPending && <span data-testid='purchaseStatusIndicator--paymentPending' title="In Bearbeitung"><PaymentPending /></span>}
              {paymentDone && <span data-testid='purchaseStatusIndicator--paymentDone' title="Abgerechnet"><PaymentDone /></span>}
            </Link>
          }
          {!creditAdviceLink(purchase) &&
            <>
              {paymentMissing && <span data-testid='purchaseStatusIndicator--paymentMissing' title="Offene Rechnung"><PaymentMissing /></span>}
              {paymentPending && <span data-testid='purchaseStatusIndicator--paymentPending' title="In Bearbeitung"><PaymentPending /></span>}
              {paymentDone && <span data-testid='purchaseStatusIndicator--paymentDone' title="Abgerechnet"><PaymentDone /></span>}
            </>
          }
          {severeFailure && <span data-testid='purchaseStatusIndicator--severe'><MajorWarning /></span>}
          {minorFailure && <span data-testid='purchaseStatusIndicator--minor'><MinorWarning /></span>}
        </>
      )}
    </>
  )
})
