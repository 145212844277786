export const ENVIRONMENT_DEVELOPMENT = "development";
export const ENVIRONMENT_END2END = "end2end";
export const ENVIRONMENT_STAGING = "staging";
export const ENVIRONMENT_PRODUCTION = "production";

export class EnvironmentService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getEnvironment() {
    switch(this.baseUrl) {
      case "efa.axelspringer.de": return ENVIRONMENT_PRODUCTION;
      case "staging.efa.axelspringer.de": return ENVIRONMENT_STAGING;
      case "e2e.efa.axelspringer.de": return ENVIRONMENT_END2END;
      default: return ENVIRONMENT_DEVELOPMENT;
    }
  }
}
const baseUrl = window.location.hostname || '';
export const environmentService = new EnvironmentService(baseUrl);
