import React from 'react'
import {AnimatePresence, motion} from 'framer-motion';
import './AssetDrawer.scss'

const animationVariants = {
  initial: {
    x: 300,
    opacity: 0
  },
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: [0.17, 0.67, 0.83, 0.67],
      stiffness: 6000,
    },
  },
  exit: {
    x: 480,
    opacity: 0,
    transition: {
      duration: 0.2
    }
  }
}

const AssetDrawer = React.memo(({actionDrawerToggled, children}) => {

  return (
    <AnimatePresence>
      {actionDrawerToggled && (
        <>
          <div
            style={{
              position: 'fixed',
              top: 'min(2.5vh, 2rem)',
              right: 'min(40vw, 40em)',
              zIndex: '15'
            }}
          >
            <motion.div
              variants={animationVariants}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <div className='assetActions__drawer' data-testid='assetActions--drawer'>
                <div className='assetActions__content' data-testid='assetDetails--content'>
                  <div className='assetActions__wrapper'>
                    {children}
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  )
});

export default AssetDrawer
