import React from 'react';
import {Link} from 'react-router-dom';

const DepartmentToBeCharged = React.memo(({name}) => {
  return (
    <div className='purchaseTable__department'>
      <Link to={'costcenters/'}>
        <div data-testid='purchaseTable--department'>
          {name || 'Nicht zugeordnet'}
        </div>
      </Link>
    </div>
  )
})

export default DepartmentToBeCharged
