import React, {useState} from 'react'
import Modal from '../../../components/Modal/Modal';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {TextareaAutosize} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import './AggregateModal.scss';
import BackofficeService, {prepareEventsAsJson} from '../services/BackofficeService';
import {notificationService} from '../../../components/Notifications/Notifications';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const initialState = {
  originDamId: 'fotoware',
  damAssetId: '',
  displayPayloadAggregate: '',
  displayPayloadEvents: '',
  asEvents: false,
  countEvents: 0
}

const AssetAggregateModal = ({modalToggled, toggleModal}) => {

  const [state, setState] = useState(initialState);

  const handleOriginDamIdChanged = (event) => {
    let {value} = event.target;
    setState({...state, originDamId: value});
  }

  const handleDamAssetIdChanged = (event) => {
    let {value} = event.target;
    setState({...state, damAssetId: value});
  }

  const handleToggleAsEvents = (event, checked) => {
    setState({...state, asEvents: checked});
  }

  const handleSubmit = () => {
    if (state.asEvents) {
      BackofficeService.getAssetAggregateEvents(state.originDamId, state.damAssetId)
        .then((payload) => {
          let {resStr, countEvents} = prepareEventsAsJson(payload);
          setState({...state, displayPayloadEvents: resStr, countEvents: countEvents})
        })
        .catch(() => notificationService.error(`Konnte Aggregate '${state.damAssetId}' nicht finden`));
    } else {
      BackofficeService.getAssetAggregate(state.originDamId, state.damAssetId)
        .then((payload) => {
          const str = JSON.stringify(payload, null, 4)
          setState({...state, displayPayloadAggregate: str})
        })
        .catch(() => notificationService.error(`Konnte Aggregate '${state.damAssetId}' nicht finden`));
    }

  };

  return (
    <div>
      <Modal modalToggled={modalToggled} toggleModal={toggleModal} hasClosingButton={false}
             modalBackgroundColor={'#ffffff'} modalWidth={'60vw'}>
        <div className='aggregateModal'>
          <h2 className='aggregateModal__title'>Fetch Asset aggregate</h2>
          <div className='aggregateModal__grid'>
            <div className='aggregateModal__grid__originDamId'>
              <TextField
                value={state.originDamId}
                onChange={handleOriginDamIdChanged}
                id='OriginDamId'
                label='OriginDamId'
                variant='outlined'
              />
            </div>
            <div className='aggregateModal__grid__damAssetId'>
              <TextField
                fullWidth={true}
                xs={6}
                value={state.damAssetId}
                onChange={handleDamAssetIdChanged}
                id='DamAssetId'
                label='DamAssetId'
                variant='outlined'
              />
            </div>
            <div className='aggregateModal__grid__rawEvents'>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    onChange={(evt, checked) => handleToggleAsEvents(evt, checked)}
                    value='asRawEvents'/>
                }
                label={'Raw Events'}
              />
              {state.asEvents && <div>Events fetched: <strong>{state.countEvents}</strong></div>}
            </div>
          </div>
          <FormControl fullWidth={true}>
            <TextareaAutosize
              readOnly={true}
              className='aggregateModal__textarea'
              rowsMin={20}
              rowsMax={20}
              value={state.asEvents ? state.displayPayloadEvents : state.displayPayloadAggregate}
            />
          </FormControl>
          <Box>
            <Button onClick={() => toggleModal()} type='submit'> Close </Button>
            <Button color='primary' type='submit' onClick={() => handleSubmit()}> Fetch </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default AssetAggregateModal;
