import {toDateString} from '../../../services/DateUtils';
import React from 'react';
import {resolutionStrategyLiteral} from '../services/AssetService';
import {CollageChildrenList} from './CollageChildrenList';

export const CollageInformation = ({purchase}) => {
  return (
    <div className='assetDetails__purchase' data-testid='assetDetails--information--collage'>
      <div className='assetDetails__headerGrid'>
        <div>
          <h1>Asset</h1>
          <span data-testid='assetDetails--assetDamId'>
          {purchase.assetId.damAssetId || '-'}
        </span>
        </div>
        <div>
          <h1>Eingangsdatum</h1>
          <span data-testid='assetDetails--dateCreated'>
          {toDateString(purchase.metadata.dateCreated) || '-'}
        </span>
        </div>
        <div>
          <h1>Titel</h1>
          <span data-testid='assetDetails--title'>
          {purchase.metadata.title || '-'}
        </span>
        </div>
      </div>
      <div className='assetDetails__onlineTime'>
        <h1>Standzeit</h1>
        <div className='assetDetails__DatePickerGrid'>
        <span data-testid='assetDetails--onlineTimeStart'>
          {toDateString(purchase.onlineTimeStart) || '-'}
        </span>
          <span>bis</span>
          <span data-testid='assetDetails--onlineTimeEnd'>
          {toDateString(purchase.onlineTimeEnd) || '-'}
        </span>
          <span data-testid='assetDetails--onlineTimeAmount'>
          {purchase.metadata.onlineLife || '-'} Tage <i
            className='assetDetails__resolutionStrategy'>{resolutionStrategyLiteral(purchase.onlineTimeCalculationStrategy)}</i>
        </span>
        </div>
      </div>
      <div className='assetDetails__collageChildren' data-testid='assetDetails--collageChildren--wrapper'>
        <CollageChildrenList purchase={purchase}/>
      </div>
    </div>
  )
}
