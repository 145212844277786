import React from 'react';

import './styles/main.scss';
import './styles/fonts.scss';
import AuthProvider from './services/AuthProvider';
import AuthLoader from './services/AuthLoader';

const App = () => {
  return (
    <AuthProvider>
      <AuthLoader />
    </AuthProvider>
  );
};

// we gotta have some style in the console. right?
console.log(
  "%caxel springer\uFF3F\n%cefa\n\n%c\u00A92020 %c Team Tailor \n%cideas engineering",
  "font-size:16px; color:black; font-weight:800; font-family: 'MontAS', sans-serif;",
  "color: red;",
  "color: black;",
  "font-weight:400; font-size:10px;",
  "font-weight:800;"
);

export default App;
