import React from 'react'

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '../../../components/Modal/Modal';
import './BillableConfirmModal.scss';

const customCancelButtonStyles = theme => ({
  root: {
    maxWidth: '15em',
    backgroundColor: '#efefef',
    color: '#000',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#d4d4d4',
      boxShadow: 'none'
    },
  }
});

const customSubmitButtonStyles = theme => ({
  root: {
    maxWidth: '15em',
    backgroundColor: '#FF0954',
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(248,8,81,0.9)',
      boxShadow: 'none'
    },
  }
});

const CustomSubmitButton = withStyles(customSubmitButtonStyles)(Button);
const CustomCancelButton = withStyles(customCancelButtonStyles)(Button);

const BillableConfirmModal = React.memo(({modalToggled, toggleModal, submitButtonText, cancelButtonText, content: vendorEmail, handleBillingStateChangeAndToggleModal}) => {

  const handleCancel = () => {
    toggleModal()
  };

  const handleSubmit = () => {
    handleBillingStateChangeAndToggleModal()
  };

  const cancelButton = (buttonText) => (
    <CustomCancelButton
      data-testid='billableConfirmModal--cancel'
      className='billableConfirmModal__Buttons__cancel'
      size='small'
      variant='contained'
      onClick={handleCancel}
      type='submit'
    >
      {buttonText}
    </CustomCancelButton>)

  const submitButton = (buttonText) => (
    <CustomSubmitButton
      data-testid='billableConfirmModal--continue'
      className='billableConfirmModal__Buttons__continue'
      size='small'
      variant='contained'
      color='primary'
      type='submit'
      onClick={() => handleSubmit()}
    >
      {buttonText}
    </CustomSubmitButton>
  );

  return (
    <div className='billableConfirmModal' data-testid='billableConfirmModal'>
      <Modal modalToggled={modalToggled} toggleModal={toggleModal} hasClosingButton={false} modalWidth={'30em'}>

        <div>
          <div className='billableConfirmModal__content'>
            <p>Du bist dabei eine honorapflichtige Gutschrift zu erstellen. Dieser Vorgang ist nicht umkehrbar.</p>
            <p>Der Anbieter wird per E-Mail an folgende Addresse informiert: <span data-testid='billableConfirmModal--email'>{vendorEmail}</span></p>
            <p>Wie möchtest du fortfahren?</p>
          </div>

          <div className='billableConfirmModal__Buttons'>
            {cancelButton(cancelButtonText)}
            {submitButton(submitButtonText)}
          </div>
        </div>

      </Modal>
    </div>
  );
})

export default BillableConfirmModal
