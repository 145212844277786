import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useHistory} from 'react-router-dom';
import './VendorCreation.scss';
import {MAX_INPUT_LENGTH} from '../../constants';
import {vendorService} from './services/VendorService';
import {PersonalData, SALUTATION_OPTIONS} from './models/PersonalData';
import {notificationService} from '../../components/Notifications/Notifications';

const VendorCreation = () => {
  const history = useHistory();

  const disableSave = (state) => {
    return !state.externalCreditorId || !state.companyName;
  }

  const initialState = {
    externalCreditorId: '',
    firstName: '',
    lastName: '',
    companyName: '',
    salutation: Object.keys(SALUTATION_OPTIONS)[0],
    saveDisabled: true
  }

  let [state, setState] = useState(initialState);

  const handleSubmit = (event) => {
    event.preventDefault();

    setState({...state, saveDisabled: true});

    const personalData = PersonalData.fromJson(state);
    vendorService.createVendor(personalData)
      .then(res => {
        history.push(`/vendors/edit/${res.id}`);
      })
      .catch(() => {
        setState({...state, saveDisabled: false});
        notificationService.error('Der Anbieter konnte nicht gespeichert werden. Überprüfe ob die externe Kreditor Id bereits existiert.')
      })
  };

  const handleInputChange = (event) => {
    let {name, value} = event.target;
    let newState = {...state, [name]: value};
    newState.saveDisabled = disableSave(newState);
    setState(newState);
  }

  const salutationItems = Object.entries(SALUTATION_OPTIONS).map(([key, value]) => <MenuItem key={key} value={key}>{value}</MenuItem>);

  return (
    <>
      <div
        className='vendorCreation__wrapper'
        data-testid='vendorCreation--wrapper'
      >
        <h1>Neuen Anbieter anlegen</h1>
        <form onSubmit={handleSubmit} data-testid='vendorCreation--form'>
          <div className='vendorCreation__grid'>
            <TextField
              required={true}
              data-testid='vendorCreation--externalCreditorId'
              id='externalCreditorId'
              label='(Ext) Kreditor ID'
              name='externalCreditorId'
              variant='outlined'
              value={state.externalCreditorId}
              onChange={handleInputChange}
              inputProps={{maxLength: MAX_INPUT_LENGTH}}
            />
            <TextField
              required={true}
              data-testid='vendorCreation--companyName'
              id='companyName'
              name='companyName'
              label='Firma'
              variant='outlined'
              value={state.companyName}
              onChange={handleInputChange}
              inputProps={{maxLength: MAX_INPUT_LENGTH}}
            />
            <Select
              data-testid='vendorCreation--salutation'
              id="salutation"
              name="salutation"
              value={state.salutation}
              onChange={handleInputChange}
            >
              {salutationItems}
            </Select>
            <TextField
              className='vendorCreation__input__firstName'
              data-testid='vendorCreation--firstName'
              id='firstName'
              name='firstName'
              label='Vorname'
              variant='outlined'
              value={state.firstName}
              onChange={handleInputChange}
              inputProps={{maxLength: MAX_INPUT_LENGTH}}
            />
            <TextField
              className='vendorCreation__input__lastName'
              data-testid='vendorCreation--lastName'
              id='lastName'
              name='lastName'
              label='Nachname'
              variant='outlined'
              value={state.lastName}
              onChange={handleInputChange}
              inputProps={{maxLength: MAX_INPUT_LENGTH}}
            />
            <Button
              disabled={state.saveDisabled}
              data-testid='vendorCreation--save'
              className='vendorCreation__saveButton'
              size='small'
              variant='contained'
              color='primary'
              type='submit'
            >
              Speichern
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default VendorCreation;
