import {useCallback, useEffect, useState} from 'react'

const useModal = () => {
  const [modalToggled, setModalToggled] = useState(false)

  const toggleModal = useCallback(() => (setModalToggled(!modalToggled)), [modalToggled]);

  // locks the body from scrolling when modal is toggled
  const lockBackgroundWhenModalVisible = useCallback((cssOverflow) => (document.body.style.overflow = cssOverflow), [])

  useEffect(() => {
    if (modalToggled) {
      lockBackgroundWhenModalVisible('hidden');
    } else {
      lockBackgroundWhenModalVisible('unset')
    }
    return lockBackgroundWhenModalVisible
  }, [modalToggled, lockBackgroundWhenModalVisible]);

  return [modalToggled, toggleModal]
}

export default useModal
