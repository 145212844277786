import React from 'react';
import './Maintenance.scss';
import {MaintenanceBackground} from '../../assets/Svgs/MaintenanceBackground';
import WaveTop from '../../assets/Imgs/wave-top.png';
import WaveMid from '../../assets/Imgs/wave-mid.png';
import WaveBot from '../../assets/Imgs/wave-bot.png';

const Maintenance = () => {
  return (
    <>
      <div className='maintenance__wrapper' data-testid='maintenance--wrapper'>
        <div className='maintenance__content'>
          <h1>
            Wir arbeiten im Moment schwer im Hintergrund, damit du bald wieder Spaß an EFA hast.
          </h1>
          <MaintenanceBackground/>
          <h1><span>Schau doch später wieder rein.</span>️</h1>
        </div>

      </div>
      <div className="waveWrapper waveAnimation">
        <ul className="circles">
          <li/>
          <li/>
          <li/>
          <li/>
          <li/>
          <li/>
          <li/>
          <li/>
          <li/>
          <li/>
        </ul>
        <div className="waveWrapperInner bgTop">
          <div className="wave waveTop"
               style={{backgroundImage: `url(${WaveTop})`}}/>
        </div>
        <div className="waveWrapperInner bgMiddle">
          <div className="wave waveMiddle"
               style={{backgroundImage: `url(${WaveMid})`}}/>
        </div>
        <div className="waveWrapperInner bgBottom">
          <div className="wave waveBottom"
               style={{
                 backgroundImage: `url(${WaveBot})`
               }}/>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
