import React, {useEffect, useState} from 'react';

import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import './DepartmentList.scss';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {departmentService} from './services/DepartmentService';

const DepartmentList = () => {

  let initialState = {
    defaultDepartment: {},
    otherDepartments: [],
    sortKey: 'urlPattern',
    loading: true
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    departmentService.fetchDepartments()
      .then((response) => {
        setState(state => ({
          ...state,
          defaultDepartment: response.defaultDepartment,
          otherDepartments: response.otherDepartments,
          loading: false
        }));
      });
  }, [initialState.sortKey]);


  const tableHeadings = [
    {
      key: 'urlPattern',
      label: 'URL Mapping'
    },
    {
      key: 'costCenterName',
      label: 'Kostenstelle'
    }, {
      key: 'costCenterId',
      label: 'Kostenstellen- / Abrechnungsnummer'
    },
    {
      key: 'resortName',
      label: 'Ressort'
    }, {
      key: 'resortId',
      label: 'Ressort ID'
    },];

  const tableHeadItems = tableHeadings.map(heading =>
    <TableCell align='left' key={heading.key}>
      <TableSortLabel
        direction={'desc'}
        className='departmentlist__sortLabel'
        active={state.sortKey === heading.key}>{heading.label}</TableSortLabel>
    </TableCell>
  );


  return (
    <>
      {!state.loading && (
        <div className='departmentlist__wrapper' data-testid='departmentlist--container'>
          <h1 className='departmentlist__title'>Kostenstellen</h1>

          <TableContainer component={Paper} data-testid='departmentlist--table'>
            <Table aria-label='simple table'>
              <TableHead className='table__head'>
                <TableRow>
                  {tableHeadItems}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={'defaultDepartmentId'}
                  hover={true}
                  className='departmentlist__defaultDepartment'
                  data-testid='departmentlist--item'
                >
                  <TableCell className='departmentlist__pattern'>
                    DEFAULT
                  </TableCell>
                  <TableCell> {state.defaultDepartment.costCenterName} </TableCell>
                  <TableCell> {state.defaultDepartment.costCenterId}  </TableCell>
                  <TableCell>{state.defaultDepartment.name}</TableCell>
                  <TableCell>{state.defaultDepartment.foreignId}</TableCell>

                </TableRow>
                {state.otherDepartments.map((department, idx) => (
                  <TableRow
                    key={idx}
                    hover={true}
                    data-testid='departmentlist--item'
                  >
                    <TableCell className='departmentlist__pattern'>{department.urlPattern}</TableCell>
                    <TableCell>{department.costCenterName}</TableCell>
                    <TableCell>{department.costCenterId}</TableCell>
                    <TableCell>{department.name}</TableCell>
                    <TableCell>{department.foreignId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default DepartmentList;
