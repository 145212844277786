import {httpClient} from '../infrastructure/HttpClient';

class MaintenanceService {
  static url = '/usage-catalog/admin/maintenance-mode';

  getMaintenanceMode() {
    return httpClient
      .head(MaintenanceService.url)
      .then(_ => false)
      .catch(xhr => xhr.statuscode === 503)
  }
}

export const maintenanceService = new MaintenanceService();
