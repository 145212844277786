import {PersonalData} from './PersonalData';
import {PricingModel} from './PricingModel';
import {AddressAndBank} from './AddressAndBank';
import {BusinessData} from './BusinessData';

export class Vendor {
  id = '';
  lastModified = new Date();
  personalData = new PersonalData()
  pricingModel = new PricingModel()
  addressAndBank = new AddressAndBank()
  businessDetails = new BusinessData()

  static fromJson(payload = {}) {
    let vendor = new Vendor();
    vendor.id = payload.id;
    vendor.lastModified = new Date(payload.lastModified)
    vendor.personalData = PersonalData.fromJson(payload.personalData || {})
    vendor.pricingModel = PricingModel.fromJson(payload.pricingModel || {});
    vendor.addressAndBank = AddressAndBank.fromJson({...payload.address || {}, ...payload.bankDetails || {}});
    vendor.businessDetails = BusinessData.fromJson(payload.businessDetails || {})
    return vendor;
  }
}
