import {useEffect, useRef, useState} from 'react'
import Spinner from '../../../../components/Spinner/SpinnerInline'
import Popover from '@material-ui/core/Popover';
import './BatchJobsLink.scss'
import {batchJobService} from '../../../purchases/services/BatchJobService'
import {FaCheck} from 'react-icons/fa';
import {FaExclamationCircle} from 'react-icons/fa'

const batchjobStates = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
}

const states = {
  PENDING: 'In Arbeit',
  RUNNING: 'In Arbeit',
  SUCCESS: 'Fertig',
  FAILED: 'Fehler'
}

const jobType = {
  METADATA: 'METADATA',
  CREDIT_ADVICE: 'CREDIT_ADVICE'
}

const getStateLabel = (val) => {
  return states[val]
}

const checkIsAnyFailed = (data) => {
  for (const el of data) {
    if (el.state === batchjobStates.FAILED) {
      return true
    }
  }
  return false
}

function getPendingStatus(entities) {
  const pendingJobs = entities.length - entities.filter(entity => entity.state === batchjobStates.PENDING).length
  return `${pendingJobs} / ${entities.length}`
}

export const BatchJobsLink = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([])
  const popoverRef = useRef();

  useEffect(() => {
    let unmounted = false;
    !unmounted && batchJobService.get()
      .then(resData => {
        if (JSON.stringify(data) !== JSON.stringify(resData)) {
          setData(resData)
        }
      })
    return () => {
      unmounted = true
    };
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);


  const checkIsAnyPending = () => {
    for (const el of data) {
      if (el.state === batchjobStates.PENDING || el.state === batchjobStates.RUNNING) {
        return true
      }
    }

    return false
  }

  const checkIsAnyFailing = () => {
    for (const el of data) {
      if (el.state === batchjobStates.FAILED) {
        return true
      }
    }

    return false
  }

  const renderButton = () => {
    if (checkIsAnyFailing()) {
      return (<div data-testid="batchJobLink--icon-failed"><FaExclamationCircle color={'red'}/></div>)
    }
    if (checkIsAnyPending()) {
      return (<div data-testid="batchJobLink--icon-pending"><Spinner color={'black'}/></div>)
    }

    return (<div data-testid="batchJobLink--icon-success"><FaCheck size={20} color={'green'}/></div>)
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target) && anchorEl && !anchorEl.contains(event.target)) {
      handlePopoverClose();
    }
  };

  const open = Boolean(anchorEl);
  checkIsAnyPending()

  if (!data.length) {
    return ''
  }

  return (
    <div
      onClick={handlePopoverOpen}>

      <div aria-owns={open ? 'mouse-over-popover' : undefined}
           aria-haspopup="true"
      >
        <div data-testid="batchJobLink--icon">{renderButton()}</div>
      </div>
      <Popover
        ref={popoverRef}
        style={{pointerEvents: 'none'}}
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div data-testid="batchJobLink--popover__content" className="popoverContent">
          {data.map(({id, type, startDate, endDate, state, vendorName, vendorKey, entities}) => {
            const shortType = type === jobType.METADATA ? 'MD' : 'CA'
            const isCreditAdvice = type === jobType.CREDIT_ADVICE
            return (
              <div key={id} className="item">
                <div
                  data-testid="batchJobLink--popover__content_item"
                  className="batchJob"
                >
                                    <span
                                      className="jobTitle">{`${shortType}: ${startDate} - ${endDate} ${vendorName} `}
                                    </span>
                  <span data-testid="batchJobLink--popover__content_item__state"
                        className={`status__${state.toLowerCase()}`}>{getStateLabel(state)}: {getPendingStatus(entities)}</span>
                </div>
                {isCreditAdvice && checkIsAnyFailed(entities) && (
                  <div
                    data-testid="batchJobLink--popover__content_failed_credit_items"
                    className="failedJobs"
                  >
                    {entities.filter(({state}) => state === batchjobStates.FAILED).map(({creditorName}) => {
                      return (
                        <div key={id+creditorName} data-testid="batchJobLink--popover__content_failed_credit_item" className="batchJob"
                        >
                                                    <span
                                                      className="jobTitle">{`${creditorName} `}
                                                    </span>
                          <span className={'status__failed'}>{states.FAILED}</span>
                        </div>
                      )
                    })}
                  </div>)}
              </div>
            )
          })}
        </div>
      </Popover>
    </div>

  )
}
