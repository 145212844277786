export class Pagination {
  totalElements = 0;
  totalPages = 0;
  pageNumber = 0;
  pageSize = 50;

  static fromJson(payload) {
    let pagination = new Pagination();

    pagination.totalElements = payload.totalElements || 0;
    pagination.totalPages = payload.totalPages || 0;
    pagination.pageNumber = payload.number || 0;
    pagination.pageSize = payload.size || 50;

    return pagination;
  }
}
