import {Vendor} from '../models/Vendor';
import {httpClient} from '../../../infrastructure/HttpClient';
import {handleHttpStatusCode} from '../../../infrastructure/HandleHttpStatusCode';

const CREATION_ERROR = 'CREATION_ERROR';
const CREATION_ERROR_CONFLICT = 'CREATION_ERROR_CONFLICT';
const CACHE_TTL_MS = 10000;

class VendorService {
  static url = '/usage-catalog/api/vendors';
  vendorCacheLastReset = Date.now();
  vendorCache = {};

  createVendor(personalData) {
    return httpClient.post(VendorService.url, personalData)
      .then(handleHttpStatusCode)
      .then(res => {
        if (!res.id) return Promise.reject(CREATION_ERROR);
        else return res;
      })
      .catch(response => {
        if (response.status === 409) {
          return Promise.reject(CREATION_ERROR_CONFLICT);
        }
        return Promise.reject(CREATION_ERROR);
      });
  }

  getVendors() {
    return httpClient.get(VendorService.url)
      .then(handleHttpStatusCode)
      .then(data => data || [])
  }

  getVendorById(id) {
    return httpClient.get(`${VendorService.url}/${id}`)
      .then(handleHttpStatusCode)
      .then(response => Vendor.fromJson(response || {}))
  }

  getVendorByIdCached(id, ttl = CACHE_TTL_MS) {
    // reset if ttl has been reached
    if (Date.now() > (this.vendorCacheLastReset + ttl)) {
      this.vendorCache = {};
      this.vendorCacheLastReset = Date.now();
    }

    // return cached result or fetch & update if not found
    if (this.vendorCache[id]) {
      return this.vendorCache[id];
    }

    this.vendorCache[id] = this.getVendorById(id);
    return this.vendorCache[id];
  }

  updatePricingModel(id, data) {
    return httpClient.put(`${VendorService.url}/${id}/pricing-model`, data)
      .then(handleHttpStatusCode)
      .then(response => Vendor.fromJson(response || {}))
  }

  updatePersonalData(id, data) {
    return httpClient.put(`${VendorService.url}/${id}/personal-data`, data)
      .then(handleHttpStatusCode)
      .then(response => Vendor.fromJson(response || {}))
  }

  updateAddressAndBank(id, data) {
    let updateAddressAndBankCommand = {
      streetAndNumber: data.streetAndNumber,
      zipCode: data.zipCode,
      city: data.city,
      country: data.country,
      phoneNumber: data.phoneNumber || null,
      email: data.email || null,
      accountHolder: data.accountHolder,
      bankName: data.bankName,
      bic: data.bic,
      iban: data.iban || null
    };
    return httpClient.put(`${VendorService.url}/${id}/address-and-bank`, updateAddressAndBankCommand)
      .then(handleHttpStatusCode)
      .then(response => Vendor.fromJson(response || {}))
  }

  updateBusinessDetails(id, data) {
    let updateBusinessDetailsCommand = {
      tradeId: data.tradeId || null,
      taxRate: data.taxRate,
      taxNumber: data.taxNumber || null
    }

    return httpClient.put(`${VendorService.url}/${id}/business-details`, updateBusinessDetailsCommand)
        .then(handleHttpStatusCode)
        .then(response => Vendor.fromJson(response || {}))
  }
}

export const vendorService =  new VendorService();
