
export class AssetId {

  constructor(dam, damAssetId, tenant) {
    this.dam = dam;
    this.damAssetId = damAssetId;
    this.tenant = tenant;
  }

  dam = null
  damAssetId = null
  tenant = null

  static fromJson(payload = {}) {
    let res = new AssetId()
    res.dam = payload.originDam
    res.damAssetId = payload.originDamId
    res.tenant = payload.tenantId
    return res
  }

}

