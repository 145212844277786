import React, { useState } from 'react';
import { notificationService } from '../../../../components/Notifications/Notifications';
import { addDays, isFutureDate, toDateString } from '../../../../services/DateUtils';
import { purchaseService } from '../../services/PurchaseService';
import TextField from '@material-ui/core/TextField';
import { AssetPurchaseEditorActionButtons } from './AssetPurchaseEditorActionButtons';


const AssetPurchaseEditorOnlineTime = React.memo(({
  asset,
  toggleActionDrawer
}) => {

  const [lifeTime, setLifeTime] = useState(asset.metadata.onlineLife);
  const [onlineTimeEnd, setOnlineTimeEnd] = useState(asset.onlineTimeEnd);

  const calculateExpiryDate = (days) => {
    if (days !== '' && asset.onlineTimeStart) {
      const newOnlineTimeEnd = addDays(parseInt(days), asset.onlineTimeStart);
      setOnlineTimeEnd(newOnlineTimeEnd);
    }
  }
  const submitOnlineLife = () => {
    purchaseService.updateOnlineLife(asset.assetId, lifeTime)
      .then(() => {
        toggleActionDrawer();
        notificationService.success('Anpassung der Standzeit war erfolgreich, bitte gebe EFA etwas Zeit, die Änderungen zu übernehmen. Aktualisiere die Seite ggf. erneut.');
      })
      .catch((err) => {
        let backendErrorMessage = err ? err.message : '';
        notificationService.error(`Anpassung der Standzeit ist fehlgeschlagen. ${backendErrorMessage}`)
      })
  };

  const handleLifeTime = (event) => {
    const days = event.target.value;
    setLifeTime(days);
    calculateExpiryDate(days);
  }

  const disableSubmit = !asset.onlineTimeStart || !isFutureDate(onlineTimeEnd) || !lifeTime;
  const updateOnlineTimeSupported = !asset.isCollage;

  return (
    <div className="assetActions__section">
      <h1>Standzeit anpassen</h1>

      {asset.isCollage &&
        <p className="assetActions__paragraph" data-testid="assetActions--onlineLife__info">Die Standzeit einer Collage
          kann aktuell noch nicht
          angepasst werden.</p>
      }

      {updateOnlineTimeSupported &&
        <>
          <p className="assetActions__paragraph">
            Du bist dabei, die Standzeit zu überschreiben. Die Anpassung wird anschließend zu Fotoware und LeanCMS
            synchronisiert .
          </p>

          <div className="assetActions__paragraph">
            Das sich ergebende Ablaufdatum ist der <b
              data-testid="assetActions--onlineLife__expiryDate">{toDateString(onlineTimeEnd)}</b>.
          </div>

          <div className="assetActions__picker">
            <TextField
              label="Anzahl der Tage"
              type="number"
              value={lifeTime}
              onChange={handleLifeTime}
              inputProps={{
                'data-testid': 'assetActions--onlineLife__input'
              }}
            />
          </div>
        </>
      }
      < AssetPurchaseEditorActionButtons isSubmitEnabled={!disableSubmit} toggleActionDrawer={toggleActionDrawer} submit={submitOnlineLife} testId={"onlineLife"} />
    </div>
  );
});

export default AssetPurchaseEditorOnlineTime;
