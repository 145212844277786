import {useCallback, useEffect, useState} from 'react';

const useDetails = () => {
  const [detailsToggled, setDetailsToggled] = useState(false)

  const toggleDetails = useCallback(() => setDetailsToggled(!detailsToggled), [detailsToggled])

  // locks the body from scrolling when modal is toggled
  const lockBackgroundWhenModalVisible = useCallback((cssOverflow) => (document.body.style.overflow = cssOverflow), [])

  useEffect(() => {
    if (detailsToggled) {
      lockBackgroundWhenModalVisible('hidden');
    } else {
      lockBackgroundWhenModalVisible('unset')
    }
    return lockBackgroundWhenModalVisible
  }, [detailsToggled, lockBackgroundWhenModalVisible]);

  return [detailsToggled, toggleDetails]
}

export default useDetails
