import _ from 'lodash';
import {httpClient} from '../../../infrastructure/HttpClient';

export const prepareEventsAsJson = (eventsStr) => {
  let resStr = ""
  let countEvents = 0
  eventsStr.split("\n").forEach(line => {
    if (line) {
      countEvents += 1
      resStr += JSON.stringify(JSON.parse(line), null, 4) + "\n\n"
    }
  })
  return {resStr, countEvents};
}

class BackofficeService {
  static BACKOFFICE_URL = '/usage-catalog/api/admin';

  getAggregateSummaries() {
    return httpClient.get(BackofficeService.BACKOFFICE_URL + '/aggregates/')
      .then(res => res.data)
      .then(res => _.sortBy(res, 'name'))
  }

  getReadModelSummaries() {
    return httpClient.get(BackofficeService.BACKOFFICE_URL + '/read-models')
      .then(res => res.data)
      .then(res => _.sortBy(res, 'name'))
  }

  getEnrichmentSummary(days = 1) {
    return httpClient.get(BackofficeService.BACKOFFICE_URL + '/read-models/stats/' + days)
      .then(res => res.data);
  }

  getPurchasesAggregate(damId, damAssetId) {
    return httpClient.get( `/usage-catalog/api/purchases-wm/dam/${damId}/damAssetId/${damAssetId}`)
      .then(res => res.data);
  }

  getPurchasesAggregateEvents(damId, damAssetId) {
    return httpClient.get( `/usage-catalog/api/purchases-wm/dam/${damId}/damAssetId/${damAssetId}/events`)
      .then(res => res.data);
  }

  getAssetAggregate(damId, damAssetId) {
    return httpClient.get( `/usage-catalog/api/asset-wm/dam/${damId}/damAssetId/${damAssetId}`)
      .then(res => res.data);
  }

  getAssetAggregateEvents(damId, damAssetId) {
    return httpClient.get( `/usage-catalog/api/asset-wm/dam/${damId}/damAssetId/${damAssetId}/events`)
      .then(res => res.data);
  }

  getVendorAggregate(vendorKey) {
    return httpClient.get( `/usage-catalog/api/vendor-wm/vendorKey/${vendorKey}`)
      .then(res => res.data);
  }

  getVendorAggregateEvents(vendorKey) {
    return httpClient.get( `/usage-catalog/api/vendor-wm/vendorKey/${vendorKey}/events`)
      .then(res => res.data);
  }

  getCreditAdviceAggregate(vendorKey, serialNumber) {
    return httpClient.get( `/usage-catalog/api/creditadvice-wm/vendorKey/${vendorKey}/serialNumber/${serialNumber}`)
      .then(res => res.data);
  }

  getCreditAdviceAggregateEvents(damId, damAssetId) {
    return httpClient.get( `/usage-catalog/api/creditadvice-wm/vendorKey/${damId}/serialNumber/${damAssetId}/events`)
      .then(res => res.data);
  }
}

export default new BackofficeService();


