import {httpClient} from '../../../infrastructure/HttpClient';
import {handleHttpStatusCode} from '../../../infrastructure/HandleHttpStatusCode';
import {Department, DepartmentResponse} from '../model/Department';

const CACHE_TTL_MS = 1000;

class DepartmentService {
  static DEPARTMENT_MAPPING_URL = '/usage-catalog/api/departments';
  departmentCacheLastReset = null;
  departmentCache = [];

  fetchDepartments() {
    return httpClient.get(DepartmentService.DEPARTMENT_MAPPING_URL)
      .then(handleHttpStatusCode)
      .then(res => DepartmentResponse.fromJson(res));
  }

  fetchDepartmentByKey(key) {
    return httpClient.get(`${DepartmentService.DEPARTMENT_MAPPING_URL}/${key}`)
      .then(handleHttpStatusCode)
      .then(res => Department.fromJson(res));
  }

  fetchDepartmentByKeyCached(key, ttl = CACHE_TTL_MS) {

    // reset if ttl has been reached
    if (Date.now() > (this.departmentCacheLastReset + ttl)) {
      this.departmentCache = {};
      this.departmentCacheLastReset = Date.now();
    }

    // return cached result or fetch & update if not found
    if (this.departmentCache[key]) {
      return this.departmentCache[key];
    }

    this.departmentCache[key] = this.fetchDepartmentByKey(key);
    return this.departmentCache[key];
  }
}

export const departmentService = new DepartmentService();
