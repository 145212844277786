import React from 'react'

export const RestrictionNoteIcon = React.memo(() => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1961 1.77888C15.3772 1.77888 18.0652 3.18208 18.0652 4.84412C18.0652 5.11192 18.3728 5.32833 18.7534 5.32833C19.1326 5.32833 19.4416 5.11192 19.4416 4.84412C19.4416 2.62074 16.1903 0.811523 12.1962 0.811523C8.20206 0.811523 4.95076 2.62084 4.95076 4.84412C4.95076 6.37578 6.49285 7.71078 8.75826 8.39355C6.49429 9.07538 4.95076 10.4104 4.95076 11.942C4.95076 14.1653 8.20065 15.9746 12.1962 15.9746C15.3772 15.9746 18.0653 17.3778 18.0653 19.0398C18.0653 20.7009 15.3771 22.105 12.1962 22.105C9.01519 22.105 6.32707 20.7008 6.32707 19.0398C6.32707 18.772 6.01949 18.5556 5.63888 18.5556C5.25967 18.5556 4.95068 18.772 4.95068 19.0398C4.95068 21.2632 8.20199 23.0724 12.1961 23.0724C16.1902 23.0724 19.4415 21.2631 19.4415 19.0398C19.4415 17.5081 17.8994 16.1731 15.634 15.4904C17.898 14.8085 19.4415 13.4735 19.4415 11.942C19.4415 9.71858 16.1916 7.90936 12.1961 7.90936C9.01501 7.90936 6.32699 6.50616 6.32699 4.84412C6.32699 3.18302 9.01512 1.77888 12.1961 1.77888ZM18.0652 11.9422C18.0652 13.6033 15.3771 15.0075 12.1961 15.0075C9.01512 15.0075 6.32699 13.6033 6.32699 11.9422C6.32699 10.2812 9.01512 8.87697 12.1961 8.87697C15.3771 8.87697 18.0652 10.2812 18.0652 11.9422Z" fill="white"/>
    <line y1="-0.5" x2="30.9514" y2="-0.5" transform="matrix(0.738486 0.674269 -0.738486 0.674269 0.213623 1.88623)" stroke="white"/>
  </svg>
))

export const CollageIcon = React.memo(() => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.7037 20.7407H16.2963C17.2786 20.7407 18.2205 20.3501 18.9149 19.6557C19.6094 18.9612 20 18.0194 20 17.037V3.7037C20 2.72137 19.6094 1.77952 18.9149 1.08507C18.2205 0.39063 17.2786 0 16.2963 0H3.7037C1.658 0 0 1.658 0 3.7037V17.037C0 18.0194 0.39063 18.9612 1.08507 19.6557C1.77952 20.3501 2.72137 20.7407 3.7037 20.7407ZM2.22222 17.037V10H8.88889V18.5185H3.7037C2.88485 18.5185 2.22222 17.8559 2.22222 17.037ZM16.2963 2.22222C16.6898 2.22222 17.066 2.37847 17.3437 2.65626C17.6215 2.93404 17.7778 3.31019 17.7778 3.7037V17.037C17.7778 17.4306 17.6215 17.8067 17.3437 18.0845C17.066 18.3623 16.6898 18.5185 16.2963 18.5185H11.1111V2.22222H16.2963ZM3.7037 2.22222H8.88889V7.77778H2.22222V3.7037C2.22222 2.88485 2.88485 2.22222 3.7037 2.22222Z" fill="black"/>
  </svg>
))
