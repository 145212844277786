import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {cancelButtonStyles, optimisticSubmitButtonStyles, pessimisticSubmitButtonStyles} from './Buttons';
import {notificationService} from '../../../../components/Notifications/Notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {assetService} from '../../services/AssetService';

const AssetRestrictionNote = React.memo(({asset, toggleActionDrawer}) => {

  const [isLoading, setLoading] = useState(false)
  const [restrictionNoteConfirmed, confirmRestrictionNote] = useState(false)

  const SubmitButton = withStyles(optimisticSubmitButtonStyles)(Button);
  const ConfirmButton = withStyles(pessimisticSubmitButtonStyles)(Button);
  const CancelButton = withStyles(cancelButtonStyles)(Button);

  const askSecondTime = (e) => {
    confirmRestrictionNote(true)
  }

  const cancelRestrictionNote = () => {
    confirmRestrictionNote(false)
    toggleActionDrawer()
  }

  const submitRestrictionNote = () => {
    setLoading(true)
    assetService.setRestrictionNote(asset.assetId)
      .then(() => {
        toggleActionDrawer()
        notificationService.success('Sperrvermerk erfolgreich geschrieben, bitte gebe EFA etwas Zeit, die Änderungen zu übernehmen. Aktualisiere die Seite ggf. erneut.')
      })
      .catch((err) => {
        const backendErrorMessage = err ? err.message : '';
        notificationService.error(`Sperrvermerk konnte nicht geschrieben werden, versuche es bitte erneut. ${backendErrorMessage}`);
      })
      .finally(() => {
        setLoading(false)
        confirmRestrictionNote(false)
      })
  }

  return (
    <>
      {isLoading ?
        <div className='assetActions__spinner' data-testid='assetActions__spinner'>
          <div className='assetActions__spinner--circularProgress'><CircularProgress/></div>
          <div className='assetActions__spinner--message'>
            <h3>Sperrvermerk</h3>
          </div>
        </div>
        :
        <>
          <div className='assetActions__restrictionNote__header'>
            <h1>Sperrvermerk</h1>

            <p className='assetActions__paragraph'>
              Hier kannst du einen Sperrvermerk fürs Asset hinterlegen. Alle Referenzen in Artikeln werden gesperrt,
              auch
              in Collagen. Die Anpassung wird anschließend zu Fotoware synchronisiert.
            </p>

            <p className='assetActions__paragraph' data-testid='assetActions--paragraph__info'>
              Aktuelles Asset: <span>{asset.assetId.damAssetId}</span>
            </p>

            {restrictionNoteConfirmed &&
            <p className='assetActions__paragraph assetActions__paragraph--warning'>
              <strong>Das Setzen des Sperrvermerks sorgt für mögliche Weißflächen. Möchtest du den Sperrvermerk wirklich
                setzen?</strong>
            </p>
            }
          </div>

          <div className='assetActions__buttonGrid'>
            {restrictionNoteConfirmed ?
              <ConfirmButton
                data-testid='restrictionNote--confirm'
                variant='contained'
                color='primary'
                type='submit'
                onClick={submitRestrictionNote}
              >
                Sperrvermerk jetzt setzen!
              </ConfirmButton>
              :
              <SubmitButton
                data-testid='restrictionNote--submit'
                variant='contained'
                color='primary'
                type='submit'
                onClick={askSecondTime}
              >
                Sperrvermerk setzen
              </SubmitButton>
            }

            <CancelButton
              data-testid='restrictionNote--cancel'
              variant='contained'
              onClick={cancelRestrictionNote}
            >
              Abbrechen
            </CancelButton>
          </div>
        </>
      }
    </>
  )
}
);

export default AssetRestrictionNote;
