import React, {useState} from 'react';
import './Footer.scss';
import {FaArrowCircleUp} from 'react-icons/fa';
import {IdeasCredit, IdeasLogo, IdeasLogo2, IdeasLogo3, IdeasLogo4} from '../../assets/Logos/IdeasLogo';

const Footer = () => {
  return (
    <footer data-testid='footer--wrapper'>
      <div className='footer__wrapper' onDoubleClick={() => {
        document.getElementById('credit').classList.add('footer__gfx')
      }}>
        <div className='footer__credit'>
          <div id='credit'><span>built by Tailors at</span> <span>{atob(IdeasCredit.front)}
            <IdeasLogo2/> <IdeasLogo3/> {atob(IdeasCredit.middle)}
            <IdeasLogo4/> <IdeasLogo2/> {atob(IdeasCredit.end)}</span></div>
        </div>
        <div>
          <a href='https://www.ideas-engineering.io' target='_blank' rel="noopener noreferrer">
            <IdeasLogo/>
          </a>
        </div>
        <div className='scrollToTop__wrapper'>
          <ScrollArrow/>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop);

  return (
    <FaArrowCircleUp className="scrollTop" onClick={scrollTop}
                     style={{height: 40, display: showScroll ? 'flex' : 'none'}} data-testid='footer--scrollToTop'/>
  );
};
