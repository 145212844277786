import {Usage} from './Usage';

const IMMINENT = 'IMMINENT';
const COVERED = 'COVERED';
const ISSUED = 'ISSUED';

export const PaymentStatus = {
  IMMINENT,
  COVERED,
  ISSUED
};

const VENDOR_ENRICHMENT = "VENDOR_ENRICHMENT";
const FOTOWARE_ENRICHMENT = "FOTOWARE_ENRICHMENT";
const PRICING_ENRICHMENT = "PRICING_ENRICHMENT";
const DEPARTMENT_ENRICHMENT = "DEPARTMENT_ENRICHMENT";
const ONLINETIME_ENRICHMENT = "ONLINETIME_ENRICHMENT";

export const EnrichmentStates = {
  VENDOR_ENRICHMENT,
  FOTOWARE_ENRICHMENT,
  PRICING_ENRICHMENT,
  DEPARTMENT_ENRICHMENT,
  ONLINETIME_ENRICHMENT
}

export class Purchase {
  assetId = {
    dam: '',
    damAssetId: '',
    purchaseOrdinal: null,
    tenant: ''
  };
  assetForeignId = '';

  onlineTimeStart = '';
  onlineTimeEnd = '';
  onlineTimeCalculationStrategy = '';

  damAssetUrl = '';

  departmentToBeCharged = {
    key: '',
    name: ''
  };

  creditAdviceId = {
    tenant: null,
    vendorKey: null,
    serialNumber: null,
  };

  price = {
    value: '',
    currency: ''
  }

  enrichments = []

  priceResolutionStrategy = ''

  metadata = {
    credit: '',
    caption: '',
    captionWriter: '',
    damAssetUrl: '',
    dateCreated: '',
    fileName: '',
    fileSize: null,
    foreignId: null,
    imageHeight: null,
    imageWidth: null,
    keywords: '',
    onlineLife: null,
    onlineLocked: null,
    price: null,
    shortId: '',
    thumbnailUrl: null,
    title: '',
    externalCreditorId: '',
    source: '',
    objectDescription: ''
  };

  vendor = {
    vendorKey: '',
    vendorName: ''
  };

  usages = [];

  paymentStatus = '';

  restrictionNoteSet = false;
  restrictionNoteLastModified = null;

  lastUpdated = null;

  isCollage = false;

  static fromJson(payload) {
    let purchase = new Purchase();

    purchase.assetId = payload.id || {};
    purchase.metadata = payload.metadata || {};
    purchase.departmentToBeCharged = payload.departmentToBeCharged || {};

    purchase.assetForeignId = payload.assetForeignId;

    purchase.onlineTimeStart = payload.publicationDate;
    purchase.onlineTimeEnd = payload.expiryDate;
    purchase.onlineTimeCalculationStrategy = payload.onlineTimeCalculationStrategy;
    purchase.paymentStatus = payload.paymentStatus;
    purchase.price = payload.price || {}
    purchase.priceResolutionStrategy = payload.priceResolutionStrategy || ''

    purchase.vendor = payload.vendor || {};
    purchase.creditAdviceId = payload.creditAdviceId || {};

    purchase.enrichments = payload.enrichments || [];

    purchase.lastUpdated = payload.lastUpdated;

    purchase.restrictionNoteSet = payload.restrictionNoteSet;
    purchase.restrictionNoteLastModified = payload.restrictionNoteLastModified;

    purchase.isCollage = payload.isCollage;

    let possibleUsages = payload.usages || [];
    purchase.usages = possibleUsages.map(u => Usage.fromJson(u));

    return purchase;
  }

  get id() {
    return [this.assetId.damAssetId, this.assetId.dam, this.assetId.purchaseOrdinal].join('');
  }

}

