import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Spinner.scss';

const SpinnerInline = React.memo(({ color, dataTestId }) => {

  return (
    <div className='spinner__inline'>
      <CircularProgress style={{ height: '15px', width: '15px', marginRight: '15px', color }} data-testid={dataTestId} />
    </div>

  );
})
export default SpinnerInline;
