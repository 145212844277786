import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import {toDateTimeString} from '../../../services/DateUtils';
import './summary.scss';

const normalise = (value, max) => (value - 0) * 100 / (max - 0);

export const StatisticsSummary = ({statistics}) => {
  return (<>
    <Card className='summary'>
      <CardContent>
        <h2 className='summary__title'>Enrichment processing:</h2>
        <ul className='summary__details'>
          <li>last Updated: {toDateTimeString(statistics.lastUpdated)}</li>
          <li><em>Total: </em>{statistics.total}</li>
          <li><em>Succeeded: </em>{statistics.success}</li>
          <li><em>Failed: </em>{statistics.failed}</li>
        </ul>
      </CardContent>
    </Card>

    <Card className='summary'>
      <CardContent>
        <h2 className='summary__title'>Enrichment failures:</h2>
        <ul className='summary__details'>
          <li>
            <em>Department: </em>{statistics.departmentEnrichmentFailed}
            <LinearProgress
              variant='determinate'
              value={normalise(statistics.departmentEnrichmentFailed, statistics.failed)}
            />
          </li>
          <li>
            <em>Fotoware: </em>{statistics.fotowareEnrichmentFailed}
            <LinearProgress
              variant='determinate'
              value={normalise(statistics.fotowareEnrichmentFailed, statistics.failed)}
            />
          </li>
          <li>
            <em>Vendor: </em>{statistics.vendorEnrichmentFailed}
            <LinearProgress
              variant='determinate'
              value={normalise(statistics.vendorEnrichmentFailed, statistics.failed)}
            />
          </li>
          <li>
            <em>Pricing: </em>{statistics.pricingEnrichmentFailed}
            <LinearProgress
              variant='determinate'
              value={normalise(statistics.pricingEnrichmentFailed, statistics.failed)}
            />
          </li>
          <li>
            <em>Onlinetime: </em>{statistics.onlineTimeEnrichmentFailed}
            <LinearProgress
              variant='determinate'
              value={normalise(statistics.onlineTimeEnrichmentFailed, statistics.failed)}
            />
          </li>
        </ul>
      </CardContent>
    </Card>
  </>);
}
