import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import './Modal.scss';

const Modal = React.memo(({modalToggled, toggleModal, children, hasClosingButton, modalBackgroundColor, modalMinHeight, modalWidth}) => {

  const animationVariants = {
    initial: {
      opacity: 0
    },
    enter: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      transition: {duration: 0.25, delay: 0.3},
    }
  }

  return (
    <div data-testid='modal--AnimateWrapper'>
      <AnimatePresence>
        {modalToggled && (
          <>
            <motion.div
              variants={animationVariants}
              initial="initial"
              animate="enter"
              exit="exit"
              style={{
                position: 'fixed',
                top: '10vh',
                left: '50%',
                transform: 'translate3d(-50%, 0,0)',
                zIndex: '30'
              }}
            >
              <motion.div initial={{y: 50}} animate={{y: 0}} exit={{y: 50, transition: {delay: 0.2}}}>
                {hasClosingButton && (
                  <div className='modal__closeButton' onClick={() => toggleModal()}>
                    <CloseIcon/>
                  </div>
                )}
                <Card className='modal__content' style={{background: modalBackgroundColor || '#333333', minHeight: modalMinHeight, width: modalWidth}} data-testid='modal--ContentWrapper'>
                  {children}
                </Card>
              </motion.div>
            </motion.div>
            <motion.div
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 0.1}}
              className="modal__background"
            />
          </>
        )}
      </AnimatePresence>
    </div>
  );
});

export default Modal;
