import {useState} from 'react'
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import './CreditAdviceBatchJobCreator.scss'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import {CreditAdviceBatchJobSearchResults} from './CreditAdviceBatchJobSearchResults'
import {purchaseService} from '../services/PurchaseService';
import {batchJobService} from '../services/BatchJobService';
import Checkbox from '@material-ui/core/Checkbox';

const optimisticSubmitButtonStyles = () => ({
  root: {
    backgroundColor: '#6FCF97',
    fontSize: '0.72rem',
    width: '70%',
    '&:hover': {
      backgroundColor: '#52C582',
    },
  }
});

const cancelButtonStyles = () => ({
  root: {
    backgroundColor: '#4f4f4f',
    fontSize: '0.72rem',
    color: '#ffffff',
    width: '70%',
    marginTop: '30px',
    '&:hover': {
      backgroundColor: '#000000',
      color: '#ffffff'
    },
  }
});

const selectButtonStyles = () => ({
  root: {
    marginRight: '0.5rem',
    fontSize: '0.55rem',
    backgroundColor: 'white',
    boxShadow: 'none',
    width: '32%',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none'
    },
  }
});

const SelectButton = withStyles(selectButtonStyles)(Button);
const SubmitButton = withStyles(optimisticSubmitButtonStyles)(Button);
const CancelButton = withStyles(cancelButtonStyles)(Button);

const getUniqueVendorsKeys = (data) => {
  const output = new Map()
  data.forEach(el => {
    output.set(el.vendor.vendorKey, true)
  })
  return output
}
export const CreditAdviceBatchJobCreator = ({
                                              vendors,
                                              searchQuery,
                                              forceUpdate,
                                              getPendingCreditAdviceJobs,
                                              triggerSearch
                                            }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentVendor, setCurrentVendor] = useState();
  const [selectedVendors, setSelectedVendors] = useState(new Map())
  const [searchResults, setSearchResults] = useState([])
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true)
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
  const [filteredVendor, setFilteredVendor] = useState(null)

  const urlQuery = new URLSearchParams(searchQuery);
  const IsCreateBatchJobEnabled = urlQuery.get('tab') === 'ACCOUNTABLE'

  const checkIsSelected = (id) => {
    return !!selectedVendors.get(id)
  };

  const handleSelectVendor = (id) => {
    const newSelectedVendors = new Map(selectedVendors)
    newSelectedVendors.set(id, !selectedVendors.get(id))
    setSelectedVendors(newSelectedVendors)

    const allSelected = filteredVendor
      ? newSelectedVendors.get(filteredVendor) === true
      : Array.from(newSelectedVendors.values()).every(value => value === true)
    setIsSelectAllChecked(allSelected)
  }

  const handleActivateAll = () => {
    const newSelectedVendors = new Map(selectedVendors)

    if (filteredVendor) {
      newSelectedVendors.forEach((_, key) => {
        newSelectedVendors.set(key, key === filteredVendor)
      });
    } else {
      newSelectedVendors.forEach((_, key) => {
        newSelectedVendors.set(key, true)
      });
    }

    setSelectedVendors(newSelectedVendors)
    setIsSelectAllChecked(true)
  };

  const handleDeActivateAll = () => {
    const newSelectedVendors = new Map()
    selectedVendors.forEach((value, key) => {
      newSelectedVendors.set(key, false)
    })
    setSelectedVendors(newSelectedVendors)
    setIsSelectAllChecked(false)
  };

  const handleSelectAllChange = () => {
    if (isSelectAllChecked) {
      handleDeActivateAll()
    } else {
      handleActivateAll()
    }
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  const results = currentVendor ? [currentVendor] : searchResults

  const getAllPurchases = async () => {
    const data = await purchaseService.fetchAllSelectedVendorsData(new URLSearchParams(searchQuery))
    setSearchResults(data)
    setSelectedVendors(getUniqueVendorsKeys(data))
    setIsSelectAllChecked(true)

  };

  const handleClick = (event) => {
    getAllPurchases().then(r => {
    })
    setAnchorEl(event.currentTarget)
    setIsSubmitEnabled(true)
  };

  const handleClose = () => {
    setAnchorEl(null)
    setCurrentVendor(null)
  };

  const handleChange = (_, e) => {
    setCurrentVendor(e)

    const newSelectedVendors = new Map()
    selectedVendors.forEach((value, key) => {
      newSelectedVendors.set(key, e ? key === e.vendor.vendorKey : true)
    })

    setSelectedVendors(newSelectedVendors)
    setIsSelectAllChecked(true)
    setFilteredVendor(e ? e.vendor.vendorKey : null)
  };

  const handleCancelClick = () => {
    handleClose()
  }

  const getEntities = () => {
    const output = []
    selectedVendors.forEach((value, vendorKey) => {
      if (value === true) {
        output.push({id: vendorKey, purchaseIds: []})
      }
    })
    output.forEach((entity) => {
      searchResults.forEach((vendorPurchases) => {
        if (entity.id === vendorPurchases.vendor.vendorKey) {
          entity.purchaseIds = vendorPurchases.ids
        }
      })
    })

    return output
  }

  const handleSubmit = () => {
    setIsSubmitEnabled(false)
    const startDate = urlQuery.get('publishDateStart')
    const endDate = urlQuery.get('publishDateEnd')
    const entities = getEntities()

    const payload = {
      entities,
      type: 'CREDIT_ADVICE',
      startDate,
      endDate
    }

    batchJobService.post(payload).then(() => {
      forceUpdate()
      getPendingCreditAdviceJobs()
      triggerSearch(urlQuery, 'ACCOUNTABLE')
      handleClose()
    })

  }

  const open = Boolean(anchorEl) && !!results.length;
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div className="purchaseFilterCreateBatchJobButton">
        <Button
          disabled={!IsCreateBatchJobEnabled}
          aria-describedby={id} variant="contained"
          data-testid="purchaselist--filter__create"
          className="purchaseFilterCreateBatchJobButton--button"
          onClick={handleClick}>
          Gutschrift erzeugen
        </Button>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="popoverContentWrapper"
             data-testid="creditAdviceBatchJobCreator--popover">
          <div className="heading">
            <h2>Gutschriften erzeugen</h2>
          </div>
          <div className="jobs">
            <div className="searchWrapper">
              <div className="search">
                <Autocomplete
                  className="textfield"
                  onChange={handleChange}
                  data-testid="creditAdviceBatchJobCreator--popover__filter"
                  ListboxProps={{'data-testid': 'vendorOptionsList'}}
                  id="tags-standard"
                  options={results}
                  noOptionsText={'Keine Anbieter'}
                  getOptionSelected={(option, value) => option.vendorKey === value.vendorKey}
                  getOptionLabel={el => el.vendorName}
                  renderInput={params => (
                    <TextField
                      {...params}
                      data-testid="creditAdviceBatchJobCreator--filterInput"
                      variant="standard"
                      label={`Anbieter`}
                    />
                  )}
                />
              </div>
              <div className="actionButtons">
                <SubmitButton
                  data-testid={`creditAdviceBatchJobCreator--popover__submitButton`}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isSubmitEnabled}
                >
                  Erzeugen
                </SubmitButton>

                <CancelButton
                  data-testid={`creditAdviceBatchJobCreator--popover__cancelButton`}
                  variant="contained"
                  onClick={handleCancelClick}
                >
                  Abbrechen
                </CancelButton>
              </div>
            </div>
            <div className="searchResults">
              <div className="selectButtons">
                <Checkbox
                  data-testid={`creditAdviceBatchJobSearchResults--select-all`}
                  checked={isSelectAllChecked}
                  onClick={handleSelectAllChange}
                >
                </Checkbox>
                <div>Alle Auswählen</div>
                <button className="deselectAll"
                        data-testid={`creditAdviceBatchJobSearchResults--deselect-all`}
                        onClick={handleDeActivateAll}
                >
                  Auswahl aufheben
                </button>
              </div>
              <div className="CreditAdviceBatchJobSearchResults">
                <CreditAdviceBatchJobSearchResults results={results} checkIsSelected={checkIsSelected}
                                                   handleSelectVendor={handleSelectVendor}
                                                   handleActivateAll={handleActivateAll}
                                                   handleDeActivateAll={handleDeActivateAll}/>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
