import React, {useEffect, useState} from 'react';
import SpinnerInline from '../../../components/Spinner/SpinnerInline';
import {notificationService} from '../../../components/Notifications/Notifications';
import {purchaseService} from '../services/PurchaseService';
import {CollageChildPreview} from './CollageChildPreview';

export const CollageChildrenList = ({purchase}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    purchaseService.fetchCollagePurchaseChildAssets(purchase.assetId)
      .then(res => setChildren(res))
      .catch(() => notificationService.error(`Konnte referenzierte Assets für Collage "${purchase.assetId.damAssetId}" nicht laden`))
      .finally(() => setIsLoading(false))
  }, [purchase.assetId])

  const noChildrenFound = (<span data-testid='assetDetails--collage-notfound'>- Keine Referenzen -</span>);
  const loadingIndication = (<SpinnerInline/>);

  return (
    <div className='assetDetails__collageChildren__wrapper'>
      <div className='assetDetails__collageChildren__titleWrapper'>
        <h1>Referenzierte Assets</h1>
        {isLoading && loadingIndication}
      </div>
      {!isLoading &&
      <>
        {children.length === 0 && noChildrenFound}
        {children.length > 0 &&
        <div>
          {
            children.map((child, index) => <CollageChildPreview assetIdChild={child} key={index}/>)
          }
        </div>
        }
      </>
      }
    </div>);
}
