import React, {useEffect, useState} from 'react';
import './VendorDetails.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {useHistory} from 'react-router-dom';
import {notificationService} from '../../components/Notifications/Notifications';
import {vendorService} from './services/VendorService';
import {ProgressBar} from '../../components/ProgressBar/ProgressBar';
import {PersonalDataSection} from './components/PersonalDataSection';
import {AddressAndBankSection} from './components/AddressAndBankSection';
import {PricingModelSection} from './components/PricingModelSection';
import {BusinessDataSection} from './components/BusinessDataSection';
import {toDateString} from '../../services/DateUtils';

const VendorDetails = (props) => {

  // using props.match.params instead of useParams hook as react-router-dom doesn't supply a proper testing for useParams hook
  const {id} = props.match.params;
  const history = useHistory();

  const initialState = {
    lastModified: new Date(),
    personalData: {},
    pricingModel: {},
    addressAndBank: {},
    businessDetails: {}
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(true);

  const updateVendorState = (vendor) => {
    setState(s => {
      return {
        ...s,
        lastModified: vendor.lastModified,
        pricingModel: vendor.pricingModel,
        personalData: vendor.personalData,
        addressAndBank: vendor.addressAndBank,
        businessDetails: vendor.businessDetails
      }
    });
  };

  const loadVendorDetails = () => {
    vendorService.getVendorById(id)
      .then(updateVendorState)
      .catch(() => {
        history.push('/not-found');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(loadVendorDetails, [id, history]);

  const handlePersonalDataSubmit = (data) => {
    vendorService.updatePersonalData(id, data)
      .then(updateVendorState)
      .then(() => notificationService.success('Persönliche Daten erfolgreich aktualisiert'))
      .catch(() => notificationService.error('Persönliche Daten konnten nicht aktualisiert werden'))
  }
  const handlePricingModelSubmit = (data) => {
    vendorService.updatePricingModel(id, data)
      .then(updateVendorState)
      .then(() => notificationService.success('Preismodell erfolgreich aktualisiert'))
      .catch(() => notificationService.error('Preismodell konnte nicht aktualisiert werden'))
  }
  const handleAddressAndBankSubmit = (data) => {
    vendorService.updateAddressAndBank(id, data)
      .then(updateVendorState)
      .then(() => notificationService.success('Adresse und Bankdaten erfolgreich aktualisiert'))
      .catch(() => notificationService.error('Adresse und Bankdaten konnten nicht aktualisiert werden'))
  }
  const handleBusinessDetailsSubmit = (data) => {
    vendorService.updateBusinessDetails(id, data)
      .then(updateVendorState)
      .then(() => notificationService.success('Unternehmensdaten erfolgreich aktualisiert'))
      .catch(() => notificationService.error('Unternehmensdaten konnten nicht aktualisiert werden'))
  }

  return (
    <>
      {!loading && (
        <div
          className='vendorDetails__wrapper'
          data-testid='vendorDetails--wrapper'
        >
          <div className='vendorDetails__progressbar' data-testid='vendorDetails--progressbars'>
            <div className='vendorDetails__progress'>
              <AnchorLink className='vendorDetails__progress__section' href='#personalData'>
                <div className='vendorDetails__progress__label'>Persönlichen Daten</div>
                <ProgressBar value={state.personalData.progress}/>
              </AnchorLink>
              <AnchorLink className='vendorDetails__progress__section' href='#pricingModel'>
                <div className='vendorDetails__progress__label'>Preismodell & Fakturierung</div>
                <ProgressBar value={state.pricingModel.progress}/>
              </AnchorLink>
              <AnchorLink className='vendorDetails__progress__section' href='#addressAndBank'>
                <div className='vendorDetails__progress__label'>Anschrift & Kontodaten</div>
                <ProgressBar value={state.addressAndBank.progress}/>
              </AnchorLink>
              <AnchorLink className='vendorDetails__progress__section' href='#businessDetails'>
                <div className='vendorDetails__progress__label'>Unternehmensdaten</div>
                <ProgressBar value={state.businessDetails.progress}/>
              </AnchorLink>
            </div>
          </div>

          <div className='vendorDetails__headline'>
            <h1 data-testid='vendorDetails--companyName'>Anbieter Stammdaten: {state.personalData.companyName}</h1>
            <small data-testid='vendorDetails--lastModified'>letzte Änderung: {toDateString(state.lastModified)}</small>
          </div>

          <div className='detailsSection__wrapper' id='personalData'>
            <PersonalDataSection data={state.personalData} handleSubmit={handlePersonalDataSubmit}/>
          </div>
          <div className='detailsSection__wrapper' id='pricingModel'>
            <PricingModelSection data={state.pricingModel} handleSubmit={handlePricingModelSubmit}/>
          </div>
          <div className='detailsSection__wrapper' id='addressAndBank'>
            <AddressAndBankSection data={state.addressAndBank} handleSubmit={handleAddressAndBankSubmit}/>
          </div>
          <div className='detailsSection__wrapper' id='businessDetails'>
            <BusinessDataSection data={state.businessDetails} handleSubmit={handleBusinessDetailsSubmit}/>
          </div>
        </div>
      )}
    </>
  );
};

export default VendorDetails;
