export class CreditlineItem {
  assetId = {
    originDamId: '',
    originDam: '',
    tenantId: ''
  };
  purchaseOrdinal = null;

  vendorId = {
    vendorKey: '',
    tenantId: ''
  };

  caption = '';

  foreignId = '';

  price = {
    value: null,
    currency: ''
  };

  department = {
    departmentKey: '',
    name: ''
  };

  usageTime = {
    start: null,
    end: null
  };

  static fromJson(payload) {
    let creditlineItem = new CreditlineItem();

    creditlineItem.assetId = payload.assetId || {};
    creditlineItem.purchaseOrdinal = payload.purchaseOrdinal || 0;
    creditlineItem.vendorKey = payload.vendorKey;

    creditlineItem.caption = payload.caption;
    creditlineItem.foreignId = payload.foreignId;

    creditlineItem.usageTime = {
      start: payload.usageTime.start ? new Date(payload.usageTime.start) : '',
      end: payload.usageTime.end ? new Date(payload.usageTime.end) : ''
    };

    creditlineItem.department = {
      departmentKey: payload.departmentKey || '',
      name: ''
    };

    creditlineItem.price = {
      value: payload.price.value || 0,
      currency: payload.price.currency || ''
    };

    return creditlineItem;
  }

  get id() {
    return [this.assetId.originDamId, this.assetId.originDam, this.purchaseOrdinal].join('');
  }
}

