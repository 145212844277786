import React from 'react';
import AssetPurchaseEditorOnlineTime from './AssetPurchaseEditorOnlineTime';
import AssetPurchaseEditorVendor from './AssetPurchaseEditorVendor';
import AssetPurchaseEditorPrice from './AssetPurchaseEditorPrice'

const AssetPurchaseEditor = React.memo(({asset, toggleActionDrawer}) => {
  return (
    <>
      <AssetPurchaseEditorVendor asset={asset} toggleActionDrawer={toggleActionDrawer} />
      {
        asset.onlineTimeStart && (
          <AssetPurchaseEditorOnlineTime asset={asset} toggleActionDrawer={toggleActionDrawer}/>
        )
      }
      <AssetPurchaseEditorPrice asset={asset} toggleActionDrawer={toggleActionDrawer}/>
    </>
  )
});

export default AssetPurchaseEditor;
