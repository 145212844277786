import {BasicModel} from './BasicModel';

const USAGE_REPORT = "USAGE_REPORT";
const INVOICE_REQUEST = "INVOICE_REQUEST";
const CREDIT_ADVICE = "CREDIT_ADVICE";

export const AccountingTypes = {
  USAGE_REPORT, INVOICE_REQUEST, CREDIT_ADVICE
}

export class PricingModel extends BasicModel {
  lifetime = '';
  defaultPrice = 0;
  billingType = '';
  currency = '';
  accountingType = '';

  get mandatoryFields() {
    return ['lifetime', 'defaultPrice', 'billingType', 'currency', 'accountingType'];
  }

  static Currency = {
    EUR: "EUR",
    GBP: "GBP",
    USD: "USD",
    CHF: "CHF"
  }

  static LifetimeLabels = {
    INDEFINITE: 'einmalig',
    DAY: 'pro Tag',
    WEEK: 'pro Woche',
    MONTH: 'pro Monat',
    TWO_MONTH: 'alle zwei Monate',
    QUARTER: 'pro Quartal',
    HALF_YEAR: 'pro Halbjahr',
    YEAR: 'pro Jahr',
    DECADE: 'pro Dekade'
  };

  static FacturationLabels = {
    FLATRATE: 'Flatrate',
    BY_LIFETIME: 'Nach Standzeit',
  };

  static AccountingLabels = {
    INVOICE_REQUEST: 'Rechnungsstellung',
    CREDIT_ADVICE: 'Gutschrift',
    USAGE_REPORT: 'Verwendungsnachweis'
  }

  static fromJson(payload = {}) {
    let p = new PricingModel();
    let price = payload.defaultPrice || {};
    p.currency = price.currency || '';
    p.defaultPrice = price.value || '';
    p.billingType = payload.billingType || '';
    p.lifetime = payload.lifetime || '';
    p.accountingType = payload.accountingType || '';
    return p;
  }

  get lifeTimeLiteral() {
    return PricingModel.LifetimeLabels[this.lifetime] || ''
  }

  get billingTypeLiteral() {
    return PricingModel.FacturationLabels[this.billingType] || '';
  };

  get accountingTypeLiteral() {
    return PricingModel.AccountingLabels[this.accountingType] || '';
  };
}
