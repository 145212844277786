import {toDateString} from '../../../services/DateUtils';
import {PriceLiteral} from '../../../services/MoneyUtils';
import React from 'react';
import {resolutionStrategyLiteral} from '../services/AssetService';

export const AssetInformation = ({purchase}) => {
  return (
    <div className='assetDetails__purchase' data-testid='assetDetails--information--asset'>
      <div className='assetDetails__headerGrid'>
        <div>
          <h1>Asset</h1>
          <span data-testid='assetDetails--assetDamId'>
          {purchase.assetId.damAssetId || '-'}
        </span>
        </div>
        <div>
          <h1>Eingangsdatum</h1>
          <span data-testid='assetDetails--dateCreated'>
          {toDateString(purchase.metadata.dateCreated) || '-'}
        </span>
        </div>
        <div>
          <h1>Titel</h1>
          <span data-testid='assetDetails--title'>
          {purchase.metadata.title || '-'}
        </span>
        </div>
        <div>
          <h1>Anbieter</h1>
          {
            purchase.vendor.vendorKey ?
              <a href={'/vendors/edit/' + purchase.vendor.vendorKey} title={'Anbieter Details'}>
                <span data-testid='assetDetails--vendorName'>{purchase.vendor.vendorName || '-'}</span>
              </a>
              :
              <span data-testid='assetDetails--vendorName'>{purchase.vendor.vendorName || '-'}</span>
          }
        </div>
      </div>
      <div className='assetDetails__caption'>
        <h1>Bildunterschrift</h1>
        <span data-testid='assetDetails--caption'>{purchase.metadata.caption || '-'}</span>
      </div>
      <div className='assetDetails__price'>
        <h1>Honorar</h1>
        <div className='assetDetails__price--flex'>
          <div className='assetDetails__price--flex-value nowrap'>
          <span data-testid='assetDetails--price'>
            <PriceLiteral price={purchase.price.value}/>
          </span>
            {' '}
            <span className='assetDetails__price--flex-currency' data-testid='assetDetails--currency'>
            {purchase.price.currency}
          </span>
            {' '}
            <span className='assetDetails__resolutionStrategy'
                  data-testid='assetDetails--priceresolution'>
            {resolutionStrategyLiteral(purchase.priceResolutionStrategy)}
          </span>
          </div>
        </div>
      </div>
      <div className='assetDetails__onlineTime'>
        <h1>Standzeit</h1>
        <div className='assetDetails__DatePickerGrid'>
        <span data-testid='assetDetails--onlineTimeStart'>
          {toDateString(purchase.onlineTimeStart) || '-'}
        </span>
          <span>bis</span>
          <span data-testid='assetDetails--onlineTimeEnd'>
          {toDateString(purchase.onlineTimeEnd) || '-'}
        </span>
          <span data-testid='assetDetails--onlineTimeAmount'>
          {purchase.metadata.onlineLife || '-'} Tage <i
            className='assetDetails__resolutionStrategy'>{resolutionStrategyLiteral(purchase.onlineTimeCalculationStrategy)}</i>
        </span>
        </div>
      </div>
    </div>
  )
}
