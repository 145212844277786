import React, {useEffect, useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import {EventEmitter} from 'fbemitter';
import {AnimatePresence, motion} from 'framer-motion'
import './Notifications.scss';

const NOTIFY_EVENT = "notify";
const NOTIFY_DEFAULT_TIMEOUT = 5000;
const NOTIFY_ERROR_TIMEOUT = 10000;
const emitter = new EventEmitter();

class NotificationService {
  constructor(emitter) {
    this.emitter = emitter;
  }

  success(message) {
    this.emitter.emit(NOTIFY_EVENT, {severity: 'success', message: message})
  }

  info(message) {
    this.emitter.emit(NOTIFY_EVENT, {severity: 'info', message: message})
  }

  warning(message) {
    this.emitter.emit(NOTIFY_EVENT, {severity: 'warning', message: message})
  }

  error(message) {
    this.emitter.emit(NOTIFY_EVENT, {severity: 'error', message: message})
  }
}

export const notificationService = new NotificationService(emitter);

export const NotificationsContainer = React.memo(({errorTimeout = NOTIFY_ERROR_TIMEOUT, defaultTimeout = NOTIFY_DEFAULT_TIMEOUT}) => {
  const [notifications, setNotifications] = useState([]);

  const closeNotification = (notificationToClose) => {
    setNotifications(notifications => {
      let notification = notifications.find(notification => notification.id === notificationToClose.id)
      notification.visible = false
      return [...notifications]
    })
  }

  const addNotification = (severity, message) => {
    const notification = {id: (new Date()).getTime(), severity: severity, message: message, visible: true};
    setNotifications([...notifications, notification])

    const timeout = severity === 'error' ? errorTimeout : defaultTimeout;

    setTimeout(closeNotification, timeout, notification);
  }

  useEffect(() => {
    emitter.addListener(NOTIFY_EVENT, (args) => addNotification(args.severity, args.message))

    // cleanup after unmount of component
    return () => {
      emitter.removeAllListeners();
    }
  })

  const notificationItems = notifications.map(n => {
    return (
      <AnimatePresence key={n.id}>
        {n.visible &&
        <div className='NotificationsContainer__AlertWrapper'>
          <motion.div
            initial={{x: 100, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            exit={{x: -100, opacity: 0}}
          >
            <Alert style={{padding: "1em", width: "50vw"}}
                   onClick={() => closeNotification(n)}
                   onClose={() => closeNotification(n)}
                   severity={n.severity}
                   data-testid={`notification--${n.severity}`}
            >
              {n.message}
            </Alert>
          </motion.div>
        </div>
        }
      </AnimatePresence>
    )
  })

  return (
    <div className='NotificationsContainer__wrapper' data-testid="NotificationsContainer--wrapper">
      {notificationItems}
    </div>
  );
});
