import React from 'react';
import {EfaLogo} from '../../assets/Logos/EfaLogo';
import './Header.scss';
import {Link} from 'react-router-dom';
import MainMenu from '../MainMenu/MainMenu';
import Grid from '@material-ui/core/Grid';
import {useAuthContext} from '../../services/AuthContext';
import {environmentService} from '../../infrastructure/EnvironmentService';

const Header = () => {

  const authContext = useAuthContext();
  const environment = environmentService.getEnvironment();

  return (
    <div className='header'>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <Grid item xs={2}>
          <Link to='/'>
            <EfaLogo environment={environment}/>
          </Link>
        </Grid>
        {authContext.userAuthenticated && (
          <>
            <Grid item xs={8}>
              <MainMenu data-testid='header--menu'/>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Header;
