import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import Card from '@material-ui/core/Card';
import Spinner from '../../../components/Spinner/Spinner';
import './LineItemCandidateList.scss';

const LoadingCard = React.memo(() => (
    <AnimatePresence>
      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
      >
        <motion.div initial={{y: -100}} animate={{y: -10}} exit={{y: -100}} transition={{duration: 0.5}}>
          <Card className='addLineItem__card' data-testid='creditadviceAddLineItem--statusCard'>
            <div className='addLineItem__actions' style={{padding: '3em 1em'}}>
             <Spinner />
            </div>
          </Card>
        </motion.div>
      </motion.div>
    </AnimatePresence>
));

export default LoadingCard;
