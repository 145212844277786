import React from 'react';
import './ProgressBar.scss';

export const ProgressBar = (props) => {
  return (
    <div className={'ProgressBar__container'}>
      <div className='ProgressBar__slider' style={{width: props.value + '%'}}/>
    </div>
  );
}
