import axios from 'axios';
import { deriveApiUrl } from './DeriveApiUrl';

export const HTTP_STATUS_CONFLICT = 409;
export const HTTP_STATUS_METHOD_NOT_ALLOWED = 405;

export class HttpError extends Error {
  constructor(statuscode, message, data, headers) {
    super();
    this.statuscode = statuscode || 0;
    this.message = message || '';
    this.data = data || {}
    this.headers = headers || {}
  }
}

const throwHttpError = error => {
  let response = error.response || {
    status: 0,
    data: {
      message: ''
    },
    headers: {}
  };
  return Promise.reject(new HttpError(response.status, response.data?.message, response.data, response.headers));
};

export class HttpClient {
  client = null;

  constructor(axiosClient) {
    if (axiosClient) {
      this.client = axiosClient;
    } else {
      this.client = axios.create({
        baseURL: deriveApiUrl(window.location.href),
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      /**
       * This serves our temporary double-login state (Spring+BasicAuth and AWS-Cognito+AD)
       * TODO: NCC-2342 refactor this after our double authentication is removed
       */
      this.client.interceptors.response.use(
        (response) => response,
        (error) => {

          let lbUrl = deriveApiUrl(window.location.href);

          if (error.message === 'Network Error') {
            // possibly this is a CORS error caused by the user not being Cognito authenticated
            this.redirectToCognitoAuthenticationIfRequired(lbUrl)
          }

          return Promise.reject(error);
        });
    }
  }

  redirectToCognitoAuthenticationIfRequired(lbUrl) {
    const url = lbUrl + "/am-i-cognito-authenticated"
    const newClient = axios.create()

    newClient.get(url, {
      withCredentials: true
    }).then((resp) => {
      switch (resp.data) {
        case "not authenticated":
          console.log("user not authenticated, redirecting to Cognito login");
          window.location.replace(lbUrl + "/login");
          break;
        case "authenticated":
          console.log("user already authenticated, doing nothing");
          break;
        default:
          console.log("unexpected response received from end point " + resp.data);
      }
    }).catch((e) => console.error(e))
  }

  setAuthorizationHeader(token) {
    this.client.defaults.headers.common['Authorization'] = token;
  }

  unsetAuthorizationHeader() {
    delete this.client.defaults.headers.common['Authorization'];
  }

  head(url) {
    return this.client.head(url)
      .catch(throwHttpError)
  }

  get(url, headers) {
    return this.client.get(url, headers)
      .catch(throwHttpError);
  }

  put(url, payload) {
    return this.client.put(url, payload)
      .catch(throwHttpError);
  }

  post(url, payload, headers) {
    return this.client.post(url, payload, headers)
      .catch(throwHttpError);
  }

  delete(url, payload) {
    return this.client.delete(url, {
      data: payload
    })
      .catch(throwHttpError);
  }
}

export const httpClient = new HttpClient();
