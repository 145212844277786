
import React, { useState } from 'react';
import { notificationService } from '../../../../components/Notifications/Notifications';
import { purchaseService } from '../../services/PurchaseService';
import TextField from '@material-ui/core/TextField';
import { PriceCurrencyLiteral } from '../../../../services/MoneyUtils';
import { PricingModel } from '../../../vendor/models/PricingModel';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { useDebounceValidation } from '../../../../hooks/UseDebounceValidation';
import { AssetPurchaseEditorActionButtons } from './AssetPurchaseEditorActionButtons';




const AssetPurchaseEditorPrice = React.memo(({
  asset,
  toggleActionDrawer
}) => {

  const [value, setValue] = useState("");
  const [currency, setCurrency] = useState(asset.price.currency);

  const submit = () => {

    purchaseService.updatePurchasePrice(asset.assetId, { value, currency })
      .then((data) => {
        toggleActionDrawer();
        notificationService.success('Anpassung der Preisangaben war erfolgreich, bitte gebe EFA etwas Zeit, die Änderungen zu übernehmen. Aktualisiere die Seite ggf. erneut.');
      })
      .catch((err) => {
        let backendErrorMessage = err ? err.message : '';
        notificationService.error(`Anpassung der Preisangaben ist fehlgeschlagen. ${backendErrorMessage}`)
      })
  };
  
  const checkIfAllowedInput = (value) => {

    let test = true
    if (value.length === 1) test = /^[0-9]$/.test(value)
    if (value.length === 2) test = /^[1-9][0-9]$/.test(value) || /^[0-9],$/.test(value)
    if (value.length > 2) test = /^[0-9,]*$/.test(value)
    const match = value.match(/,([\s\S]*)$/)
    if (match && match[1].length > 2) test = false
    return test
  }

  const [isValueValid, isInProgress] = useDebounceValidation(value, 500)

  const handleValueChange = (event) => {

    const value = event.target.value.replace('.', ',')
    checkIfAllowedInput(value) && setValue(value)
  }

  const handleCurrencyChange = (e) => {

    setCurrency(e.target.value)
  }

  const isSubmitEnabled = !!value && isValueValid && !isInProgress
  const isValidationError = !isValueValid && !!value

  return (
    <div className="assetActions__section">
      <h1>Preis, Währung anpassen</h1>
      <p className="assetActions__paragraph">
        Du bist dabei, den Preis zu überschreiben. Die Anpassung wird anschließend zu Fotoware
        synchronisiert.
      </p>
      <div className="assetActions__paragraph">
        Aktueller Preis: <b><PriceCurrencyLiteral price={asset.price.value} currency={asset.price.currency}
          testId={"assetActions--price__current"} /></b>.
      </div>
      <div className="assetActions__pickerGrid">
        <div className="assetActions__picker">
          <TextField
            label="Preis"
            type="text"
            onChange={handleValueChange}
            value={value}
            error={isValidationError}
            helperText={isValidationError ? "Falscher Eintrag." : ""}
            inputProps={{
              'data-testid': 'assetActions--price-value__input'
            }}
          />
        </div>
        <div className="assetActions__picker">
          <InputLabel className='test' shrink id='currency-labelid'>Währung</InputLabel>
          <Select
            labelId="currency-labelid"
            id="currency"
            value={currency}
            label="Währung"
            onChange={handleCurrencyChange}
            inputProps={{
              'data-testid': 'assetActions--price-currency__input'
            }}

          >
            {
              Object.entries(PricingModel.Currency).map(el => {
                return <MenuItem key={el[1]} value={el[0]}>{el[1]}</MenuItem>
              })
            }
          </Select>
        </div>
      </div>
      <AssetPurchaseEditorActionButtons isSubmitEnabled={isSubmitEnabled} toggleActionDrawer={toggleActionDrawer} submit={submit} testId={"price"} />
    </div>
  );
});

export default AssetPurchaseEditorPrice;
