import {Purchase} from '../models/Purchase';
import {httpClient} from '../../../infrastructure/HttpClient';
import {handleHttpStatusCode} from '../../../infrastructure/HandleHttpStatusCode';
import {Pagination} from '../../../components/Pagination/model/Pagination';
import {AssetId} from '../models/AssetId';
import {AssetPreview} from '../models/AssetPreview';

class PurchaseService {
  static URL_MAPPING = '/usage-catalog/api/purchases';
  static URL_MAPPING_PURCHASE = '/usage-catalog/api/purchases-wm';

  search(query = new URLSearchParams()) {
    if (!(query instanceof URLSearchParams)) {
      throw new Error('query must be instance of URLSearchParams');
    }

    return httpClient.get(`${PurchaseService.URL_MAPPING}?${query}`)
      .then(handleHttpStatusCode)
      .then(response => {
        let data = response.content || [];
        return {
          pagination: Pagination.fromJson(response),
          content: data.map(entry => {
            return Purchase.fromJson(entry);
          })
        };
      });
  }

  searchAccountablePurchases(query = new URLSearchParams()) {
    if (!(query instanceof URLSearchParams)) {
      throw new Error('query must be instance of URLSearchParams');
    }

    return httpClient.get(`${PurchaseService.URL_MAPPING}/accountable?${query}`)
      .then(handleHttpStatusCode)
      .then(response => {
        let data = response.content || [];
        return {
          pagination: Pagination.fromJson(response),
          content: data.map(entry => {
            return Purchase.fromJson(entry);
          })
        };
      });
  }

  searchNearlyExpiredPurchases(query = new URLSearchParams()) {
    if (!(query instanceof URLSearchParams)) {
      throw new Error('query must be instance of URLSearchParams');
    }

    return httpClient.get(`${PurchaseService.URL_MAPPING}/expire?${query}`)
      .then(handleHttpStatusCode)
      .then(response => {
        let data = response.content || [];
        return {
          pagination: Pagination.fromJson(response),
          content: data.map(entry => {
            return Purchase.fromJson(entry);
          })
        };
      });
  }

  searchById(query = new URLSearchParams()) {
    if (!(query instanceof URLSearchParams)) {
      throw new Error('query must be instance of URLSearchParams');
    }

    const shortOrLongId = query.get('shortOrLongId')
    const page = query.get('page') || 0

    return httpClient.get(`${PurchaseService.URL_MAPPING}/byId/${shortOrLongId}?page=${page}`)
      .then(handleHttpStatusCode)
      .then(response => {
        let data = response.content || [];
        return {
          pagination: Pagination.fromJson(response),
          content: data.map(entry => {
            return Purchase.fromJson(entry);
          })
        };
      });
  }

  fetchCollagePurchaseChildAssets(assetId) {
    return httpClient.get(`${PurchaseService.URL_MAPPING}/asset/${assetId.damAssetId}/collage_children`)
      .then(handleHttpStatusCode)
      .then(response => (response || []).map(entry => AssetId.fromJson(entry)));
  }

  fetchAssetPreview(assetId) {
    return httpClient.get(`${PurchaseService.URL_MAPPING}/asset/${assetId.damAssetId}/asset_preview`)
      .then(handleHttpStatusCode)
      .then(response => AssetPreview.fromJson(response));
  }

  repurchase(assetId, usage) {
    return httpClient
      .post(`${PurchaseService.URL_MAPPING_PURCHASE}/dam/${assetId.dam}/damAssetId/${assetId.damAssetId}`,
        {referenceDocumentPath: usage.documentPath})
  }

  updateOnlineLife(purchaseId, onlineLifeInDays) {
    const payload = {onlineLifeDays: onlineLifeInDays};
    return httpClient
      .put(`${PurchaseService.URL_MAPPING_PURCHASE}/dam/${purchaseId.dam}/damAssetId/${purchaseId.damAssetId}/ordinal/${purchaseId.purchaseOrdinal}/onlineLifeDays`, payload)
      .then(handleHttpStatusCode);
  }

  updatePurchasePrice(purchaseId, payload) {
    return httpClient
      .put(`${PurchaseService.URL_MAPPING_PURCHASE}/dam/${purchaseId.dam}/damAssetId/${purchaseId.damAssetId}/ordinal/${purchaseId.purchaseOrdinal}/purchasePrice`, payload)
      .then(handleHttpStatusCode);
  }

  fetchAllSelectedVendorsData(query) {
    return httpClient.get(`${PurchaseService.URL_MAPPING}/accountable/all?${query}`)
      .then(handleHttpStatusCode)
  }

}

export const purchaseService = new PurchaseService();
